import React from "react";

const DiaryTaskModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="DiaryModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen ">
          <div className="modal-content">
            <div className="dash_card">
              <div className="card_head  d-flex align-items-center justify-content-between border-bottom">
                <h2 className="card_title">Diary</h2>
                <button
                  type="button"
                  className="btn-close border-0 bg-transparent"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {/* card-head/end */}
              <div id="vanilla-calendarModal" data-calendar-theme="light" />
            </div>
            {/* card/end */}
          </div>
        </div>
      </div>
    </>
  );
};

// mapping actions via props
export default DiaryTaskModal;