import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { propertiesActions } from "../../../redux/actions";
import Spinner from "../../Shared/Spinner";


const initialUserDetails = {
  fullName: "",
  email: "",
  phoneNumber: "",
  role: "",
};

const AddUserToPropertyModal = (props) => {
  const { 
    showModal, 
    handleClose, 
    allRoles, 
    selectedPropertyId,
    addUserToProperty 
  } = props;

  const [userDetails, setUserDetails] = useState({ ...initialUserDetails });
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsAddingUser(true);

    let dataToSend = {
      fullName: userDetails.fullName,
      email: userDetails.email,
      phoneNumber: userDetails.phoneNumber,
      roleId: Number(userDetails.role),
      propertyId: Number(selectedPropertyId)
    };

    addUserToProperty(dataToSend)
      .then((data) => {
        if (data.invited && data.invited === true) {
          toast.success("Email send to user to join property.");
        } else {
          toast.success("Something went wrong please try again.");
        }
        setIsAddingUser(false);
        ModalCloseHelper();
      },(error) => {
        toast.error(error);
        setIsAddingUser(false);
      })
      .catch((error) => {
        toast.error(error);
        setIsAddingUser(false);
      });
  };

  const validateEmail = (e) => {
    let email = e.target.value;
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var isEmailValid = re.test(String(email).toLowerCase());

    if (!isEmailValid) {
      setError("Please enter a valid email address");
    } else {
      setError("");
    }
  };

  const ModalCloseHelper = (e) => {
    setUserDetails({...initialUserDetails});
    handleClose();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          ModalCloseHelper();
        }}
      >
        <Modal.Header
          className="add-users-modal-header"
          closeButton
        ></Modal.Header>
        <Modal.Body className="add-users-modal-body">
          <h5 className="add-users-modal-title">Adding User</h5>
          <p className="add-users-modal-description">
            "You can easily add a user by entering their details below. They
            will be send an email with an invite link which they can use to join
            the property easily and quickly"
          </p>
          <input
            id="fullName"
            type="text"
            placeholder="Full Name"
            name="fullName"
            onChange={(e) => handleChange(e)}
            value={userDetails.fullName}
            required
            className="add-users-modal-input"
          />

          <input
            id="email"
            type="text"
            placeholder="Email"
            name="email"
            onChange={(e) => handleChange(e)}
            onBlur={validateEmail}
            value={userDetails.email}
            required
            className="add-users-modal-input"
          />
          {error && <div className="error-msg">{error}</div>}

          <InputMask
            id="phoneNumber"
            mask="(+44) 9999 999999"
            placeholder="(+44) 1234 123456"
            name="phoneNumber"
            value={userDetails.phoneNumber}
            onChange={(e) => handleChange(e)}
            className="add-users-modal-input"
          ></InputMask>

          <select
            id="role"
            name="role"
            required
            value={userDetails.role}
            onChange={(e) => handleChange(e)}
            className="add-users-modal-input add-users-modal-input-privilege"
          >
            <option disabled value="" selected>
              Select
            </option>
            {allRoles.map((option, index) => {
              return (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              );
            })}
          </select>

          <Button
            className="add-users-modal-submit-button"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            {isAddingUser ? <Spinner /> : "Submit"}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addUserToProperty: (userDetails) =>
    dispatch(propertiesActions.addUserToProperty(userDetails)),
});

// mapping actions via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserToPropertyModal);
