import Dashboard from "./Components/Screens/Dashboard/Dashboard";
import logo from "./logo.svg";
import "./App.css";
import Signup from "./Components/Screens/Signup/Signup";
import Login from "./Components/Screens/Login/Login";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { clientendpoints } from "./endpoints/endpoints";
import { authService } from "./services/auth.service";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrivateRoute } from "./Components/Shared/PrivateRoute";
import { PublicRoute } from "./Components/Shared/PublicRoute";

import AccountConfirm from "./Components/Screens/AccountConfirmation/AccountConfirm";
import UpdatePhone from "./Components/Screens/Otp/UpdatePhone";
import OtpForm from "./Components/Screens/Otp/Otp";
import Questionare from "./Components/Screens/NewUserQuestionare/Questionare";
import DashboardPage from "./Components/Screens/Dashboard/DashboardPage";
import RoleScreen from "./Components/Screens/RoleScreen/RoleScreen";
import UserInfo from "./Components/Screens/UserInfo/UserInfo";
import ForgotPassword from "./Components/Screens/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/Screens/ResetPassword/ResetPassword";
import QuestionareScreen from "./Components/Screens/Questionares/Questionare";
import Media from "./Components/Screens/Media/Media";
import UsersScreen from "./Components/Screens/Users/UsersScreen";
import PropertiesScreen from "./Components/Screens/Properties/PropertiesScreen";
import JoinPropertyConfirm from "./Components/Screens/Properties/JoinPropertyConfirm";
import Pricing from "./Components/Screens/Pricing/Pricing";
import FindingReports from "./Components/Screens/Reporting/FindingReports";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path={clientendpoints.auth.login}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={clientendpoints.auth.register}
            element={
              <PublicRoute>
                <Signup />
              </PublicRoute>
            }
          />

          <Route
            path={clientendpoints.auth.forgotPassword}
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />

          <Route
            path={clientendpoints.auth.resetpassword}
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />

          <Route
            path={clientendpoints.auth.accountConfirm}
            element={
              <PublicRoute>
                <AccountConfirm />
              </PublicRoute>
            }
          />

          <Route
            path={clientendpoints.properties.joinPropertyConfirmation}
            element={
              <PublicRoute>
                <JoinPropertyConfirm />
              </PublicRoute>
            }
          />

          <Route
            path={clientendpoints.otp.addPhoneNumber}
            element={
              <PrivateRoute>
                <UpdatePhone />
              </PrivateRoute>
            }
          />

          <Route
            path={clientendpoints.otp.validateOtp}
            element={
              <PrivateRoute>
                <OtpForm />
              </PrivateRoute>
            }
          />

          <Route
            path={clientendpoints.newUserQuestionare.questionare}
            element={
              <PrivateRoute>
                <Questionare />
              </PrivateRoute>
            }
          />

          <Route
            path={clientendpoints.pricing.price}
            element={
              <PrivateRoute>
                <Pricing />
              </PrivateRoute>
            }
          />

          <Route
            path={clientendpoints.newUserQuestionare.questionareSubmit}
            element={
              <UserInfo />
              // <PrivateRoute>
              // </PrivateRoute>
            }
          />
          {/* <Route
            path={clientendpoints.dashboard}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          /> */}

          <Route
            path={clientendpoints.dashboard}
            element={
              <PrivateRoute>
                <Dashboard activeTab={clientendpoints.dashboard}>
                  <DashboardPage />
                </Dashboard>
              </PrivateRoute>
            }
          />

          <Route
            path={clientendpoints.roles.main}
            element={
              <PrivateRoute>
                <Dashboard activeTab={clientendpoints.roles.main}>
                  <RoleScreen />
                </Dashboard>
              </PrivateRoute>
            }
          />

          <Route
            path={clientendpoints.reporting.findingReport}
            element={
              <PrivateRoute>
                <Dashboard activeTab={clientendpoints.reporting.findingReport}>
                  <FindingReports />
                </Dashboard>
              </PrivateRoute>
            }
          />

          <Route
            path={clientendpoints.questionares.questionare}
            element={
              <PrivateRoute>
                <Dashboard activeTab={clientendpoints.questionares.questionare}>
                  <QuestionareScreen />
                </Dashboard>
              </PrivateRoute>
            }
          />

          <Route
            path={clientendpoints.media.media}
            element={
              <PrivateRoute>
                <Dashboard activeTab={clientendpoints.media.media}>
                  <Media />
                </Dashboard>
              </PrivateRoute>
            }
          />

          <Route
            path={clientendpoints.users.users}
            element={
              <PrivateRoute>
                <Dashboard activeTab={clientendpoints.users.users}>
                  <UsersScreen />
                </Dashboard>
              </PrivateRoute>
            }
          />
          <Route
            path={clientendpoints.properties.propertiesScreen}
            element={
              <PrivateRoute>
                <PropertiesScreen />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>

      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
}

export default App;
