import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  previligeConstants,
  screenElementsConstant,
} from "../../../constants/globalConstants";
import { authService } from "../../../services/auth.service";

class QuestionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.isEdit
        ? this.props.editData.question.questionOptions
        : [],
      questionTypeId: this.props.isEdit
        ? this.props.editData.question.questionTypeId
        : 0,
      questionTypes: [
        {
          id: 1,
          enumValue: "Multiple Choice",
        },
        {
          id: 2,
          enumValue: "CheckBox",
        },
        {
          id: 3,
          enumValue: "Dropdown",
        },
        {
          id: 4,
          enumValue: "Date",
        },
        {
          id: 5,
          enumValue: "DateTime",
        },
        {
          id: 6,
          enumValue: "TextBox",
        },
        {
          id: 7,
          enumValue: "File",
        },
        {
          id: 8,
          enumValue: "radio",
        },
      ],
      actions: [
        {
          id: 1,
          enumValue: "Urgent Item",
        },
        {
          id: 2,
          enumValue: "Overall Complience",
        },
        {
          id: 3,
          enumValue: "Key Actioned",
        },
        {
          id: 4,
          enumValue: "Expiry Tracker",
        },
        {
          id: 5,
          enumValue: "No Action",
        },
      ],
      conditions: [
        {
          id: 1,
          enumValue: "equal",
        },
        {
          id: 2,
          enumValue: "not equal",
        },
        {
          id: 3,
          enumValue: "greater then",
        },
        {
          id: 4,
          enumValue: "less then",
        },
        {
          id: 5,
          enumValue: "greater then equal",
        },
        {
          id: 6,
          enumValue: "less then equal",
        },
      ],
      certificates: [
        {
          id: 1,
          enumValue: "EICR Certificate",
        },
        {
          id: 2,
          enumValue: "Insurance",
        },
        {
          id: 3,
          enumValue: "Gas Safety Certificate",
        },
        {
          id: 4,
          enumValue: "Fire Risk Assessment",
        },
        {
          id: 5,
          enumValue: "LOLER Certificate",
        },
        {
          id: 6,
          enumValue: "Water Safety Certificate",
        },
      ],
      optionText: "",
      isCorrect: false,
      isAnswer: false,
      showUrgentItem: false,
      showOverAllComplience: false,
      showKeyActioned: false,
      expiryTracker: false,
      isAdminPrevelige: false,
      isCustomPrevelige: false,
      prevelige: {},
      questionType: {},
      parentQuestion: {},
      addOption: {},
      deleteOption: {},
      questionSubmit: {},
      isOverAllCompiliance: false,
      isKeyActionedQuestion: false,
      isCertificateQuestion: false,
      detailDescription: "",
      selectedQuestionTypes: [],
      isEditOption: false,
      tempOptionId: "",
    };
  }

  handleCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };
  onActionSelectChange = (e) => {
    var options = e.target.options;
    var newState = {
      showUrgentItem: false,
      showOverAllComplience: false,
      showKeyActioned: false,
      expiryTracker: false,
      isOverAllCompiliance:
        this.state.showOverAllComplience &&
        this.state.isOverAllCompiliance &&
        true,
      isKeyActionedQuestion:
        this.state.showKeyActioned && this.state.isKeyActionedQuestion && true,
      isCertificateQuestion:
        this.state.expiryTracker && this.state.isCertificateQuestion && true,
    };

    for (var i = 0, l = options.length; i < l; i++) {
      var optionValue = options[i].value;
      var key = this.getOptionStateKey(optionValue);
      var isSelected = options[i].selected;
      newState[key] = isSelected;
    }

    this.setState(newState);
  };

  getOptionStateKey = (value) => {
    switch (value) {
      case "1":
        return "showUrgentItem";
      case "2":
        return "showOverAllComplience";
      case "3":
        return "showKeyActioned";
      case "4":
        return "expiryTracker";
      default:
        return null;
    }
  };
  componentDidMount() {
    if (this.props.isEdit) {
      let stateData = {
        isOverAllCompiliance: this.props.editData.question.isOverAllCompiliance,
        isKeyActionedQuestion:
          this.props.editData.question.isKeyActionedQuestion,
        isCertificateQuestion:
          this.props.editData.question.isCertificateQuestion,
      };

      let questionTypes = this.props.editData.question.questionActionsTypes
        ? this.props.editData.question.questionActionsTypes.split(",")
        : [];

      questionTypes.forEach((type) => {
        stateData[this.getOptionStateKey(type)] = true;
      });

      stateData.selectedQuestionTypes = questionTypes;

      this.setState(stateData);
    }
    console.log(this.props.usedCertificate);
    this.setState({
      prevelige:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.QuestionText
            )
          : {},
      questionType:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.QuestionType
            )
          : {},
      parentQuestion:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.ParentQuestion
            )
          : {},
      addOption:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.AddQuestionOption
            )
          : {},

      deleteOption:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName ===
                screenElementsConstant.DeleteQuestionOption
            )
          : {},

      questionSubmit:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.AddQuestionSubmit
            )
          : {},
      isAdminPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.ADMIN
        : false,

      isCustomPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.CUSTOM
        : false,
    });
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  // Function to generate a random unique ID
  generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };

  handleAddOption = () => {
    if (this.state.optionText.length > 0) {
      let data = {
        tempId: this.generateUniqueId(),
        text: this.state.optionText,
        isCorrect: this.state.isCorrect,
        isAnswer: this.state.isAnswer,
        detailDescription: this.state.detailDescription,
      };

      this.setState((prevState) => ({
        options: [...prevState.options, data],
        optionText: "",
        isCorrect: false,
        isAnswer: false,
        detailDescription: "",
      }));
    } else {
      toast.error("Option text is required.");
    }
  };

  handleEditOption = () => {
    if (this.state.optionText.length > 0 && this.state.tempOptionId !== "") {
      let updatedOptions = [...this.state.options];
      let optionIndex = updatedOptions.findIndex(
        (option) =>
          option.id === this.state.tempOptionId ||
          option.tempId === this.state.tempOptionId
      );

      if (optionIndex !== -1) {
        updatedOptions[optionIndex] = {
          ...updatedOptions[optionIndex],
          isCorrect: this.state.isCorrect,
          isAnswer: this.state.isAnswer,
          detailDescription: this.state.detailDescription,
          optionText: this.state.optionText,
        };

        this.setState({
          options: updatedOptions,
          optionText: "",
          isCorrect: false,
          isAnswer: false,
          detailDescription: "",
          isEditOption: false,
          tempOptionId: "",
        });
      } else {
        // Handle case when option is not found
        toast.error("Option not found.");
      }
    } else {
      toast.error("One Or more Validation failed during editing option");
    }
  };

  handleOptionDelete = (id) => {
    this.setState({
      options: this.state.options.filter(
        (option) =>
          (option.id !== id && !option.tempId) ||
          (option.tempId !== id && !option.id)
      ),
    });
  };

  handleOptionEdit = (id) => {
    var selectedOption = this.state.options.find((option) => {
      let isIdMatch = option.id === id && !option.tempId;
      let isTempIdMatch = option.tempId === id && !option.id;
      return isIdMatch || isTempIdMatch;
    });
    this.setState({
      optionText: selectedOption.text,
      detailDescription: selectedOption.detailDescription,
      isCorrect: selectedOption.isCorrect,
      isAnswer: selectedOption.isAnswer,
      isEditOption: true,
      tempOptionId: selectedOption.id
        ? selectedOption.id
        : selectedOption.tempId,
    });
  };

  handleQuestionSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const formData = this.changeformDataToJson(e);

    console.log(formData);

    let data = {
      text: formData.text,
      questionTypeId: formData.questionTypeId,
      options: this.state.options,
      isUrgentItem: false,
      isOverAllCompiliance: false,
      compilianceScore: 0,
      isKeyActionedQuestion: false,
      detailDescription: "",
      isCertificateQuestion: false,
      nearlyExpiredays: 0,
      conditionType: null,
      certificateType: null,
      questionActionsTypes: "",
    };

    if (formData.parentQuestionId) {
      data.parentQuestionId = formData.parentQuestionId;
    }

    if (formData.isUrgentItem && formData.isUrgentItem !== null) {
      data.isUrgentItem = formData.isUrgentItem;
    }

    if (
      formData.isOverAllCompiliance &&
      formData.isOverAllCompiliance !== null
    ) {
      data.isOverAllCompiliance = formData.isOverAllCompiliance;
      data.compilianceScore = formData.compilianceScore;
    }

    if (
      formData.isKeyActionedQuestion &&
      formData.isKeyActionedQuestion !== null
    ) {
      data.isKeyActionedQuestion = formData.isKeyActionedQuestion;
      data.detailDescription = formData.detailDescription;
    }

    if (
      formData.isCertificateQuestion &&
      formData.isCertificateQuestion !== null
    ) {
      data.isCertificateQuestion = formData.isCertificateQuestion;
      data.nearlyExpiredays = formData.nearlyExpiredays;
      data.conditionType = formData.conditionType;
      data.certificateType = formData.certificateType;
    }

    if (
      formData.questionActionsTypes &&
      formData.questionActionsTypes !== null
    ) {
      data.questionActionsTypes = formData.questionActionsTypes;
    }

    if (["1", "2", "3", "8"].includes(String(this.state.questionTypeId))) {
      if (this.state.options.length !== 0) {
        this.props.handleAddNewQuestion(data);
      } else {
        toast.error("Options are required for this question type");
      }
    } else {
      data.options = [];
      this.props.handleAddNewQuestion(data);
    }
  };

  handleQuestionEditSubmit = (e, questionId) => {
    e.preventDefault();
    e.stopPropagation();
    const formData = this.changeformDataToJson(e);

    let data = {
      text: formData.text,
      questionTypeId: formData.questionTypeId,
      options: this.state.options,
      isUrgentItem: false,
      isOverAllCompiliance: false,
      compilianceScore: 0,
      isKeyActionedQuestion: false,
      detailDescription: "",
      isCertificateQuestion: false,
      nearlyExpiredays: 0,
      conditionType: null,
      certificateType: null,
      questionActionsTypes: "",
    };

    if (formData.parentQuestionId) {
      data.parentQuestionId = formData.parentQuestionId;
    }

    if (formData.isUrgentItem && formData.isUrgentItem !== null) {
      data.isUrgentItem = formData.isUrgentItem;
    }

    if (
      formData.isOverAllCompiliance &&
      formData.isOverAllCompiliance !== null
    ) {
      data.isOverAllCompiliance = formData.isOverAllCompiliance;
      data.compilianceScore = formData.compilianceScore;
    }

    if (
      formData.isKeyActionedQuestion &&
      formData.isKeyActionedQuestion !== null
    ) {
      data.isKeyActionedQuestion = formData.isKeyActionedQuestion;
      data.detailDescription = formData.detailDescription;
    }

    if (
      formData.isCertificateQuestion &&
      formData.isCertificateQuestion !== null
    ) {
      data.isCertificateQuestion = formData.isCertificateQuestion;
      data.nearlyExpiredays = formData.nearlyExpiredays;
      data.conditionType = formData.conditionType;
      data.certificateType = formData.certificateType;
    }

    if (
      formData.questionActionsTypes &&
      formData.questionActionsTypes !== null
    ) {
      data.questionActionsTypes = formData.questionActionsTypes;
    }

    if (formData.isActive) {
      data.isActive = true;
    }
    debugger;
    console.log(data);

    if (["1", "2", "3", "8"].includes(String(this.state.questionTypeId))) {
      if (this.state.options.length !== 0) {
        this.props.handleEditQuestionSubmit(data, questionId);
      } else {
        toast.error("Options are required for this question type");
      }
    } else {
      data.options = [];
      this.props.handleEditQuestionSubmit(data, questionId);
    }
  };

  changeformDataToJson = (e) => {
    const formData = {};
    Array.from(e.target.elements).forEach((element) => {
      if (element.name) {
        if (element.type === "checkbox") {
          formData[element.name] = element.checked;
        } else if (element.type === "select-multiple") {
          formData[element.name] = Array.from(element.selectedOptions)
            .map((option) => option.value)
            .join(",");
        } else if (element.tagName.toLowerCase() === "textarea") {
          formData[element.name] = element.value;
        } else {
          formData[element.name] = element.value;
        }
      }
    });

    return formData;
  };

  render() {
    return (
      <>
        <div
          className={`modal fade ${this.props.showMoal ? " show" : ""}`}
          tabIndex={-1}
          style={
            this.props.showMoal ? { display: "block", paddingLeft: "0px" } : {}
          }
        >
          <div className="modal-dialog modal-fullscreen ">
            <div className="modal-content">
              <div className="dash_card">
                <div className="card_head  d-flex align-items-center justify-content-between">
                  <h2 className="card_title">
                    {this.props.isEdit
                      ? `Edit Question ${this.props.editData.question.text}`
                      : "Add new Question"}
                  </h2>
                  <button
                    type="button"
                    className="btn-close border-0 bg-transparent"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.props.closeModal()}
                  >
                    <span className="visually-hidden">Expand Button</span>
                  </button>
                </div>
                <div className="card_body">
                  <div className="tab_container">
                    <ul
                      className="nav nav-pills mb-3 gap-2"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active blue-color"
                          id="pills-money-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-money1"
                          type="button"
                          role="tab"
                          aria-controls="pills-money"
                          aria-selected="true"
                        >
                          {" "}
                          General{" "}
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link dark-green-color"
                          id="pills-maintenance-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-maintenance1"
                          type="button"
                          role="tab"
                          aria-controls="pills-maintainance"
                          aria-selected="false"
                          disabled={this.props.isEdit ? false : true}
                        >
                          Questions
                        </button>
                      </li> */}
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-money1"
                        role="tabpanel"
                        aria-labelledby="pills-money-tab"
                        tabIndex={0}
                      >
                        <div className="tab_head">
                          <div className="container">
                            <form
                              id="RoleForm"
                              onSubmit={
                                this.state.isAdminPrevelige ||
                                (this.state.questionSubmit &&
                                  authService.isFullControlPrevelige(
                                    this.state.questionSubmit.Priviliges
                                  ))
                                  ? (e) =>
                                      this.props.isEdit
                                        ? this.handleQuestionEditSubmit(
                                            e,
                                            this.props.editData.question.id
                                          )
                                        : this.handleQuestionSubmit(e)
                                  : (e) => {
                                      e.preventDefault();
                                    }
                              }
                              method="post"
                            >
                              <div
                                className="modal-height"
                                style={{
                                  overflowX: "hidden",
                                  overflowY: "scroll",
                                }}
                              >
                                <div className="row scrollable">
                                  {this.state.isAdminPrevelige ||
                                  (this.state.isCustomPrevelige &&
                                    !authService.isNoAccess(
                                      this.state.prevelige?.Priviliges
                                    )) ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Question Text
                                        </label>
                                        <input
                                          name="text"
                                          maxLength={100}
                                          id="title"
                                          type="text"
                                          required
                                          className="is-untouched is-pristine av-invalid form-control"
                                          disabled={
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.prevelige?.Priviliges
                                            )
                                              ? true
                                              : false
                                          }
                                          defaultValue={
                                            this.props.isEdit
                                              ? this.props.editData.question
                                                  .text
                                              : ""
                                          }
                                        />
                                        <div
                                          id="NameInvalid-msg"
                                          className="invalid-feedback d-none"
                                        >
                                          This field is invalid
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {this.state.isAdminPrevelige ||
                                  (this.state.isCustomPrevelige &&
                                    !authService.isNoAccess(
                                      this.state.questionType?.Priviliges
                                    )) ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Question Type
                                        </label>
                                        <select
                                          name="questionTypeId"
                                          className="is-untouched is-pristine av-invalid form-control"
                                          onChange={
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.prevelige?.Priviliges
                                            )
                                              ? (e) => {}
                                              : (e) => this.handleChange(e)
                                          }
                                          disabled={
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.questionType
                                                ?.Priviliges
                                            )
                                              ? true
                                              : false
                                          }
                                          required
                                        >
                                          <option value="" selected disabled>
                                            select Question Type
                                          </option>
                                          {this.state.questionTypes.map(
                                            (type, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={type.id}
                                                  selected={
                                                    this.props.isEdit
                                                      ? this.props.editData
                                                          .question
                                                          .questionTypeId ===
                                                        type.id
                                                      : false
                                                  }
                                                >
                                                  {type.enumValue}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                        <div
                                          id="NameInvalid-msg"
                                          className="invalid-feedback d-none"
                                        >
                                          This field is invalid
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                    <div id="Name-div" className="form-group">
                                      <label
                                        htmlFor="questionActionsTypes"
                                        className=""
                                      >
                                        Question Action
                                      </label>
                                      <select
                                        name="questionActionsTypes"
                                        multiple
                                        className="is-untouched is-pristine av-invalid form-control"
                                        onChange={(e) =>
                                          this.onActionSelectChange(e)
                                        }
                                        required
                                      >
                                        {this.state.actions.map(
                                          (type, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={type.id}
                                                selected={
                                                  this.props.isEdit
                                                    ? this.state.selectedQuestionTypes.includes(
                                                        String(type.id)
                                                      )
                                                    : false
                                                }
                                              >
                                                {type.enumValue}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                      <div
                                        id="NameInvalid-msg"
                                        className="invalid-feedback d-none"
                                      >
                                        This field is invalid
                                      </div>
                                    </div>
                                  </div>

                                  {this.state.showUrgentItem ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label
                                          htmlFor="isUrgentItem"
                                          className=""
                                        >
                                          Is Urgent Item
                                          <br />
                                          <input
                                            name="isUrgentItem"
                                            id="isUrgentItem"
                                            type="checkbox"
                                            className="ml-2"
                                            defaultChecked={
                                              this.props.isEdit
                                                ? this.props.editData.question
                                                    .isUrgentItem
                                                : false
                                            }
                                          />
                                        </label>
                                        <div
                                          id="NameInvalid-msg"
                                          className="invalid-feedback d-none"
                                        >
                                          This field is invalid
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {this.state.expiryTracker ? (
                                    <>
                                      <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                        <div
                                          id="Name-div"
                                          className="form-group"
                                        >
                                          <label
                                            htmlFor="isCertificateQuestion"
                                            className=""
                                          >
                                            Is Expiry Tracker
                                            <br />
                                            <input
                                              name="isCertificateQuestion"
                                              id="isCertificateQuestion"
                                              type="checkbox"
                                              className="ml-2"
                                              defaultChecked={
                                                this.props.isEdit
                                                  ? this.props.editData.question
                                                      .isCertificateQuestion
                                                  : false
                                              }
                                              onChange={(e) =>
                                                this.handleCheckboxChange(e)
                                              }
                                            />
                                          </label>
                                          <div
                                            id="NameInvalid-msg"
                                            className="invalid-feedback d-none"
                                          >
                                            This field is invalid
                                          </div>
                                        </div>
                                      </div>

                                      {this.state.isCertificateQuestion ? (
                                        <>
                                          <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                            <div
                                              id="Name-div"
                                              className="form-group"
                                            >
                                              <label
                                                htmlFor="certificateType"
                                                className=""
                                              >
                                                Select Certificate
                                              </label>
                                              <select
                                                name="certificateType"
                                                className="is-untouched is-pristine av-invalid form-control"
                                                required
                                              >
                                                <option
                                                  value=""
                                                  selected
                                                  disabled
                                                >
                                                  select Certificate
                                                </option>
                                                {this.state.certificates.map(
                                                  (type, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        value={type.id}
                                                        selected={
                                                          this.props.isEdit
                                                            ? this.props
                                                                .editData
                                                                .question
                                                                .certificateType ==
                                                              type.id
                                                            : false
                                                        }
                                                        disabled={this.props.usedCertificate.includes(
                                                          type.id
                                                        )}
                                                      >
                                                        {type.enumValue}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                              <div
                                                id="NameInvalid-msg"
                                                className="invalid-feedback d-none"
                                              >
                                                This field is invalid
                                              </div>
                                            </div>
                                          </div>

                                          <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                            <div
                                              id="Name-div"
                                              className="form-group"
                                            >
                                              <label
                                                htmlFor="conditionType"
                                                className=""
                                              >
                                                Select Conditions
                                              </label>
                                              <select
                                                name="conditionType"
                                                className="is-untouched is-pristine av-invalid form-control"
                                                required
                                              >
                                                <option
                                                  value=""
                                                  selected
                                                  disabled
                                                >
                                                  Select Condition
                                                </option>
                                                {this.state.conditions.map(
                                                  (type, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        value={type.id}
                                                        selected={
                                                          this.props.isEdit
                                                            ? this.props
                                                                .editData
                                                                .question
                                                                .conditionType ==
                                                              type.id
                                                            : false
                                                        }
                                                      >
                                                        {type.enumValue}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                              <div
                                                id="NameInvalid-msg"
                                                className="invalid-feedback d-none"
                                              >
                                                This field is invalid
                                              </div>
                                            </div>
                                          </div>

                                          <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                            <div
                                              id="Name-div"
                                              className="form-group"
                                            >
                                              <label
                                                htmlFor="nearlyExpiredays"
                                                className=""
                                              >
                                                Days Until Certificate Expiry
                                                Notification
                                              </label>
                                              <input
                                                name="nearlyExpiredays"
                                                maxLength={100}
                                                id="nearlyExpiredays"
                                                type="number"
                                                required
                                                className="is-untouched is-pristine av-invalid form-control"
                                                defaultValue={
                                                  this.props.isEdit
                                                    ? this.props.editData
                                                        .question
                                                        .nearlyExpiredays
                                                    : ""
                                                }
                                              />
                                              <div
                                                id="NameInvalid-msg"
                                                className="invalid-feedback d-none"
                                              >
                                                This field is invalid
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {this.state.showKeyActioned ? (
                                    <>
                                      <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                        <div
                                          id="Name-div"
                                          className="form-group"
                                        >
                                          <label
                                            htmlFor="isKeyActionedQuestion"
                                            className=""
                                          >
                                            Is Key Action
                                            <br />
                                            <input
                                              name="isKeyActionedQuestion"
                                              id="isKeyActionedQuestion"
                                              type="checkbox"
                                              className="ml-2"
                                              defaultChecked={
                                                this.props.isEdit
                                                  ? this.props.editData.question
                                                      .isKeyActionedQuestion
                                                  : false
                                              }
                                              onChange={(e) =>
                                                this.handleCheckboxChange(e)
                                              }
                                            />
                                          </label>
                                          <div
                                            id="NameInvalid-msg"
                                            className="invalid-feedback d-none"
                                          >
                                            This field is invalid
                                          </div>
                                        </div>
                                      </div>

                                      {this.state.isKeyActionedQuestion ? (
                                        <>
                                          <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                            <div
                                              id="Name-div"
                                              className="form-group"
                                            >
                                              <label
                                                htmlFor="detailDescription"
                                                className=""
                                              >
                                                Description If not Answered
                                              </label>
                                              <textarea
                                                name="detailDescription"
                                                maxLength={100}
                                                id="detailDescription"
                                                cols={4}
                                                required
                                                defaultValue={
                                                  this.props.isEdit
                                                    ? this.props.editData
                                                        .question
                                                        .detailDescription
                                                    : ""
                                                }
                                                className="is-untouched is-pristine av-invalid form-control"
                                              />
                                              <div
                                                id="NameInvalid-msg"
                                                className="invalid-feedback d-none"
                                              >
                                                This field is invalid
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {this.state.showOverAllComplience ? (
                                    <>
                                      <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                        <div
                                          id="Name-div"
                                          className="form-group"
                                        >
                                          <label
                                            htmlFor="isOverAllCompiliance"
                                            className=""
                                          >
                                            Is OverAllCompalience
                                            <br />
                                            <input
                                              name="isOverAllCompiliance"
                                              id="isOverAllCompiliance"
                                              type="checkbox"
                                              defaultChecked={
                                                this.props.isEdit
                                                  ? this.props.editData.question
                                                      .isOverAllCompiliance
                                                  : false
                                              }
                                              onChange={(e) =>
                                                this.handleCheckboxChange(e)
                                              }
                                              className="ml-2"
                                            />
                                          </label>
                                          <div
                                            id="NameInvalid-msg"
                                            className="invalid-feedback d-none"
                                          >
                                            This field is invalid
                                          </div>
                                        </div>
                                      </div>
                                      {this.state.isOverAllCompiliance ? (
                                        <>
                                          <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                            <div
                                              id="Name-div"
                                              className="form-group"
                                            >
                                              <label
                                                htmlFor="compilianceScore"
                                                className=""
                                              >
                                                Compiliance Score
                                              </label>
                                              <input
                                                name="compilianceScore"
                                                maxLength={100}
                                                id="compilianceScore"
                                                defaultValue={
                                                  this.props.isEdit
                                                    ? this.props.editData
                                                        .question
                                                        .compilianceScore
                                                    : ""
                                                }
                                                type="number"
                                                required
                                                className="is-untouched is-pristine av-invalid form-control"
                                              />
                                              <div
                                                id="NameInvalid-msg"
                                                className="invalid-feedback d-none"
                                              >
                                                This field is invalid
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {this.props.isEdit &&
                                  !this.props.editData.question.isActive ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Is Active
                                          <br />
                                          <input
                                            name="isActive"
                                            id="isActive"
                                            type="checkbox"
                                            className="ml-2"
                                          />
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {["1", "2", "3", "8"].includes(
                                    String(this.state.questionTypeId)
                                  ) ? (
                                    <>
                                      <div className="row">
                                        {this.state.isAdminPrevelige ||
                                        (this.state.isCustomPrevelige &&
                                          !authService.isNoAccess(
                                            this.state.optionText?.Priviliges
                                          )) ? (
                                          <>
                                            <div className="inner-div col-md-4 col-sm-4 col-6 mb-2">
                                              <div
                                                id="Name-div"
                                                className="form-group"
                                              >
                                                <label
                                                  htmlFor="Name"
                                                  className=""
                                                >
                                                  Option Text
                                                </label>
                                                <input
                                                  name="optionText"
                                                  maxLength={100}
                                                  id="title"
                                                  type="text"
                                                  disabled={
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.optionText
                                                        ?.Priviliges
                                                    )
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.questionType
                                                        ?.Priviliges
                                                    )
                                                      ? (e) => {}
                                                      : (e) =>
                                                          this.handleChange(e)
                                                  }
                                                  value={this.state.optionText}
                                                  className="is-untouched is-pristine av-invalid form-control"
                                                />
                                                <div
                                                  id="NameInvalid-msg"
                                                  className="invalid-feedback d-none"
                                                >
                                                  This field is invalid
                                                </div>
                                              </div>
                                            </div>

                                            <div className="inner-div col-md-4 col-sm-4 col-6 mb-2">
                                              <div
                                                id="Name-div"
                                                className="form-group"
                                              >
                                                <label
                                                  htmlFor="Name"
                                                  className=""
                                                >
                                                  Is Correct Option
                                                  <br />
                                                  <input
                                                    name="isCorrect"
                                                    id="isActive"
                                                    type="checkbox"
                                                    className="ml-2"
                                                    disabled={
                                                      !this.state
                                                        .isAdminPrevelige &&
                                                      authService.isReadOnlyPrevelige(
                                                        this.state.optionText
                                                          ?.Priviliges
                                                      )
                                                        ? true
                                                        : false
                                                    }
                                                    checked={
                                                      this.state.isCorrect
                                                    }
                                                    value={this.state.isCorrect}
                                                    onChange={
                                                      !this.state
                                                        .isAdminPrevelige &&
                                                      authService.isReadOnlyPrevelige(
                                                        this.state.questionType
                                                          ?.Priviliges
                                                      )
                                                        ? (e) => {}
                                                        : (e) =>
                                                            this.handleCheckboxChange(
                                                              e
                                                            )
                                                    }
                                                  />
                                                </label>
                                              </div>
                                            </div>

                                            <div className="inner-div col-md-4 col-sm-4 col-6 mb-2">
                                              <div
                                                id="Name-div"
                                                className="form-group"
                                              >
                                                <label
                                                  htmlFor="Name"
                                                  className=""
                                                >
                                                  Is Answer
                                                  <br />
                                                  <input
                                                    name="isAnswer"
                                                    id="isActive"
                                                    type="checkbox"
                                                    className="ml-2"
                                                    disabled={
                                                      !this.state
                                                        .isAdminPrevelige &&
                                                      authService.isReadOnlyPrevelige(
                                                        this.state.optionText
                                                          ?.Priviliges
                                                      )
                                                        ? true
                                                        : false
                                                    }
                                                    checked={
                                                      this.state.isAnswer
                                                    }
                                                    value={this.state.isAnswer}
                                                    onChange={
                                                      !this.state
                                                        .isAdminPrevelige &&
                                                      authService.isReadOnlyPrevelige(
                                                        this.state.questionType
                                                          ?.Priviliges
                                                      )
                                                        ? (e) => {}
                                                        : (e) =>
                                                            this.handleCheckboxChange(
                                                              e
                                                            )
                                                    }
                                                  />
                                                </label>
                                              </div>
                                            </div>

                                            <div className="inner-div col-md-12 col-sm-12 col-12 mb-2">
                                              <div
                                                id="Name-div"
                                                className="form-group"
                                              >
                                                <label
                                                  htmlFor="Name"
                                                  className=""
                                                >
                                                  Detail description
                                                </label>
                                                <textarea
                                                  name="detailDescription"
                                                  cols={4}
                                                  id="detailDescription"
                                                  disabled={
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.optionText
                                                        ?.Priviliges
                                                    )
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.questionType
                                                        ?.Priviliges
                                                    )
                                                      ? (e) => {}
                                                      : (e) =>
                                                          this.handleChange(e)
                                                  }
                                                  value={
                                                    this.state.detailDescription
                                                  }
                                                  className="is-untouched is-pristine av-invalid form-control"
                                                />
                                                <div
                                                  id="NameInvalid-msg"
                                                  className="invalid-feedback d-none"
                                                >
                                                  This field is invalid
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}

                                        <div className="inner-div col-md-12 col-sm-12 col-12 mb-2">
                                          <div
                                            id="Name-div"
                                            className="form-group"
                                          >
                                            {this.state.isAdminPrevelige ||
                                            (this.state.isCustomPrevelige &&
                                              !authService.isNoAccess(
                                                this.state.addOption?.Priviliges
                                              )) ? (
                                              this.state.isEditOption ? (
                                                <button
                                                  type="button"
                                                  onClick={
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.addOption
                                                        ?.Priviliges
                                                    )
                                                      ? () => {}
                                                      : () =>
                                                          this.handleEditOption()
                                                  }
                                                  disabled={
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.addOption
                                                        ?.Priviliges
                                                    )
                                                      ? true
                                                      : false
                                                  }
                                                  className="submit-button btn btn-primary"
                                                >
                                                  Edit Option
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  onClick={
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.addOption
                                                        ?.Priviliges
                                                    )
                                                      ? () => {}
                                                      : () =>
                                                          this.handleAddOption()
                                                  }
                                                  disabled={
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.addOption
                                                        ?.Priviliges
                                                    )
                                                      ? true
                                                      : false
                                                  }
                                                  className="submit-button btn btn-primary"
                                                >
                                                  Add Option
                                                </button>
                                              )
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {this.state.isAdminPrevelige ||
                                  (this.state.isCustomPrevelige &&
                                    !authService.isNoAccess(
                                      this.state.parentQuestion?.Priviliges
                                    )) ? (
                                    <div className="inner-div col-md-12 col-sm-12 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Parent Question
                                        </label>
                                        <select
                                          name="parentQuestionId"
                                          className="is-untouched is-pristine av-invalid form-control"
                                          disabled={
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.parentQuestion
                                                ?.Priviliges
                                            )
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value="" selected disabled>
                                            Select Any Parent Question
                                          </option>
                                          {this.props.questions &&
                                          this.props.questions.length > 0 ? (
                                            this.props.questions.map(
                                              (question, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={question.id}
                                                    selected={
                                                      this.props.isEdit
                                                        ? this.props.editData
                                                            .question
                                                            .parentQuestionId ===
                                                          question.id
                                                        : false
                                                    }
                                                  >
                                                    {question.id} -{" "}
                                                    {question.text}
                                                  </option>
                                                );
                                              }
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </select>
                                        <div
                                          id="NameInvalid-msg"
                                          className="invalid-feedback d-none"
                                        >
                                          This field is invalid
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  <div className="table-responsive">
                                    <table
                                      id="roleTable"
                                      className="table table-bordered table-striped "
                                    >
                                      <thead>
                                        <tr>
                                          <th className="th-lg">
                                            <span className="d-flex">
                                              Option Text
                                            </span>
                                          </th>

                                          <th className="th-lg">
                                            <span className="d-flex">
                                              Is correct Option
                                            </span>
                                          </th>

                                          <th className="th-lg">
                                            <span className="d-flex">
                                              Is Answer Option
                                            </span>
                                          </th>

                                          <th className="th-lg">
                                            <span className="d-flex">
                                              description
                                            </span>
                                          </th>

                                          <th className="th-lg">
                                            <span className="d-flex">
                                              Actions
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.options &&
                                        this.state.options.length > 0 ? (
                                          this.state.options.map(
                                            (option, index) => {
                                              return (
                                                <>
                                                  <tr key={index}>
                                                    <td>
                                                      {option.optionText ??
                                                        option.text}
                                                    </td>
                                                    <td>
                                                      {option.isCorrect
                                                        ? "Yes"
                                                        : "No"}
                                                    </td>
                                                    <td>
                                                      {option.isAnswer
                                                        ? "Yes"
                                                        : "No"}
                                                    </td>
                                                    <td>
                                                      {option.detailDescription}
                                                    </td>

                                                    <td>
                                                      <div className="row flex">
                                                        <div className="col d-flex justify-content-end">
                                                          {this.state
                                                            .isAdminPrevelige ||
                                                          (this.state
                                                            .isCustomPrevelige &&
                                                            !authService.isNoAccess(
                                                              this.state
                                                                .deleteOption
                                                                ?.Priviliges
                                                            )) ? (
                                                            <button
                                                              type="button"
                                                              className="p-0 disabled_cursor btn btn-link ml-2 text-primary"
                                                              disabled={
                                                                !this.state
                                                                  .isAdminPrevelige &&
                                                                authService.isReadOnlyPrevelige(
                                                                  this.state
                                                                    .deleteOption
                                                                    ?.Priviliges
                                                                )
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={
                                                                !this.state
                                                                  .isAdminPrevelige &&
                                                                authService.isReadOnlyPrevelige(
                                                                  this.state
                                                                    .deleteOption
                                                                    ?.Priviliges
                                                                )
                                                                  ? () => {}
                                                                  : () =>
                                                                      this.handleOptionEdit(
                                                                        option.id
                                                                          ? option.id
                                                                          : option.tempId
                                                                      )
                                                              }
                                                            >
                                                              <span className="action-edit-button">
                                                                <i
                                                                  className="fa fa-edit"
                                                                  style={{
                                                                    color:
                                                                      "primary",
                                                                  }}
                                                                />{" "}
                                                                Edit
                                                              </span>
                                                            </button>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </div>
                                                        <div className="col">
                                                          {this.state
                                                            .isAdminPrevelige ||
                                                          (this.state
                                                            .isCustomPrevelige &&
                                                            !authService.isNoAccess(
                                                              this.state
                                                                .deleteOption
                                                                ?.Priviliges
                                                            )) ? (
                                                            <button
                                                              type="button"
                                                              className="p-0 disabled_cursor btn btn-link ml-2 text-danger"
                                                              disabled={
                                                                !this.state
                                                                  .isAdminPrevelige &&
                                                                authService.isReadOnlyPrevelige(
                                                                  this.state
                                                                    .deleteOption
                                                                    ?.Priviliges
                                                                )
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={
                                                                !this.state
                                                                  .isAdminPrevelige &&
                                                                authService.isReadOnlyPrevelige(
                                                                  this.state
                                                                    .deleteOption
                                                                    ?.Priviliges
                                                                )
                                                                  ? () => {}
                                                                  : () =>
                                                                      this.handleOptionDelete(
                                                                        option.id
                                                                          ? option.id
                                                                          : option.tempId
                                                                      )
                                                              }
                                                            >
                                                              <span className="action-edit-button">
                                                                <i
                                                                  className="fas fa-trash-alt"
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                />{" "}
                                                                Delete
                                                              </span>
                                                            </button>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {this.state.isAdminPrevelige ||
                              (this.state.questionSubmit &&
                                !authService.isNoAccess(
                                  this.state.deleteOption?.Priviliges
                                )) ? (
                                <button
                                  type="submit"
                                  disabled={
                                    !this.state.isAdminPrevelige &&
                                    authService.isReadOnlyPrevelige(
                                      this.state.questionSubmit?.Priviliges
                                    )
                                      ? true
                                      : false
                                  }
                                  className={`submit-button btn btn-primary ${
                                    !this.state.isAdminPrevelige &&
                                    authService.isReadOnlyPrevelige(
                                      this.state.questionSubmit?.Priviliges
                                    )
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  Submit
                                </button>
                              ) : (
                                <></>
                              )}
                            </form>
                          </div>
                        </div>
                        {/* tab-head/end */}
                      </div>
                      {/* <div
                        className="tab-pane fade"
                        id="pills-maintenance1"
                        role="tabpanel"
                        aria-labelledby="pills-maintenance-tab"
                        tabIndex={0}
                      >
                        <div className="container"></div>
                      </div> */}
                    </div>
                  </div>
                  {/* tab/end */}
                </div>
                {/* card-body/end */}
              </div>
              {/* card/end */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QuestionModal;
