export const globalConstants = {
  REQUEST: "_REQUEST",
  SUCCESS: "_SUCCESS",
  FAILURE: "_FAILURE",
};

export const GLOBAL_STATUS = {
  ERROR: 0,
  SUCCESS: 1,
  UNAUTHORIZED: 2,
};

export const loginConstants = {
  REGISTER: "USERS_REGISTER",
  LOGIN: "USERS_LOGIN",
  LOGOUT: "USERS_LOGOUT",

  LOGIN_STATUS: {
    ERROR: 0,
    SUCCESS: 1,
    UNAUTHORIZED: 2,
    DOUBLE_ACCOUNT_LOCK: 3,
    PERMANENT_ACCOUNT_LOCK: 4,
    BANNED_ACCOUNT: 5,
    UNKNOWN_STATUS: 6,
  },
};

export const previligeConstants = {
  NONE: 0,
  ADMIN: 1,
  CUSTOM: 2,
};

export const elementPreviligeConstants = {
  NOACCESS: 0,
  READ_ONLY: 1,
  FULL_CONTROL: 2,
};

export const screenNamesConstant = {
  ROLES: "Roles",
  USERS: "Users",
  QUESTIONARE: "Questionares",
  MEDIA: "News",
};

export const sessionStorageConstant = {
  email: "email",
};
export const screenElementsConstant = {
  PAGINATION: "Pagination",
  FILTER: "Filter",
  ADDNEW: "AddNew",
  SORT: "Sort",
  DELETE: "Delete",
  EDIT: "Edit",
  RoleNameInput: "RoleNameInput",
  RoleNameSubmit: "RoleNameSubmit",
  ElementSubmitButton: "ElementSubmitButton",
  RolesElementSelect: "RolesElementSelect",
  RolePrivilegesSubmit: "RolePrivilegesSubmit",
  ScreenPrivilegesSelect: "ScreenPrivilegesSelect",
  UserAccessCount: "UserAccessCount",
  UserSelectRole: "UserSelectRole",
  UserUpdate: "UserUpdate",
  QuestionareTitle: "QuestionareTitle",
  QuestionareDisplayTriggerSelect: "QuestionareDisplayTriggerSelect",
  QuestionareIconName: "QuestionareIconName",
  QuestionareSubmit: "QuestionareSubmit",
  QuestionAddNew: "QuestionAddNew",
  QuestionEdit: "QuestionEdit",
  QuestionDelete: "QuestionDelete",
  QuestionText: "QuestionText",
  QuestionType: "QuestionType",
  ParentQuestion: "ParentQuestion",
  AddQuestionOption: "AddQuestionOption",
  DeleteQuestionOption: "DeleteQuestionOption",
  AddQuestionSubmit: "AddQuestionSubmit",
  LoadMoreNews: "LoadMoreNews",
  ShowLinks: "ShowLinks",
  ShowNews: "ShowNews",
};
