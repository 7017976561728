import React, { Component } from "react";

class DocumentStorage extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <>
          <div className="dash_card">
            <div className="card_head  d-flex align-items-center justify-content-between">
              <h2 className="card_title">Document Storage</h2>
              <button
                type="button"
                className="expand_btn border-0 bg-transparent"
                data-bs-toggle="modal"
                data-bs-target="#documentStorageModal"
              >
                <svg
                  width={20}
                  height={17}
                  viewBox="0 0 20 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 5.4V1H19V11H14.2333"
                    stroke="#8181A5"
                    strokeLinecap="round"
                  />
                  <rect
                    x="0.5"
                    y="5.5"
                    width={13}
                    height={10}
                    stroke="#8181A5"
                  />
                </svg>
                <span className="visually-hidden">Expand Button</span>
              </button>
            </div>

            {/* card-head/end */}
            <div className="task_table">
              <div className=""></div>

              <div className="table_tr top_border">
                <div className="table_text">
                  <div className=" form-check custom_container">
                    <input
                      className="form-check-input mb-2"
                      type="checkbox"
                      name="flexRadioDefault"
                      style={{ marginLeft: "unset" }}
                      id="flexRadioDefault1"
                      defaultChecked="true"
                    />
                    <div className="trans_detail">
                      <span className="card_ico icon_high_width">
                        <img
                          style={{ marginTop: "10px" }}
                          src="images/EICR.svg"
                          alt="Cart Icon"
                        />
                      </span>
                    </div>
                    <div className="mt-2">
                      <a href="#" className="btn btn-secondary px-4">
                        uploaded
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table_text">
                  <div className=" form-check custom_container">
                    <input
                      className="form-check-input mb-2"
                      type="checkbox"
                      name="flexRadioDefault"
                      style={{ marginLeft: "unset" }}
                      id="flexRadioDefault1"
                    />
                    <div className="trans_detail">
                      <span className="card_ico icon_high_width icon_disabled">
                        <img
                          style={{ marginTop: "15px" }}
                          src="images/insurance.svg"
                          alt="Cart Icon"
                        />
                      </span>
                    </div>
                    <div className="mt-2">
                      <a href="#" className="btn btn-secondary px-4">
                        upload
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table_text">
                  <div className=" form-check custom_container">
                    <input
                      className="form-check-input mb-2"
                      type="checkbox"
                      name="flexRadioDefault"
                      style={{ marginLeft: "unset" }}
                      id="flexRadioDefault1"
                    />
                    <div className="trans_detail">
                      <span className="card_ico icon_high_width icon_disabled">
                        <img
                          style={{ marginTop: "14px" }}
                          src="images/gas.svg"
                          alt="Cart Icon"
                        />
                      </span>
                    </div>
                    <div className="mt-2">
                      <a href="#" className="btn btn-secondary px-4">
                        upload
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table_text">
                  <div className=" form-check custom_container">
                    <input
                      className="form-check-input mb-2"
                      type="checkbox"
                      name="flexRadioDefault"
                      style={{ marginLeft: "unset" }}
                      id="flexRadioDefault1"
                      defaultChecked="true"
                    />
                    <div className="trans_detail">
                      <span className="card_ico icon_high_width ">
                        <img
                          style={{ marginTop: "10px" }}
                          src="images/fire.svg"
                          alt="Cart Icon"
                        />
                      </span>
                    </div>
                    <div className="mt-2">
                      <a href="#" className="btn btn-secondary px-4">
                        uploaded
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table_text">
                  <div className=" form-check custom_container">
                    <input
                      className="form-check-input mb-2"
                      type="checkbox"
                      name="flexRadioDefault"
                      style={{ marginLeft: "unset" }}
                      id="flexRadioDefault1"
                    />
                    <div className="trans_detail">
                      <span className="card_ico icon_high_width icon_disabled">
                        <img
                          style={{ marginTop: "16px" }}
                          src="images/loler.svg"
                          alt="Cart Icon"
                        />
                      </span>
                    </div>
                    <div className="mt-2">
                      <a href="#" className="btn btn-secondary px-4">
                        upload
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table_text">
                  <div className=" form-check custom_container">
                    <input
                      className="form-check-input mb-2"
                      type="checkbox"
                      name="flexRadioDefault"
                      style={{ marginLeft: "unset" }}
                      id="flexRadioDefault1"
                      defaultChecked="true"
                    />
                    <div className="trans_detail">
                      <span className="card_ico icon_high_width">
                        <img
                          style={{ marginTop: "10px" }}
                          src="images/water.svg"
                          alt="Cart Icon"
                        />
                      </span>
                    </div>
                    <div className="mt-2">
                      <a href="#" className="btn btn-secondary px-4">
                        uploaded
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* tr/end */}
              <div className="table_foot text-end"></div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default DocumentStorage;
