import React, { Component } from "react";

class TaskModal extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div
          className="modal fade"
          id="comTaskModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-fullscreen ">
            <div className="modal-content">
              <div className="dash_card">
                <div className="card_head  d-flex align-items-center justify-content-between">
                  <h2 className="card_title">Communications Tasks</h2>
                  <button
                    type="button"
                    className="btn-close border-0 bg-transparent"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                {/* card-head/end */}
                <div className="task_table">
                  <div className="table_tr">
                    <div className="table_text">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Do you want to tell your residents about upcoming
                          inspection
                        </label>
                      </div>
                    </div>
                    <div className="table_btns d-flex gap-1 gap-xxl-2">
                      <a href="#" className="btn btn-primary">
                        Schedule Later
                      </a>
                      <a href="#" className="btn btn-secondary px-4">
                        Ignore
                      </a>
                      <a
                        href="#"
                        className="custom_btn btn bg-transparent border-0 blue-color blue-color text-uppercase fw-bold"
                      >
                        Send Now
                      </a>
                    </div>
                  </div>
                  {/* tr/end */}
                  <div className="table_tr">
                    <div className="table_text">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Notify residents of remedial work
                        </label>
                      </div>
                    </div>
                    <div className="table_btns d-flex gap-1 gap-xxl-2">
                      <a href="#" className="btn btn-primary">
                        Schedule Later
                      </a>
                      <a href="#" className="btn btn-secondary px-4">
                        Ignore
                      </a>
                      <a
                        href="#"
                        className="custom_btn btn  bg-transparent border-0 blue-color blue-color text-uppercase fw-bold"
                      >
                        Send Now
                      </a>
                    </div>
                  </div>
                  {/* tr/end */}
                  <div className="table_tr">
                    <div className="table_text">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Notify plumber of broken pipe
                        </label>
                      </div>
                    </div>
                    <div className="table_btns d-flex gap-1 gap-xxl-2">
                      <a href="#" className="btn btn-primary">
                        Schedule Later
                      </a>
                      <a href="#" className="btn btn-secondary px-4">
                        Ignore
                      </a>
                      <a
                        href="#"
                        className="custom_btn btn bg-transparent border-0 blue-color blue-color text-uppercase fw-bold"
                      >
                        Send Now
                      </a>
                    </div>
                  </div>
                  {/* tr/end */}
                  <div className="table_foot text-end">
                    <a href="#" className="blue-color text-uppercase">
                      View All Tasks
                    </a>
                  </div>
                </div>
              </div>
              {/* card/end */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TaskModal;
