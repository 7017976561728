import React, { Component } from "react";
import {
  previligeConstants,
  screenElementsConstant,
} from "../../constants/globalConstants";
import { authService } from "../../services/auth.service";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: screenElementsConstant.PAGINATION,
      currentPage: 1,
      totalPages: 10,
      isAdminPrevelige: false,
      isCustomPrevelige: false,
      prevelige: {},
    };
  }

  componentDidMount() {
    this.setState({
      prevelige:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.PAGINATION
            )
          : {},
      isAdminPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.ADMIN
        : false,

      isCustomPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.CUSTOM
        : false,
    });
  }

  render() {
    const { currentPage, totalPages, totalEntities, pageSize, totalShowable } =
      this.props;
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    const maxVisiblePages = 3;

    // Calculate the range of page numbers to display
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
    const startIndex = (currentPage - 1) * pageSize + 1;
    const endIndex =
      currentPage == totalPages ? totalEntities : currentPage * totalShowable;

    return (
      <>
        {this.state.isAdminPrevelige ||
        (this.state.isCustomPrevelige &&
          !authService.isNoAccess(this.state.prevelige?.Priviliges)) ? (
          <div className="row">
            <div className="col-sm-12 col-md-5">
              <div
                className="dataTables_info ml-4"
                id="auditTable_info"
                role="status"
                aria-live="polite"
                style={{ marginLeft: "17px" }}
              >
                Showing {startIndex} to {endIndex} of {totalEntities} enteries
              </div>
            </div>

            <div className="col-sm-12 col-md-7 d-flex flex-row-reverse">
              <div
                className="dataTables_paginate paging_simple_numbers mr-4"
                id="auditTable_paginate"
                style={{ marginRight: "17px" }}
              >
                <nav aria-label="Page navigation">
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        isFirstPage ||
                        (!this.state.isAdminPrevelige &&
                          authService.isReadOnlyPrevelige(
                            this.state.prevelige?.Priviliges
                          ))
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <span
                        className="page-link"
                        tabIndex="-1"
                        onClick={
                          this.state.isAdminPrevelige ||
                          (this.state.prevelige &&
                            authService.isFullControlPrevelige(
                              this.state.prevelige.Priviliges
                            ))
                            ? () =>
                                !isFirstPage &&
                                this.props.handlePageChange(currentPage - 1)
                            : () => {}
                        }
                      >
                        Previous
                      </span>
                    </li>
                    {[...Array(endPage - startPage + 1).keys()].map((index) => (
                      <li
                        key={startPage + index}
                        className={`page-item ${
                          startPage + index === currentPage ? "active" : ""
                        } ${
                          !this.state.isAdminPrevelige &&
                          authService.isReadOnlyPrevelige(
                            this.state.prevelige?.Priviliges
                          )
                            ? " disabled"
                            : ""
                        }`}
                      >
                        <span
                          className="page-link"
                          onClick={
                            this.state.isAdminPrevelige ||
                            (this.state.prevelige &&
                              authService.isFullControlPrevelige(
                                this.state.prevelige.Priviliges
                              ))
                              ? () =>
                                  this.props.handlePageChange(startPage + index)
                              : () => {}
                          }
                        >
                          {startPage + index}
                          {startPage + index === currentPage && (
                            <span className="sr-only">(current)</span>
                          )}
                        </span>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        isLastPage ||
                        (!this.state.isAdminPrevelige &&
                          authService.isReadOnlyPrevelige(
                            this.state.prevelige?.Priviliges
                          ))
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <span
                        className="page-link"
                        onClick={
                          this.state.isAdminPrevelige ||
                          (this.state.prevelige &&
                            authService.isFullControlPrevelige(
                              this.state.prevelige.Priviliges
                            ))
                            ? () =>
                                !isLastPage &&
                                this.props.handlePageChange(currentPage + 1)
                            : () => {}
                        }
                      >
                        Next
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default Pagination;
