import { endpoints } from "../endpoints/endpoints";
import { getRequest, postRequest } from "../system/server-calls";

import responseHandler from "../system/server-response-handler";

function updatePhonenumber(data) {
  return postRequest(endpoints.otp.addPhoneNumer, data, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getOtpExpireInTime() {
  return getRequest(endpoints.otp.getOtpExpiresAt, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function regenerateOtp() {
  return getRequest(endpoints.otp.regenerateOtp, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function validateOtp(data) {
  return postRequest(endpoints.otp.validateOtp, data, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// exporting functions for other files
export const otpService = {
  updatePhonenumber,
  regenerateOtp,
  getOtpExpireInTime,
  validateOtp,
};
