import axios from "axios";
import { authHeader } from "./authHeader";
import config from "./../config/config";

const buildURL = (url) => {
  return config.env === "production" ? url : config.REACT_APP_BASE_URL + url;
};

const buildThirdParty = (url) => {
  return url;
};

const buildHeaders = (auth, isform = false) => {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (isform) {
    headers = {
      "Content-Type": "multipart/form-data",
    };
  }

  if (auth === true) {
    headers = { ...headers, ...authHeader() };
  }

  return headers;
};

const getRequest = (url, auth = true) => {
  const completeUrl = buildURL(url);

  const requestOptions = {
    method: "GET",
    headers: buildHeaders(auth),
  };

  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

const getThirdPartyRequest = (url, auth = true) => {
  const completeUrl = buildThirdParty(url);

  const requestOptions = {
    method: "GET",
    headers: buildHeaders(auth),
  };

  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

const deleteRequest = (url, auth = true) => {
  const completeUrl = buildURL(url);

  const requestOptions = {
    method: "DELETE",
    headers: buildHeaders(auth),
  };

  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

const postRequest = (url, dataObj, auth = true) => {
  const completeUrl = buildURL(url);

  const requestOptions = {
    method: "POST",
    headers: buildHeaders(auth),
    data: dataObj,
  };

  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

const postFormRequest = (url, dataObj, auth = true) => {
  const completeUrl = buildURL(url);
  const requestOptions = {
    method: "POST",
    headers: buildHeaders(auth, true),
    data: dataObj,
  };

  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

const putRequest = (url, dataObj, auth = true) => {
  const completeUrl = buildURL(url);

  const requestOptions = {
    method: "PUT",
    headers: buildHeaders(auth),
    data: JSON.stringify(dataObj),
  };

  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

export {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postFormRequest,
  getThirdPartyRequest,
};
