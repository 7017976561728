import { endpoints } from "../endpoints/endpoints";
import { getRequest } from "../system/server-calls";

import responseHandler from "../system/server-response-handler";

function getSolvedQuestionareByUserForFindingReport() {
  return getRequest(
    endpoints.questionare.getUserSubmittedQuestionare,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// exporting functions for other files
export const reportService = {
  getSolvedQuestionareByUserForFindingReport,
};
