import { boardEndpoints } from "../endpoints/endpoints";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest
} from "../system/server-calls";

import responseHandler from "../system/server-response-handler";

function getBoardData() {
  return getRequest(boardEndpoints.GET_BOARD_DATA, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function addOrUpdateTask(data, boardName) {
  return postRequest(boardEndpoints.ADD_OR_UPDATE_TASK + `${boardName}`, data, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function deleteTask(taskId) {
  return deleteRequest(boardEndpoints.DELETE_TASK + taskId, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function updateTaskOrder(data) {
  return putRequest(boardEndpoints.UPDATE_TASK_ORDER, data, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// exporting functions for other files
export const boardService = {
    getBoardData,
    addOrUpdateTask,
    deleteTask,
    updateTaskOrder
};
