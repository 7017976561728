import { endpoints } from "../endpoints/endpoints";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../system/server-calls";

import responseHandler from "../system/server-response-handler";

function getUserUncompletedOnRegisterQuestionare() {
  return getRequest(endpoints.user.getUserUncompletedQuestionare, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getAllDisplayTriggers() {
  return getRequest(endpoints.questionare.getAllDisplayTriggers, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getAllQuestionares(queryParam = "") {
  return getRequest(
    endpoints.questionare.getAllQuestionares + queryParam,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getQuestionareById(id) {
  return getRequest(endpoints.questionare.getQuestionarebyId + id, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function addUserQuestionareAnswers(data) {
  return postRequest(
    endpoints.questionare.addQuestionareAnswers,
    data,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function addUserQuestionare(data) {
  return postRequest(endpoints.questionare.addUserQuestionare, data, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function addNewQuestionare(data) {
  return postRequest(endpoints.questionare.getAllQuestionares, data, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function updateQuestionareByQuestionareId(data, questionareId) {
  return putRequest(
    endpoints.questionare.getQuestionarebyId + questionareId,
    data,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function deleteQuestionareByQuestionareId(questionareId) {
  return deleteRequest(
    endpoints.questionare.getQuestionarebyId + questionareId,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function requestToJoinExistingProperty(data) {
  return postRequest(endpoints.questionare.requestToJoinExistingProperty, data, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// exporting functions for other files
export const questionareService = {
  getUserUncompletedOnRegisterQuestionare,
  getQuestionareById,
  addUserQuestionareAnswers,
  getAllQuestionares,
  addUserQuestionare,
  addNewQuestionare,
  getAllDisplayTriggers,
  updateQuestionareByQuestionareId,
  deleteQuestionareByQuestionareId,
  requestToJoinExistingProperty
};
