import {boardConstants} from "../../constants";
import {boardService} from "../../services"
import { success} from "./actionsUtilities";

const { GET_ALL_BOARD_DATA } = boardConstants;

function getBoardData() {
    return (dispatch) => {
        boardService.getBoardData()
        .then((response) => {
            const updatedData = updateJSONforBoard(response);
            dispatch(success(updatedData, GET_ALL_BOARD_DATA));
        },() => {})
        .finally(() => {});
    };
}

function addOrUpdateTask(newTask, boardEnum) {
    return (dispatch) => {
        boardService.addOrUpdateTask(newTask, boardEnum)
        .then(() => {
           dispatch( getBoardData());
        },() => {})
        .finally(() => {});
    };
}

function deleteTask(taskId) {
    return (dispatch) => {
        boardService.deleteTask(taskId)
        .then(() => {
           dispatch(getBoardData());
        },() => {})
        .finally(() => {});
    };
}

function updateTaskOrder(taskDetail) {
    return (dispatch) => {
        boardService.deleteTask(taskDetail)
        .then(() => {
           dispatch( getBoardData());
        },() => {})
        .finally(() => {});
    };
}

function updateJSONforBoard(allColumns) {
    let boardData = {};
    allColumns.forEach(column => {
        boardData[column.title] = column.cards
    });
    return boardData;
}

export const boardActions = {
    getBoardData,
    addOrUpdateTask,
    deleteTask,
    updateTaskOrder
};