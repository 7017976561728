import React from "react";

const CalenderDiary = () => {
  return (
    <>
      <div className="col-12 col-md-6  col-xl-12">
        <div className="dash_card pb-4 p-4">
          <div className="card_head  d-flex align-items-center justify-content-between border-bottom p-0 pb-3">
            <h2 className="card_title">Diary</h2>
            <button
              type="button"
              className="expand_btn border-0 bg-transparent"
              data-bs-toggle="modal"
              data-bs-target="#DiaryModal"
            >
              <svg
                width={20}
                height={17}
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 5.4V1H19V11H14.2333"
                  stroke="#8181A5"
                  strokeLinecap="round"
                />
                <rect x="0.5" y="5.5" width={13} height={10} stroke="#8181A5" />
              </svg>
              <span className="visually-hidden">Expand Button</span>
            </button>
          </div>
          {/* card-head/end */}
          <div className="card_body">
            <div id="vanilla-calendar" data-calendar-theme="light" />
          </div>
        </div>
        {/* calendar/end */}
      </div>
    </>
  );
};

export default CalenderDiary;
