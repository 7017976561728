import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="pb-4 pt-3">
        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-6 text-center">
            <a
              href="#"
              className="btn btn-primary fw-bold btn-lg d-inline-flex gap-2"
            >
              Chat with us!
              <svg
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.1853 3.94668 19.4489 9 19.9506V20H10H20V10Z"
                  fill="white"
                />
                <circle cx={6} cy={10} r={1} fill="#5E81F4" />
                <circle cx={10} cy={10} r={1} fill="#5E81F4" />
                <circle cx={14} cy={10} r={1} fill="#5E81F4" />
              </svg>
            </a>
          </div>
          <div className="col-md-3 text-center text-md-end">
            <div className="foot_nav">
              <a href="#">Privacy Policy</a>
              <span>|</span>
              <a href="#">Terms of Use</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
