import React, { Component } from "react";

class KeyActioned extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div className="dash_card">
          <div className="card_head  d-flex align-items-center justify-content-between">
            <h2 className="card_title">Key Actioned</h2>
            <button
              type="button"
              className="expand_btn border-0 bg-transparent"
              data-bs-toggle="modal"
              data-bs-target="#keyActionedModal"
            >
              <svg
                width={20}
                height={17}
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 5.4V1H19V11H14.2333"
                  stroke="#8181A5"
                  strokeLinecap="round"
                />
                <rect x="0.5" y="5.5" width={13} height={10} stroke="#8181A5" />
              </svg>
              <span className="visually-hidden">Expand Button</span>
            </button>
          </div>
          {/* card-head/end */}

          <div className="task_table">
            {this.props.keyActioned.slice(0, 3).map((action, index) => {
              return (
                <>
                  <div className="table_tr">
                    <div className="table_text">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`flexRadioDefault-${index}`}
                          id={`flexRadioDefault-${index}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexRadioDefault-${index}`}
                        >
                          {action.question.detailDescription}
                        </label>
                      </div>
                    </div>
                    <div className="table_btns d-flex gap-1 gap-xxl-2">
                      <a
                        href="#"
                        className="blue-color blue-color "
                        style={{ fontSize: "12px", marginTop: "5px" }}
                      >
                        SEND TO MARKETPLACE
                      </a>
                    </div>
                  </div>
                </>
              );
            })}

            {/* tr/end */}

            <div className="table_foot text-end"></div>
          </div>
        </div>

        <>
          <div
            className="modal fade"
            id="keyActionedModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-fullscreen ">
              <div className="modal-content">
                <div className="dash_card">
                  <div className="card_head  d-flex align-items-center justify-content-between">
                    <h2 className="card_title">Key Actioned</h2>
                    <button
                      type="button"
                      className="btn-close border-0 bg-transparent"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  {/* card-head/end */}
                  <div className="task_table">
                    {this.props.keyActioned.map((action, index) => {
                      return (
                        <>
                          <div className="table_tr">
                            <div className="table_text">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`flexRadioDefault-${index}`}
                                  id={`flexRadioDefault-${index}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexRadioDefault-${index}`}
                                >
                                  {action.question.detailDescription}
                                </label>
                              </div>
                            </div>
                            <div className="table_btns d-flex gap-1 gap-xxl-2">
                              <a
                                href="#"
                                className="blue-color blue-color "
                                style={{ fontSize: "12px", marginTop: "5px" }}
                              >
                                SEND TO MARKETPLACE
                              </a>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    {/* tr/end */}
                    <div className="table_foot text-end"></div>
                  </div>
                </div>
                {/* card/end */}
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default KeyActioned;
