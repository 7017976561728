import { endpoints } from "../endpoints/endpoints";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "../system/server-calls";

import responseHandler from "../system/server-response-handler";

function getAllRoles(queryParam = "") {
  return getRequest(endpoints.roles.role + queryParam, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function deleteById(id) {
  return deleteRequest(endpoints.roles.role + `/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function AddNewRole(role) {
  return postRequest(endpoints.roles.role, role, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function updateExistingRole(role, id) {
  return putRequest(endpoints.roles.role + `/${id}`, role, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getRoleById(id) {
  return getRequest(endpoints.roles.role + `/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getScreenElementsByScreenId(id) {
  return getRequest(endpoints.roles.getScreenElements + `${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getRoleScreenElemnets(roleId, screenId) {
  return getRequest(
    endpoints.roles.getRoleScreenElements + `${roleId}/${screenId}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function addRoleScreenElemnets(roleId, data) {
  return postRequest(
    endpoints.roles.addScreenElements + `${roleId}`,
    data,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function addRoleScreens(roleId, data) {
  return postRequest(endpoints.roles.addScreens + `${roleId}`, data, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getAllActiveRoles() {
  return getRequest(endpoints.roles.getAllRoles, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// exporting functions for other files
export const roleService = {
  getAllRoles,
  deleteById,
  AddNewRole,
  updateExistingRole,
  getRoleById,
  getScreenElementsByScreenId,
  addRoleScreenElemnets,
  getRoleScreenElemnets,
  addRoleScreens,
  getAllActiveRoles,
};
