import React, { Component } from "react";
import { roleService } from "../../../services/role.service";
import { toast } from "react-toastify";
import {
  previligeConstants,
  screenElementsConstant,
} from "../../../constants/globalConstants";
import { authService } from "../../../services/auth.service";
class ElementsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priOptions: [
        {
          id: 0,
          text: "NO ACCESS",
        },
        {
          id: 1,
          text: "READ ONLY",
        },
        {
          id: 2,
          text: "FULL CONTROL",
        },
      ],

      isAdminPrevelige: false,
      isCustomPrevelige: false,
      prevelige: {},
      elementSubmit: {},
    };
  }

  componentDidMount() {
    this.setState({
      prevelige:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName ===
                screenElementsConstant.RolesElementSelect
            )
          : {},
      elementSubmit:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName ===
                screenElementsConstant.ElementSubmitButton
            )
          : {},

      isAdminPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.ADMIN
        : false,

      isCustomPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.CUSTOM
        : false,
    });
  }

  handleElementsSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.preventDefault();
    const formData = {};
    Array.from(e.target.elements).forEach((element) => {
      if (element.name) {
        formData[element.name] = element.value;
      }
    });

    const roleId = this.props.roleId;

    const data = [];

    for (const screenelementid in formData) {
      data.push({
        screenelementid: screenelementid,
        previlige: formData[screenelementid],
      });
    }

    roleService
      .addRoleScreenElemnets(roleId, { screenElements: data })
      .then((data) => {
        toast.success("Elements updated successfully");
        this.props.closeModal();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  render() {
    return (
      <>
        <>
          <div
            className={`modal fade ${this.props.showMoal ? " show" : ""}`}
            tabIndex={-1}
            style={
              this.props.showMoal
                ? { display: "block", paddingLeft: "0px" }
                : {}
            }
          >
            <div className="modal-dialog modal-fullscreen ">
              <div className="modal-content">
                <div className="dash_card">
                  <div className="card_head  d-flex align-items-center justify-content-between">
                    <h2 className="card_title">
                      {this.props.isEdit
                        ? `Edit Elements ${this.props.editData.screenElement.name}`
                        : "Add new Role"}
                    </h2>
                    <button
                      type="button"
                      className="btn-close border-0 bg-transparent"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => this.props.closeModal()}
                    >
                      <span className="visually-hidden">Expand Button</span>
                    </button>
                  </div>
                  <div className="card_body">
                    <div className="tab_container">
                      <ul
                        className="nav nav-pills mb-3 gap-2"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active blue-color"
                            id="pills-money-tab1"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-money1"
                            type="button"
                            role="tab"
                            aria-controls="pills-money"
                            aria-selected="true"
                          >
                            General
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-money1"
                          role="tabpanel"
                          aria-labelledby="pills-money-tab"
                          tabIndex={0}
                        >
                          <div className="tab_head">
                            <div className="container">
                              <form
                                id="ScreenPriviligesForm"
                                onSubmit={
                                  this.state.isAdminPrevelige ||
                                  (this.state.elementSubmit &&
                                    authService.isFullControlPrevelige(
                                      this.state.elementSubmit.Priviliges
                                    ))
                                    ? (e) => this.handleElementsSubmit(e)
                                    : (e) => {
                                        e.preventDefault();
                                      }
                                }
                              >
                                <div className="modal-scrolling">
                                  {this.props.isEdit ? (
                                    this.props.editData.screenElement
                                      .elements &&
                                    this.props.editData.screenElement.elements
                                      .length > 0 ? (
                                      this.props.editData.screenElement.elements.map(
                                        (element, index) => {
                                          return (
                                            <div className="mt-2" key={index}>
                                              <div className="row mr-0 mbSmallScreen">
                                                <div className=" col-lg-4 marginSmallScreen mx-auto">
                                                  <span className="font-weight-bold">
                                                    {element.screenElement
                                                      ? element.screenElement
                                                          .ScreenElementName
                                                      : element.ScreenElementName}
                                                  </span>
                                                </div>
                                                <div className="col-lg-8 mx-auto marginSmallScreen">
                                                  {this.state
                                                    .isAdminPrevelige ||
                                                  (this.state.prevelige &&
                                                    !authService.isNoAccess(
                                                      this.state.prevelige
                                                        ?.Priviliges
                                                    )) ? (
                                                    <select
                                                      id={`screenPriviligesAudit_${index}`}
                                                      name={
                                                        element.screenElement
                                                          ? element
                                                              .screenElement.id
                                                          : element.id
                                                      }
                                                      disabled={
                                                        !this.state
                                                          .isAdminPrevelige &&
                                                        authService.isReadOnlyPrevelige(
                                                          this.state.prevelige
                                                            ?.Priviliges
                                                        )
                                                          ? true
                                                          : false
                                                      }
                                                      className={`react-select theme-light react-select__control mb-2 form-control ScreenSelector ${
                                                        !this.state
                                                          .isAdminPrevelige &&
                                                        authService.isReadOnlyPrevelige(
                                                          this.state.prevelige
                                                            ?.Priviliges
                                                        )
                                                          ? " disabled"
                                                          : ""
                                                      }`}
                                                    >
                                                      {this.state.priOptions.map(
                                                        (option, index) => {
                                                          return (
                                                            <>
                                                              <option
                                                                key={index}
                                                                value={
                                                                  option.id
                                                                }
                                                                selected={
                                                                  element.screenElement
                                                                    ? element.Privilege ===
                                                                      option.id
                                                                    : false
                                                                }
                                                              >
                                                                {option.text}
                                                              </option>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <hr />

                                {this.state.isAdminPrevelige ||
                                (this.state.elementSubmit &&
                                  !authService.isNoAccess(
                                    this.state.elementSubmit?.Priviliges
                                  )) ? (
                                  <button
                                    type="submit"
                                    disabled={
                                      !this.state.isAdminPrevelige &&
                                      authService.isReadOnlyPrevelige(
                                        this.state.elementSubmit?.Priviliges
                                      )
                                        ? true
                                        : false
                                    }
                                    className={`submit-button btn btn-primary ${
                                      !this.state.isAdminPrevelige &&
                                      authService.isReadOnlyPrevelige(
                                        this.state.elementSubmit?.Priviliges
                                      )
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    Submit Elements
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </form>
                            </div>
                          </div>
                          {/* tab-head/end */}
                        </div>
                      </div>
                    </div>
                    {/* tab/end */}
                  </div>
                  {/* card-body/end */}
                </div>
                {/* card/end */}
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default ElementsModal;
