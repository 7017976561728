import Select from "react-select";
function MultiSelectField({ handleChange, question }) {
  // Check if question and questionOptions are defined
  if (!question || !question.questionOptions) {
    return null; // or some default behavior
  }

  // Define your options array
  const options = question.questionOptions.map((option) => ({
    value: option.id,
    label: option.text,
  }));

  const handleSelectChange = (selectedOptions, actionMeta) => {
    const inputName = actionMeta.name;
    let selectedValues;

    if (Array.isArray(selectedOptions)) {
      // An array containing values of the selected options (like: ["one", "two", "three"]
      selectedValues = selectedOptions.map((option) => option.value);

      // OR, use this if you want the values of the selected options as a string separated by comma (like: "one,two,three")
      selectedValues = selectedOptions.map((option) => option.value).join(",");
    } else {
      // An array containing the selected option (like: ["one"])
      selectedValues = [selectedOptions.value];

      // OR, use this if you want the value of the selected option as a string (like: "one")
      selectedValues = selectedOptions.value;
    }

    let finalData = {
      target: {
        name: inputName,
        value: selectedValues,
      },
    };

    handleChange(finalData);
  };

  return (
    <>
      {question.questionOptions && question.questionOptions.length > 0 ? (
        <Select
          options={options}
          closeMenuOnSelect={false}
          isMulti
          name={question.id}
          onChange={handleSelectChange}
          className="tw-items-stretch tw-mt-3.5"
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default MultiSelectField;
