import React, { Component } from "react";
import { FilterColumnsTypesEnum } from "../../enums/filterColumnTypesEnums";
import { Link } from "react-router-dom";
import { clientendpoints } from "../../endpoints/endpoints";
import helperFunctions from "../../helpers/helpersfunctions";
import {
  previligeConstants,
  screenElementsConstant,
} from "../../constants/globalConstants";
import { authService } from "../../services/auth.service";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedoption: "",
      searchType: "",
      columnName: "",
      filterValue: "",
      fieldType: "",
      showFilter: false,
      isAdminPrevelige: false,
      isCustomPrevelige: false,
      prevelige: {},
      addNewPrevelige: {},
    };
  }

  componentDidMount() {
    this.setState({
      prevelige:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) => element.ElementName === screenElementsConstant.FILTER
            )
          : {},
      addNewPrevelige:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) => element.ElementName === screenElementsConstant.ADDNEW
            )
          : {},
      isAdminPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.ADMIN
        : false,

      isCustomPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.CUSTOM
        : false,
    });
  }

  handleShowFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  getFilterTypesOptions = (enumValue) => {
    let finalResults = [];
    switch (enumValue) {
      case FilterColumnsTypesEnum.TextBox:
        finalResults = [
          {
            text: "Equals",
            value: "_exact",
          },
          {
            text: "Contains",
            value: "_contains",
          },
        ];
        break;

      case FilterColumnsTypesEnum.boolean:
        finalResults = [
          {
            text: "Equals",
            value: "_exact",
          },
        ];
        break;

      default:
        break;
    }

    return finalResults;
  };

  handleColumnChange = (e) => {
    let filteredColumn = this.props.filterColumns.find((col) => {
      return col.columnName === e.target.value;
    });

    this.setState({
      options: this.getFilterTypesOptions(filteredColumn.type),
      columnName: e.target.value,
      fieldType: filteredColumn.type,
      searchType: "",
      filterValue: "",
    });
  };

  handleTypeChange = (e) => {
    this.setState({
      searchType: e.target.value,
    });
  };

  handleChangeText = (e) => {
    this.setState({
      filterValue: e.target.value,
    });
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-12 d-flex flex-row-reverse">
            <div className="btn-group flex-wrap">
              {/* <div className="dropdown">
                <button
                  className="left-border-zero tolbar-items btn btn-primary dropdown-toggle"
                  type="button"
                >
                  <i className="fa fa-file fa-xs mr-1" />
                  <span className="names-on-smaller-screens"> Export</span>
                </button>
              </div> */}

              {this.props.showAddNew ? (
                <>
                  {this.state.isAdminPrevelige ||
                  (this.state.isCustomPrevelige &&
                    !authService.isNoAccess(
                      this.state.addNewPrevelige?.Priviliges
                    )) ? (
                    <button
                      type="button"
                      className={`tolbar-items btn btn-primary ${
                        !this.state.isAdminPrevelige &&
                        authService.isReadOnlyPrevelige(
                          this.state.addNewPrevelige?.Priviliges
                        )
                          ? " disabled"
                          : ""
                      }`}
                      onClick={
                        this.state.isAdminPrevelige ||
                        (this.state.addNewPrevelige &&
                          authService.isFullControlPrevelige(
                            this.state.addNewPrevelige.Priviliges
                          ))
                          ? () => this.props.handleAddNew()
                          : () => {}
                      }
                    >
                      <i className="fa  fa-plus fa-xs" />
                      <span className="names-on-smaller-screens">Add New</span>
                    </button>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {this.state.isAdminPrevelige ||
              (this.state.isCustomPrevelige &&
                !authService.isNoAccess(this.state.prevelige?.Priviliges)) ? (
                <button
                  className={`btn btn-primary ${
                    !this.state.isAdminPrevelige &&
                    authService.isReadOnlyPrevelige(
                      this.state.prevelige?.Priviliges
                    )
                      ? " disabled"
                      : ""
                  }`}
                  onClick={
                    this.state.isAdminPrevelige ||
                    (this.state.prevelige &&
                      authService.isFullControlPrevelige(
                        this.state.prevelige.Priviliges
                      ))
                      ? () => this.handleShowFilter()
                      : () => {}
                  }
                >
                  <i className="fa fa-filter"></i>{" "}
                  <span className="names-on-smaller-screens"> Filter </span>
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {this.state.showFilter ? (
          <>
            {this.state.isAdminPrevelige ||
            (this.state.isCustomPrevelige &&
              !authService.isNoAccess(this.state.prevelige?.Priviliges)) ? (
              <div className="row mt-3" id="advanceDiv">
                <form method="POST" onSubmit={(e) => this.props.filterData(e)}>
                  <div id="filterInputs" className="ml-2">
                    <div className="row" id="filterRow-1">
                      <div className="col-md-4 col-sm-4 col-12 mb-3">
                        <div className="form-group empty-filter">
                          <select
                            name="filterColumn"
                            className="is-untouched is-pristine av-invalid form-control removeValidtionImage"
                            required
                            onChange={(e) => this.handleColumnChange(e)}
                          >
                            <option value="" disabled selected>
                              Select Column
                            </option>
                            {this.props.filterColumns &&
                            this.props.filterColumns.length > 0 ? (
                              this.props.filterColumns.map((col, index) => {
                                return (
                                  <option key={index} value={col.columnName}>
                                    {col.columnName}
                                  </option>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                      </div>
                      <div
                        id="typeDiv-1"
                        className="col-md-3 col-sm-3 col-12 mb-3"
                      >
                        <div className="form-group">
                          <select
                            name="filterType"
                            required
                            onChange={(e) => this.handleTypeChange(e)}
                            className="is-untouched is-pristine av-valid form-control removeValidtionImage"
                          >
                            <option value="" disabled selected>
                              Select Options
                            </option>
                            {this.state.options &&
                            this.state.options.length > 0 ? (
                              this.state.options.map((opt, index) => {
                                return (
                                  <option key={index} value={opt.value}>
                                    {opt.text}
                                  </option>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                      </div>
                      <div
                        id="valueTopDiv-1"
                        className="col-md-3 col-sm-3 col-12 mb-3"
                      >
                        <div className="form-group mb-1" id="valueDiv-1">
                          {this.state.fieldType ? (
                            this.state.fieldType ===
                            FilterColumnsTypesEnum.TextBox ? (
                              <input
                                onChange={(e) => this.handleChangeText(e)}
                                required
                                name="filterValue"
                                type="text"
                                className="is-untouched is-pristine av-invalid form-control removeValidtionImage"
                              />
                            ) : this.state.fieldType ===
                              FilterColumnsTypesEnum.boolean ? (
                              <select
                                name="filterValue"
                                required
                                onChange={(e) => this.handleChangeText(e)}
                                className="is-untouched is-pristine av-invalid form-control removeValidtionImage"
                              >
                                <option value="" disabled selected>
                                  Select Value
                                </option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            ) : this.state.fieldType ===
                              FilterColumnsTypesEnum.Date ? (
                              <input
                                onChange={(e) => this.handleChangeText(e)}
                                required
                                name="filterValue"
                                type="date"
                                className="is-untouched is-pristine av-invalid form-control removeValidtionImage"
                              />
                            ) : this.state.fieldType ===
                              FilterColumnsTypesEnum.DateTime ? (
                              <input
                                onChange={(e) => this.handleChangeText(e)}
                                required
                                name="filterValue"
                                type="datetime-local"
                                className="is-untouched is-pristine av-invalid form-control removeValidtionImage"
                              />
                            ) : this.state.fieldType ===
                              FilterColumnsTypesEnum.number ? (
                              <input
                                onChange={(e) => this.handleChangeText(e)}
                                required
                                name="filterValue"
                                type="number"
                                className="is-untouched is-pristine av-invalid form-control removeValidtionImage"
                              />
                            ) : (
                              <input
                                required
                                name="filterValue"
                                onChange={(e) => this.handleChangeText(e)}
                                className="is-untouched is-pristine av-invalid form-control removeValidtionImage"
                              />
                            )
                          ) : (
                            <>
                              <input
                                required
                                onChange={(e) => this.handleChangeText(e)}
                                className="is-untouched is-pristine av-invalid form-control removeValidtionImage"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row float-right m-0 mr-2"
                    style={{ textAlign: "right" }}
                  >
                    <div className="col-12 col-md-12 p-0">
                      <div role="group" className="pull-right btn-group">
                        <button type="submit" className="btn btn-primary">
                          <i className="fa fa-filter" /> Apply Filter
                        </button>
                        <Link
                          type="button"
                          className="btn btn-primary"
                          onClick={() => helperFunctions.hardReload()}
                        >
                          <i className="fa fa-window-close" /> Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default Filter;
