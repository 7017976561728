export const questionTypesEnum = {
  Multiple_Choice: 1,
  CheckBox: 2,
  Dropdown: 3,
  Date: 4,
  DateTime: 5,
  TextBox: 6,
  File: 7,
  radio: 8,
};
