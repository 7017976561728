import React, { useState } from "react";
import InputMask from "react-input-mask";
import Spinner from "../../Shared/Spinner";
import { toast } from "react-toastify";
import { otpService } from "../../../services/otp.service";
import { clientendpoints } from "../../../endpoints/endpoints";
import { withNavigation } from "../../Shared/withNavigation";

const UpdatePhone = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    let data = {
      phonenumber: phoneNumber,
    };

    otpService
      .updatePhonenumber(data)
      .then((res) => {
        setPhoneNumber("");
        setLoading(false);
        toast.success(
          "phone number updated and otp sent to the number for verfication."
        );
        props.navigate(clientendpoints.otp.validateOtp);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <>
      <div className="tw-max-w-md tw-mx-auto tw-p-8 tw-bg-white tw-shadow-md tw-rounded-md tw-mt-20">
        <h2 className="tw-text-2xl tw-font-semibold tw-mb-6">
          Enter Your Phone Number
        </h2>
        {/* Display Message if Phone Number is not Verified */}
        <div className="tw-mb-4">
          <p className="tw-text-red-500">
            Your phone number is not verified yet. Please submit your phone
            number to continue.
          </p>
        </div>
        <form
          method="POST"
          className="tw-space-y-4"
          onSubmit={(e) => handleSubmit(e)}
        >
          {/* Phone Number Input */}
          <div>
            <label
              htmlFor="phone"
              className="tw-block tw-text-sm tw-font-medium tw-text-gray-600"
            >
              Phone Number
            </label>

            <InputMask
              mask="(+44) 9999 999999"
              placeholder="(+44) 1234 123456"
              name="phonenumber"
              value={phoneNumber}
              onChange={(e) => handleChange(e)}
              required
              className="tw-mt-1 tw-p-2 tw-w-full tw-border tw-rounded-md"
            ></InputMask>
          </div>
          <button
            type="submit"
            className="tw-w-full tw-bg-blue-500 tw-text-white tw-py-2 tw-rounded-md hover:tw-bg-blue-600 tw-transition tw-duration-300"
          >
            {loading ? <Spinner /> : "Submit"}
          </button>
        </form>
      </div>
    </>
  );
};

export default withNavigation(UpdatePhone);
