import React, { Component } from "react";

class Spinner extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div
          class="tw-inline-block tw-h-4 tw-w-4 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-current tw-border-r-transparent tw-align-[-0.125em] motion-reduce:tw-animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span class="!tw-absolute !tw--m-px !tw-h-px !tw-w-px !tw-overflow-hidden !tw-whitespace-nowrap !tw-border-0 !tw-p-0 !tw-[clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </>
    );
  }
}

export default Spinner;
