import React, { Component } from "react";

class SearchBar extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div className="search_sec">
          <div className="form-group position-relative">
            <label htmlFor="searchForm" className="visually-hidden">
              Search
            </label>
            <input type="text" className="form-control" placeholder="Search" />
            <span className="form-ico">
              <img src="images/search-ico.svg" alt="search Icon" />
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default SearchBar;
