import React, { Component } from "react";
import { questionareService } from "../../../services/questionare.service";
import QuestionModal from "./QuestionModal";
import { questionsService } from "../../../services/questions.service";
import { toast } from "react-toastify";
import {
  previligeConstants,
  screenElementsConstant,
} from "../../../constants/globalConstants";
import { authService } from "../../../services/auth.service";

class QuestionareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayTriggers: [],
      questionModal: false,
      isEditModal: false,
      editQuestionData: {},

      isAdminPrevelige: false,
      isCustomPrevelige: false,
      prevelige: {},
      iconName: {},
      displayTrigger: {},
      QuestionareSubmit: {},
      QuestionAdd: {},
      QuestionEdit: {},
      QuestionDelete: {},
    };
  }

  closeModal = () => {
    this.setState({ questionModal: false });
  };

  openModal = () => {
    this.setState({ questionModal: true });
  };

  handleAddNew = () => {
    this.setState(
      {
        isEditModal: false,
      },
      () => {
        this.openModal();
      }
    );
  };

  componentDidMount() {
    this.setState({
      prevelige:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.QuestionareTitle
            )
          : {},
      iconName:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName ===
                screenElementsConstant.QuestionareIconName
            )
          : {},

      displayTrigger:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName ===
                screenElementsConstant.QuestionareDisplayTriggerSelect
            )
          : {},
      QuestionareSubmit:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.QuestionareSubmit
            )
          : {},

      QuestionAdd:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.QuestionAddNew
            )
          : {},

      QuestionEdit:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.QuestionEdit
            )
          : {},

      QuestionDelete:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.QuestionDelete
            )
          : {},
      isAdminPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.ADMIN
        : false,

      isCustomPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.CUSTOM
        : false,
    });
    questionareService
      .getAllDisplayTriggers()
      .then((data) => {
        this.setState({ displayTriggers: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleAddNewQuestion = (data) => {
    questionsService
      .addNewQuestionInQuestionare(this.props.editData.questionare.id, {
        question: data,
      })
      .then((data) => {
        this.props.handleRefreshQuestionare(this.props.editData.questionare.id);
        toast.success("New Question added successfully");
        this.closeModal();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  handleEditQuestionSubmit = (data, questionId) => {
    questionsService
      .updateQuestionById(questionId, {
        question: data,
      })
      .then((data) => {
        this.props.handleRefreshQuestionare(this.props.editData.questionare.id);
        toast.success("Question Updated Successfully");
        this.closeModal();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  handleEditQuestionData = (id) => {
    questionsService
      .getQuestionById(id)
      .then((res) => {
        console.log(res);
        this.setState(
          { editQuestionData: { question: res }, isEditModal: true },
          () => {
            this.openModal();
          }
        );
      })
      .catch((error) => {});
  };

  handleDeleteQuestionFromQuestionare = (questionId) => {
    questionsService
      .deleteQuestionById(questionId)
      .then((data) => {
        this.props.handleRefreshQuestionare(this.props.editData.questionare.id);
        toast.success("Question deleted successfully");
        this.closeModal();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  render() {
    return (
      <>
        <div
          className={`modal fade ${this.props.showMoal ? " show" : ""}`}
          tabIndex={-1}
          style={
            this.props.showMoal ? { display: "block", paddingLeft: "0px" } : {}
          }
        >
          <div className="modal-dialog modal-fullscreen ">
            <div className="modal-content">
              <div className="dash_card">
                <div className="card_head  d-flex align-items-center justify-content-between">
                  <h2 className="card_title">
                    {this.props.isEdit
                      ? `Edit Questionare ${this.props.editData.questionare.title}`
                      : "Add new Questionare"}
                  </h2>
                  <button
                    type="button"
                    className="btn-close border-0 bg-transparent"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.props.closeModal()}
                  >
                    <span className="visually-hidden">Expand Button</span>
                  </button>
                </div>
                <div className="card_body">
                  <div className="tab_container">
                    <ul
                      className="nav nav-pills mb-3 gap-2"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active blue-color"
                          id="pills-money-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-money1"
                          type="button"
                          role="tab"
                          aria-controls="pills-money"
                          aria-selected="true"
                        >
                          General
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link dark-green-color"
                          id="pills-maintenance-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-maintenance1"
                          type="button"
                          role="tab"
                          aria-controls="pills-maintainance"
                          aria-selected="false"
                          disabled={this.props.isEdit ? false : true}
                        >
                          Questions
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-money1"
                        role="tabpanel"
                        aria-labelledby="pills-money-tab"
                        tabIndex={0}
                      >
                        <div className="tab_head">
                          <div className="container">
                            <form
                              id="RoleForm"
                              onSubmit={
                                this.state.isAdminPrevelige ||
                                (this.state.QuestionareSubmit &&
                                  authService.isFullControlPrevelige(
                                    this.state.QuestionareSubmit.Priviliges
                                  ))
                                  ? (e) =>
                                      this.props.isEdit
                                        ? this.props.handleEditQuestionare(
                                            e,
                                            this.props.editData.questionare.id
                                          )
                                        : this.props.handleAddNewQuestionare(e)
                                  : (e) => {
                                      e.preventDefault();
                                    }
                              }
                              method="post"
                            >
                              <div className="modal-height">
                                <div className="row scrollable">
                                  {this.state.isAdminPrevelige ||
                                  (this.state.isCustomPrevelige &&
                                    !authService.isNoAccess(
                                      this.state.prevelige?.Priviliges
                                    )) ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Questionare Title
                                        </label>
                                        <input
                                          name="title"
                                          maxLength={50}
                                          id="title"
                                          type="text"
                                          required
                                          className="is-untouched is-pristine av-invalid form-control"
                                          disabled={
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.prevelige?.Priviliges
                                            )
                                              ? true
                                              : false
                                          }
                                          defaultValue={
                                            this.props.isEdit
                                              ? this.props.editData.questionare
                                                  .title
                                              : ""
                                          }
                                        />
                                        <div
                                          id="NameInvalid-msg"
                                          className="invalid-feedback d-none"
                                        >
                                          This field is invalid
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {this.state.isAdminPrevelige ||
                                  (this.state.isCustomPrevelige &&
                                    !authService.isNoAccess(
                                      this.state.iconName?.Priviliges
                                    )) ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Icon Name
                                        </label>
                                        <input
                                          name="iconName"
                                          maxLength={50}
                                          id="iconName"
                                          type="text"
                                          required
                                          disabled={
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.iconName?.Priviliges
                                            )
                                              ? true
                                              : false
                                          }
                                          className="is-untouched is-pristine av-invalid form-control"
                                          defaultValue={
                                            this.props.isEdit
                                              ? this.props.editData.questionare
                                                  .iconName
                                              : ""
                                          }
                                        />
                                        <div
                                          id="NameInvalid-msg"
                                          className="invalid-feedback d-none"
                                        >
                                          This field is invalid
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {this.state.isAdminPrevelige ||
                                  (this.state.isCustomPrevelige &&
                                    !authService.isNoAccess(
                                      this.state.displayTrigger?.Priviliges
                                    )) ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          DisplayTrigger
                                        </label>
                                        <select
                                          name="displayTriggerId"
                                          id="displayTriggerId"
                                          className="is-untouched is-pristine av-invalid form-control"
                                          required
                                          disabled={
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.displayTrigger
                                                ?.Priviliges
                                            )
                                              ? true
                                              : false
                                          }
                                        >
                                          {this.state.displayTriggers.length >
                                          0 ? (
                                            this.state.displayTriggers.map(
                                              (trigger, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={trigger.id}
                                                    selected={
                                                      this.props.isEdit
                                                        ? this.props.editData
                                                            .questionare
                                                            .displayTriggerId ===
                                                          String(trigger.id)
                                                        : false
                                                    }
                                                  >
                                                    {trigger.enumValue}
                                                  </option>
                                                );
                                              }
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </select>

                                        <div
                                          id="NameInvalid-msg"
                                          className="invalid-feedback d-none"
                                        >
                                          This field is invalid
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {this.props.isEdit &&
                                  !this.props.editData.questionare.isActive ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Is Active
                                          <br />
                                          <input
                                            name="isActive"
                                            id="isActive"
                                            type="checkbox"
                                            className="ml-2"
                                          />
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <hr />
                              {this.state.isAdminPrevelige ||
                              (this.state.isCustomPrevelige &&
                                !authService.isNoAccess(
                                  this.state.QuestionareSubmit?.Priviliges
                                )) ? (
                                <button
                                  type="submit"
                                  disabled={
                                    !this.state.isAdminPrevelige &&
                                    authService.isReadOnlyPrevelige(
                                      this.state.QuestionareSubmit?.Priviliges
                                    )
                                      ? true
                                      : false
                                  }
                                  className={`submit-button btn btn-primary ${
                                    !this.state.isAdminPrevelige &&
                                    authService.isReadOnlyPrevelige(
                                      this.state.QuestionareSubmit?.Priviliges
                                    )
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  Submit
                                </button>
                              ) : (
                                <></>
                              )}
                            </form>
                          </div>
                        </div>
                        {/* tab-head/end */}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-maintenance1"
                        role="tabpanel"
                        aria-labelledby="pills-maintenance-tab"
                        tabIndex={0}
                      >
                        <div className="container">
                          <div className="row mb-5">
                            <div className="col-sm-12 col-md-12 d-flex flex-row-reverse">
                              <div className="btn-group flex-wrap">
                                {this.state.isAdminPrevelige ||
                                (this.state.isCustomPrevelige &&
                                  !authService.isNoAccess(
                                    this.state.QuestionAdd?.Priviliges
                                  )) ? (
                                  <button
                                    type="button"
                                    className="tolbar-items btn btn-primary"
                                    disabled={
                                      this.state.isAdminPrevelige ||
                                      (this.state.QuestionAdd &&
                                        authService.isFullControlPrevelige(
                                          this.state.QuestionAdd.Priviliges
                                        ))
                                        ? false
                                        : true
                                    }
                                    onClick={
                                      this.state.isAdminPrevelige ||
                                      (this.state.QuestionAdd &&
                                        authService.isFullControlPrevelige(
                                          this.state.QuestionAdd.Priviliges
                                        ))
                                        ? () => this.handleAddNew()
                                        : () => {}
                                    }
                                  >
                                    <i className="fa  fa-plus fa-xs" />
                                    <span className="names-on-smaller-screens">
                                      Add New
                                    </span>
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table
                              id="roleTable"
                              className="table table-bordered table-striped "
                            >
                              <thead>
                                <tr>
                                  <th className="th-lg">
                                    <span className="d-flex">id</span>
                                  </th>

                                  <th className="th-lg">
                                    <span className="d-flex">Question</span>
                                  </th>

                                  <th className="th-lg">
                                    <span className="d-flex">
                                      Parent Question
                                    </span>
                                  </th>

                                  <th className="th-lg">
                                    <span className="d-flex">
                                      Question Type
                                    </span>
                                  </th>

                                  <th className="th-lg">
                                    <span className="d-flex">Actions</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.isEdit &&
                                this.props.editData.questionare &&
                                this.props.editData.questionare.questions
                                  .length > 0 ? (
                                  this.props.editData.questionare.questions.map(
                                    (question, index) => {
                                      return (
                                        <>
                                          <tr key={index}>
                                            <td>{question.id}</td>
                                            <td>{question.text}</td>
                                            <td>
                                              {question.parentQuestion
                                                ? question.parentQuestion.text
                                                : ""}
                                            </td>
                                            <td>
                                              {question.questionType
                                                ? question.questionType.type
                                                : ""}
                                            </td>
                                            <td>
                                              <div className="row flex">
                                                <div className="col">
                                                  {this.state
                                                    .isAdminPrevelige ||
                                                  (this.state
                                                    .isCustomPrevelige &&
                                                    !authService.isNoAccess(
                                                      this.state.QuestionEdit
                                                        ?.Priviliges
                                                    )) ? (
                                                    <button
                                                      type="button"
                                                      onClick={
                                                        this.state
                                                          .isAdminPrevelige ||
                                                        (this.state
                                                          .QuestionEdit &&
                                                          authService.isFullControlPrevelige(
                                                            this.state
                                                              .QuestionEdit
                                                              .Priviliges
                                                          ))
                                                          ? () =>
                                                              this.handleEditQuestionData(
                                                                question.id
                                                              )
                                                          : () => {}
                                                      }
                                                      className={`p-0 disabled_cursor btn btn-link ${
                                                        !this.state
                                                          .isAdminPrevelige &&
                                                        authService.isReadOnlyPrevelige(
                                                          this.state
                                                            .QuestionEdit
                                                            ?.Priviliges
                                                        )
                                                          ? " disabled"
                                                          : ""
                                                      }`}
                                                    >
                                                      <span className="action-edit-button">
                                                        <i className="fa fa-edit" />{" "}
                                                        Edit
                                                      </span>
                                                    </button>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                                <div className="col">
                                                  {question.isActive ? (
                                                    <>
                                                      {this.state
                                                        .isAdminPrevelige ||
                                                      (this.state
                                                        .isCustomPrevelige &&
                                                        !authService.isNoAccess(
                                                          this.state
                                                            .QuestionDelete
                                                            ?.Priviliges
                                                        )) ? (
                                                        <button
                                                          type="button"
                                                          className={`p-0 disabled_cursor btn btn-link ml-2 text-danger ${
                                                            !this.state
                                                              .isAdminPrevelige &&
                                                            authService.isReadOnlyPrevelige(
                                                              this.state
                                                                .QuestionDelete
                                                                ?.Priviliges
                                                            )
                                                              ? " disabled"
                                                              : ""
                                                          }`}
                                                          onClick={
                                                            this.state
                                                              .isAdminPrevelige ||
                                                            (this.state
                                                              .QuestionDelete &&
                                                              authService.isFullControlPrevelige(
                                                                this.state
                                                                  .QuestionDelete
                                                                  .Priviliges
                                                              ))
                                                              ? () =>
                                                                  this.handleDeleteQuestionFromQuestionare(
                                                                    question.id
                                                                  )
                                                              : () => {}
                                                          }
                                                        >
                                                          <span className="action-edit-button">
                                                            <i
                                                              className="fas fa-trash-alt"
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            />{" "}
                                                            Delete
                                                          </span>
                                                        </button>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* tab/end */}
                </div>
                {/* card-body/end */}
              </div>
              {/* card/end */}
            </div>
          </div>
        </div>

        {this.state.questionModal ? (
          <QuestionModal
            showMoal={this.state.questionModal}
            closeModal={this.closeModal}
            isEdit={this.state.isEditModal}
            questions={this.props.editData?.questionare?.questions}
            handleAddNewQuestion={this.handleAddNewQuestion}
            defaultName={this.state.defaultName}
            editData={this.state.editQuestionData}
            handleEditQuestionSubmit={this.handleEditQuestionSubmit}
            prevelige={this.props.prevelige}
            usedCertificate={this.props.editData?.usedCertificate}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default QuestionareModal;
