import React from "react";
import { Link } from "react-router-dom";

const BreadCumb = (props) => {
  return (
    <>
      <section className="content-header">
        <div className="mt-2 ml-2">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>{props.data.screenName}</h5>
            </div>
            <div className="col-sm-6">
              <ol
                className="breadcrumb float-sm-right mr-2"
                style={{ float: "right" }}
              >
                {props.data.path && props.data.path.length > 0 ? (
                  props.data.path.map((list, index) => {
                    return (
                      <>
                        <li
                          className={`breadcrumb-item ${
                            list.isactive ? "active" : ""
                          }`}
                          key={index}
                        >
                          {list.isactive ? (
                            list.title
                          ) : (
                            <Link to={list.link}>{list.title}</Link>
                          )}
                        </li>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BreadCumb;
