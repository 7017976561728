// OtpForm.js
import React, { useState, useEffect } from "react";
import { otpService } from "../../../services/otp.service";
import { toast } from "react-toastify";
import Spinner from "../../Shared/Spinner";
import { clientendpoints } from "../../../endpoints/endpoints";
import { useNavigate } from "react-router-dom";

const OtpForm = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    let dataToSend = {
      otp: otp,
    };

    otpService
      .validateOtp(dataToSend)
      .then((res) => {
        setLoading(false);
        toast.success("Otp is valid you can proceed");
        navigate(clientendpoints.dashboard);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleResend = () => {
    otpService
      .regenerateOtp()
      .then((data) => {
        toast.success("otp resent to mobile number again");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    otpService
      .getOtpExpireInTime()
      .then((data) => {
        if (data.expireIn) {
          setTimer(new Date() - new Date(data.expireIn));
        }
      })
      .catch((err) => {
        toast.error(err);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tw-max-w-md tw-mx-auto tw-p-8 tw-bg-white tw-shadow-md tw-rounded-md tw-mt-20">
      <h2 className="tw-text-2xl tw-font-semibold tw-mb-6">Enter OTP</h2>

      <form onSubmit={handleSubmit} className="tw-space-y-4">
        {/* OTP Input */}
        <div>
          <label
            htmlFor="otp"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-600"
          >
            We have sent a one time passcode to your registered mobile number.
            Please enter your passcode below
          </label>
          <input
            type="text"
            id="otp"
            name="otp"
            placeholder="Enter OTP"
            value={otp}
            onChange={handleOtpChange}
            className="tw-mt-1 tw-p-2 tw-w-full tw-border tw-rounded-md"
            required
            maxLength="6"
          />
        </div>

        {/* Resend OTP Link and Timer */}
        <div className="tw-flex tw-justify-between tw-items-center">
          <button
            type="button"
            onClick={handleResend}
            className={`tw-text-blue-500 hover:tw-underline`}
          >
            Resend OTP
          </button>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="tw-w-full tw-bg-blue-500 tw-text-white tw-py-2 tw-rounded-md hover:tw-bg-blue-600 tw-transition tw-duration-300"
        >
          {loading ? <Spinner /> : "Submit OTP"}
        </button>
      </form>
    </div>
  );
};

export default OtpForm;
