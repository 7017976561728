import {
  elementPreviligeConstants,
  loginConstants,
  previligeConstants,
  sessionStorageConstant,
} from "../constants/globalConstants";
import { endpoints } from "../endpoints/endpoints";
import {
  getRequest,
  postFormRequest,
  postRequest,
} from "../system/server-calls";

import responseHandler from "../system/server-response-handler";

/**
 * The Auth Service
 *
 * This is used for getting data from server and handling the business logic on that data
 */
// logging out the user from the app and removing its information from browser
function logout() {
  sessionStorage.removeItem("JWTtoken");
  sessionStorage.clear();
  return Promise.resolve();
}

// handling the Login response, if user is authenticated then saving
// the jwt token in the local storage
function handleLoginResponse(response) {
  const {
    statusCode,
    data: { token, payload },
  } = response.data;

  if (statusCode !== loginConstants.LOGIN_STATUS.SUCCESS) {
    logout();
    return responseHandler.handleServerResponse(response);
  }
  sessionStorage.setItem("JWTtoken", token);
  sessionStorage.setItem(
    "userName",
    payload.firstName + " " + payload.lastname
  );

  sessionStorage.setItem(sessionStorageConstant.email, payload.email);

  sessionStorage.setItem("privileges", JSON.stringify(payload.privileges));

  return response;
}

// authenticating the user from server
function login(userData) {
  return postRequest(endpoints.auth.login, userData, false).then(
    (response) => handleLoginResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function register(userData) {
  return postFormRequest(endpoints.auth.register, userData, false).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function isUserAuthenticated() {
  const jwtToken = sessionStorage.getItem("JWTtoken");

  return jwtToken && jwtToken !== "" ? true : false;
}

function getUserPreveliges() {
  const privileges = sessionStorage.getItem("privileges");
  return privileges ? JSON.parse(privileges) : [];
}

function getScreenPrivelige(screenName) {
  let privilege = getUserPreveliges();

  return privilege.find((screen) => screen.ScreenName == screenName);
}

function googleAuthLogin(accessToken) {
  return postRequest(endpoints.auth.googleOAuthLogin, accessToken, false).then(
    (response) => handleLoginResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function isNonPrevelige(prev) {
  return prev === previligeConstants.NONE;
}

function isAdminPrevelige(prev) {
  return prev === previligeConstants.ADMIN;
}

function isFullControlPrevelige(prev) {
  return prev === elementPreviligeConstants.FULL_CONTROL;
}

function isReadOnlyPrevelige(prev) {
  return prev === elementPreviligeConstants.READ_ONLY;
}

function isNoAccess(prev) {
  return prev === elementPreviligeConstants.NOACCESS;
}

function isAdminOrCustomPrevelige(prev) {
  return (
    prev === previligeConstants.ADMIN || prev === previligeConstants.CUSTOM
  );
}

function facebookAuthLogin(accessToken) {
  return postRequest(
    endpoints.auth.facebookOauthLogin,
    accessToken,
    false
  ).then(
    (response) => handleLoginResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function forgotPassword(data) {
  return postRequest(endpoints.auth.forgotPassword, data, false).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function confirmEmail(accessToken) {
  return getRequest(endpoints.auth.verifyuser + accessToken, false).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function authorizePing() {
  return getRequest(endpoints.ping.authorizePing, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function validateResetPasswordToken(token) {
  return getRequest(
    endpoints.auth.validateRestPasswordToken + token,
    false
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function resetPassword(data) {
  return postRequest(endpoints.auth.resetpassword, data, false).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}
// exporting functions for other files
export const authService = {
  register,
  login,
  logout,
  isUserAuthenticated,
  googleAuthLogin,
  facebookAuthLogin,
  confirmEmail,
  authorizePing,
  forgotPassword,
  validateResetPasswordToken,
  resetPassword,
  getUserPreveliges,
  isAdminOrCustomPrevelige,
  isAdminPrevelige,
  isNonPrevelige,
  getScreenPrivelige,
  isFullControlPrevelige,
  isReadOnlyPrevelige,
  isNoAccess,
};
