import React, { Component } from "react";
import { clientendpoints } from "../../../endpoints/endpoints";
import BreadCumb from "../../Shared/BreadCumb";
import SearchBar from "../../Shared/SearchBar";
import Filter from "../../Shared/Filter";
import Pagination from "../../Shared/Pagination";
import { roleService } from "../../../services/role.service";
import { FilterColumnsTypesEnum } from "../../../enums/filterColumnTypesEnums";
import { toast } from "react-toastify";

import RoleModal from "./RoleModal";
import { authService } from "../../../services/auth.service";
import {
  previligeConstants,
  screenElementsConstant,
  screenNamesConstant,
} from "../../../constants/globalConstants";
class RoleScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenName: screenNamesConstant.ROLES,
      prevelige: authService.getScreenPrivelige(screenNamesConstant.ROLES),
      isAdminPrevelige: false,
      isCustomPrevelige: false,

      sortPrevelige: {},
      editPrevelige: {},
      deletePrevelige: {},
      breadCumbData: {
        screenName: screenNamesConstant.ROLES,
        path: [
          {
            title: "Home",
            link: clientendpoints.dashboard,
            isactive: false,
          },
          {
            title: "Role",
            link: clientendpoints.roles.main,
            isactive: true,
          },
        ],
      },

      roles: [],
      page: 1,
      pageSize: 10,
      totalEntities: 2,
      totalPages: 1,
      currentPage: 1,
      currentSort: "",
      queryString: "",
      orderColumn: "",
      mandatoryFilters: [
        {
          columnname: "isActive",
          findExact: true,
          value: true,
        },
      ],
      filters: [],
      showRoleModal: false,
      isEditModal: false,
      filterColumns: [
        {
          columnName: "name",
          type: FilterColumnsTypesEnum.TextBox,
        },
        {
          columnName: "isActive",
          type: FilterColumnsTypesEnum.boolean,
        },
      ],
      defaultName: "",
      editRoleData: {},
    };
  }

  async componentDidMount() {
    this.setState({
      isAdminPrevelige: this.state.prevelige
        ? this.state.prevelige.ScreenPriviliges === previligeConstants.ADMIN
        : false,

      isCustomPrevelige: this.state.prevelige
        ? this.state.prevelige.ScreenPriviliges === previligeConstants.CUSTOM
        : false,

      sortPrevelige: this.getScreenElementPriviliges(
        screenElementsConstant.SORT
      ),

      editPrevelige: this.getScreenElementPriviliges(
        screenElementsConstant.EDIT
      ),

      deletePrevelige: this.getScreenElementPriviliges(
        screenElementsConstant.DELETE
      ),
    });
    await this.getAllRoles(1);
  }

  getAllRoles = async (pageNumber, query = "") => {
    await roleService
      .getAllRoles(
        query !== ""
          ? query
          : `?page=${pageNumber}&pageSize=${this.state.pageSize}&isActive_exact=true`
      )
      .then((data) => {
        this.setState({
          roles: data.entity,
          page: data.page,
          pageSize: data.pageSize,
          totalEntities: data.totalEntities,
          totalPages: data.totalPages,
          currentPage: pageNumber,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePageChange = async (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getAllRoles(pageNumber, this.getQueryString());
    });
  };

  handleSortChange = async (columnname) => {
    let currentSort = "ASC"; // Default sorting direction
    if (this.state.orderColumn === columnname) {
      // If already sorted by the same column, toggle the sorting direction
      currentSort = this.state.currentSort === "ASC" ? "DESC" : "ASC";
    }

    // Update state with new sorting information
    this.setState(
      {
        currentSort: currentSort,
        orderColumn: columnname,
      },
      () => {
        // Fetch data with the new sorting parameters
        this.getAllRoles(this.state.currentPage, this.getQueryString());
      }
    );
  };

  getQueryString = () => {
    let isActiveFromSearch = false;
    let data = {
      page: this.state.currentPage,
      pageSize: this.state.pageSize,
      orderColumn: this.state.orderColumn,
      orderDirection: this.state.currentSort,
    };

    if (this.state.filters && this.state.filters.length > 0) {
      this.state.filters.forEach((filter) => {
        if (filter.columnname === "isActive") {
          isActiveFromSearch = true;
        }
        data[`${filter.columnname}${filter.findExact ? "_exact" : ""}`] =
          filter.value;
      });
    }

    // add mandatory filters if any
    if (
      this.state.mandatoryFilters &&
      this.state.mandatoryFilters.length > 0 &&
      !isActiveFromSearch
    ) {
      this.state.mandatoryFilters.forEach((filter) => {
        data[`${filter.columnname}${filter.findExact ? "_exact" : ""}`] =
          filter.value;
      });
    }

    return this.updateQueryString(data);
  };

  updateQueryString = (newParams) => {
    const { queryString } = this.state;
    const params = new URLSearchParams(queryString);

    Object.entries(newParams).forEach(([key, value]) => {
      if (value !== undefined) {
        params.set(key, value);
      }
    });

    return `?${params.toString()}`;
  };

  handleFilterData = (e) => {
    e.preventDefault();
    // Access form elements dynamically
    const formData = {};
    Array.from(e.target.elements).forEach((element) => {
      if (element.name) {
        formData[element.name] = element.value;
      }
    });

    // Create a new filter object based on form data
    const newFilter = {
      columnname: formData.filterColumn,
      findExact: formData.filterType === "_exact",
      value: formData.filterValue,
    };

    this.setState({ filters: [newFilter] }, () => {
      this.getAllRoles(this.state.currentPage, this.getQueryString());
    });
  };

  handleDeleteRoleById = async (id) => {
    await roleService
      .deleteById(id)
      .then((data) => {
        toast.success("Role Deleted Successfully");
        this.getAllRoles(this.state.currentPage, this.getQueryString());
      })
      .catch((err) => {
        toast.error("error in deleting the role");
      });
  };

  getScreenElementPriviliges = (elementName) => {
    return this.state.prevelige.ScreenElementPriviliges.find(
      (element) => element.ElementName === elementName
    );
  };

  openModal = () => {
    this.setState({ showRoleModal: true });
  };

  closeModal = () => {
    this.setState({ showRoleModal: false });
  };

  handleAddNew = () => {
    this.setState(
      {
        isEditModal: false,
      },
      () => {
        this.openModal();
      }
    );
  };

  handleEditModal = async (id) => {
    await roleService
      .getRoleById(id)
      .then((res) => {
        console.log(res);
        this.setState(
          { editRoleData: { role: res }, isEditModal: true },
          () => {
            this.openModal();
          }
        );
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  handleAddNewRole = async (e) => {
    e.preventDefault();
    const formData = {};
    Array.from(e.target.elements).forEach((element) => {
      if (element.name) {
        formData[element.name] = element.value;
      }
    });

    this.closeModal();

    roleService
      .AddNewRole(formData)
      .then((data) => {
        toast.success("New Role added successfully");
        this.getAllRoles(this.state.currentPage, this.getQueryString());
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  handleEditModalSubmit = async (e, id) => {
    e.preventDefault();
    const formData = {};
    Array.from(e.target.elements).forEach((element) => {
      if (element.type === "checkbox") {
        // For checkboxes, store true if checked, false otherwise
        formData[element.name] = element.checked;
      } else {
        formData[element.name] = element.value;
      }
    });

    this.closeModal();

    roleService
      .updateExistingRole(formData, id)
      .then((data) => {
        toast.success("Role Updated successfully");
        this.getAllRoles(this.state.currentPage, this.getQueryString());
        this.setState({ isEditModal: false, editRoleData: {} });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  render() {
    return (
      <>
        <BreadCumb data={this.state.breadCumbData} />

        <div className="header_breadCumb p-3 mb-3">
          <div className="card">
            <div className="card-header">
              <h6 className="card-title mt-2">Roles Details</h6>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              {/*filter actions */}
              <Filter
                filterColumns={this.state.filterColumns}
                filterData={this.handleFilterData}
                handleAddNew={this.handleAddNew}
                prevelige={this.state.prevelige}
                showAddNew={true}
              />
              <br />
              <br />
              <div className="table-responsive">
                <table
                  id="roleTable"
                  className="table table-bordered table-striped "
                >
                  <thead>
                    <tr>
                      <th className="th-lg">
                        <span className="d-flex">
                          Role Name
                          {this.state.isAdminPrevelige ||
                          (this.state.isCustomPrevelige &&
                            !authService.isNoAccess(
                              this.state.sortPrevelige?.Priviliges
                            )) ? (
                            <div
                              className="ml-2 flex-row-reverse"
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                onClick={
                                  this.state.isAdminPrevelige ||
                                  (this.state.sortPrevelige &&
                                    authService.isFullControlPrevelige(
                                      this.state.sortPrevelige.Priviliges
                                    ))
                                    ? () => this.handleSortChange("name")
                                    : () => {}
                                }
                              >
                                <i
                                  className={`fa fa-sort${
                                    this.state.orderColumn === "name"
                                      ? this.state.currentSort === "ASC"
                                        ? "-up"
                                        : this.state.currentSort === ""
                                        ? ""
                                        : "-down"
                                      : ""
                                  } ${
                                    !this.state.isAdminPrevelige &&
                                    authService.isReadOnlyPrevelige(
                                      this.state.sortPrevelige?.Priviliges
                                    )
                                      ? " disabled"
                                      : ""
                                  }`}
                                />
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </span>
                      </th>

                      <th className="th-lg">
                        <span className="d-flex">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.roles && this.state.roles.length > 0 ? (
                      this.state.roles
                        // .filter((role) => {
                        //   return role.isActive;
                        // })
                        .map((role, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{role.name}</td>

                                <td>
                                  <div className="row flex">
                                    <div className="col d-flex flex-row-reverse">
                                      {this.state.isAdminPrevelige ||
                                      (this.state.isCustomPrevelige &&
                                        !authService.isNoAccess(
                                          this.state.editPrevelige?.Priviliges
                                        )) ? (
                                        <button
                                          type="button"
                                          onClick={
                                            this.state.isAdminPrevelige ||
                                            (this.state.editPrevelige &&
                                              authService.isFullControlPrevelige(
                                                this.state.editPrevelige
                                                  .Priviliges
                                              ))
                                              ? () =>
                                                  this.handleEditModal(role.id)
                                              : () => {}
                                          }
                                          className={`p-0 disabled_cursor btn btn-link ${
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.editPrevelige
                                                ?.Priviliges
                                            )
                                              ? " disabled"
                                              : ""
                                          }`}
                                        >
                                          <span className="action-edit-button">
                                            <i className="fa fa-edit" /> Edit
                                          </span>
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className="col">
                                      {role.isActive ? (
                                        <>
                                          {this.state.isAdminPrevelige ||
                                          (this.state.isCustomPrevelige &&
                                            !authService.isNoAccess(
                                              this.state.deletePrevelige
                                                ?.Priviliges
                                            )) ? (
                                            <button
                                              type="button"
                                              className={`p-0 disabled_cursor btn btn-link ml-2 text-danger ${
                                                !this.state.isAdminPrevelige &&
                                                authService.isReadOnlyPrevelige(
                                                  this.state.deletePrevelige
                                                    ?.Priviliges
                                                )
                                                  ? " disabled"
                                                  : ""
                                              }`}
                                              onClick={
                                                this.state.isAdminPrevelige ||
                                                (this.state.deletePrevelige &&
                                                  authService.isFullControlPrevelige(
                                                    this.state.deletePrevelige
                                                      .Priviliges
                                                  ))
                                                  ? () =>
                                                      this.handleDeleteRoleById(
                                                        role.id
                                                      )
                                                  : () => {}
                                              }
                                            >
                                              <span className="action-edit-button">
                                                <i
                                                  className="fas fa-trash-alt"
                                                  style={{ color: "red" }}
                                                />{" "}
                                                Delete
                                              </span>
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* /.card-body */}

            <Pagination
              currentPage={this.state.page}
              totalPages={this.state.totalPages}
              totalEntities={this.state.totalEntities}
              pageSize={this.state.pageSize}
              totalShowable={this.state.roles.length}
              handlePageChange={this.handlePageChange}
              prevelige={this.state.prevelige}
            />
            {this.state.showRoleModal ? (
              <RoleModal
                showMoal={this.state.showRoleModal}
                closeModal={this.closeModal}
                isEdit={this.state.isEditModal}
                handleAddNewRole={this.handleAddNewRole}
                defaultName={this.state.defaultName}
                editData={this.state.editRoleData}
                handleEditRole={this.handleEditModalSubmit}
                prevelige={this.state.prevelige}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        {this.state.showRoleModal ? (
          <div className="modal-backdrop fade show"></div>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default RoleScreen;
