import React, { Component } from "react";

class Trackers extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    certificates: [
      {
        id: 1,
        enumValue: "EICR Certificate",
        imagePath: "images/EICR.svg",
      },
      {
        id: 2,
        enumValue: "Insurance",
        imagePath: "images/insurance.svg",
      },
      {
        id: 3,
        enumValue: "Gas Safety Certificate",
        imagePath: "images/gas.svg",
      },
      {
        id: 4,
        enumValue: "Fire Risk Assessment",
        imagePath: "images/fire.svg",
      },
      {
        id: 5,
        enumValue: "LOLER Certificate",
        imagePath: "images/loler.svg",
      },
      {
        id: 6,
        enumValue: "Water Safety Certificate",
        imagePath: "images/water.svg",
      },
    ],
  };

  compareDates = (date1, date2, enumValue) => {
    switch (enumValue) {
      case 1:
        return date1.getTime() === date2.getTime();
      case 2:
        return date1.getTime() !== date2.getTime();
      case 3:
        return date1.getTime() > date2.getTime();
      case 4:
        return date1.getTime() < date2.getTime();
      case 5:
        return date1.getTime() >= date2.getTime();
      case 6:
        return date1.getTime() <= date2.getTime();
      default:
        return false;
    }
  };

  isCertifcatePresent = (certificateType) => {
    return this.props.certificates.some((certificate) => {
      return certificate.question.certificateType === certificateType;
    });
  };

  getCerificateExpireDate = (certificateType) => {
    let date = "";
    this.props.certificates.forEach((certificate) => {
      if (certificate.question.certificateType === certificateType) {
        const parts = certificate.fieldValue.split("-");
        const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;

        date = formattedDate;
      }
    });

    return date;
  };

  isCertificateExpiredDate = (certificateType) => {
    return this.props.certificates.some((certificate) => {
      if (certificate.question.certificateType === certificateType) {
        const parsedDate = new Date(certificate.fieldValue);

        // Get the current date
        const currentDate = new Date();

        // Check if the parsed date is expired
        if (parsedDate < currentDate) {
          return true;
        } else {
          return false;
        }
      }
    });
  };

  isCertificateIsGoingToBeExpiredSoon = (certificateType) => {
    return this.props.certificates.some((certificate) => {
      if (certificate.question.certificateType === certificateType) {
        const parsedDate = new Date(certificate.fieldValue);

        // Get the current date

        let currentDate = parsedDate.setDate(
          parsedDate.getDate() + certificate.question.nearlyExpiredays
        );

        try {
          if (
            this.compareDates(
              parsedDate,
              new Date(currentDate),
              certificate.question.conditionType
            )
          ) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          return false;
        }
      }
    });
  };

  render() {
    return (
      <>
        <div className="col-12 col-md-6 col-xl-12">
          <div className="dash_card pb-4 p-4">
            <div className="card_head d-flex align-items-center justify-content-between border-bottom p-0 pb-3">
              <h2 className="card_title">Certificates Expiry Tracker</h2>
              <div className="d-flex gap-3 align-items-center">
                <a href="#" className="blue-color text-capitalize">
                  SEE ALL
                </a>
                <button
                  type="button"
                  className="expand_btn border-0 bg-transparent"
                  data-bs-toggle="modal"
                  data-bs-target="#DiaryModal"
                >
                  <svg
                    width={20}
                    height={17}
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 5.4V1H19V11H14.2333"
                      stroke="#8181A5"
                      strokeLinecap="round"
                    />
                    <rect
                      x="0.5"
                      y="5.5"
                      width={13}
                      height={10}
                      stroke="#8181A5"
                    />
                  </svg>
                  <span className="visually-hidden">Expand Button</span>
                </button>
              </div>
            </div>
            {/* card-head/end */}
            <div className="card_body d-flex flex-column gap-2 py-4">
              <div className="trans_card_list d-flex flex-column gap-1">
                {this.state.certificates.map((cer, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className={`trans_item ${
                          this.isCertifcatePresent(cer.id)
                            ? this.isCertificateExpiredDate(cer.id)
                              ? "expired"
                              : this.isCertificateIsGoingToBeExpiredSoon(cer.id)
                              ? "expire_soon"
                              : ""
                            : ""
                        } `}
                      >
                        <div className="trans_detail">
                          <span className="card_ico">
                            <img
                              style={{ marginTop: "10px" }}
                              src={cer.imagePath}
                              alt="Cart Icon"
                            />
                          </span>
                          <div className="d-flex flex-column gap-2">
                            <strong className="fw-normal text-color">
                              {cer.enumValue}
                            </strong>
                            <span className="text-color-second">
                              Expiry Date:{" "}
                              {this.isCertifcatePresent(cer.id)
                                ? this.getCerificateExpireDate(cer.id)
                                : "--/--/----"}
                            </span>
                          </div>
                        </div>
                        {this.isCertifcatePresent(cer.id) &&
                        this.isCertificateExpiredDate(cer.id) ? (
                          <div className="expired_btn">Expired</div>
                        ) : this.isCertificateIsGoingToBeExpiredSoon(cer.id) ? (
                          <div className="expired_btn expire_soon_button">
                            Expiring
                          </div>
                        ) : (
                          <strong className="price">&nbsp;</strong>
                        )}
                      </div>
                    </>
                  );
                })}

                {/* trans/item */}
              </div>
              {/* lise/end */}
            </div>
          </div>
          {/* calendar/end */}
        </div>

        <>
          {/* diaryTask-Modal */}
          <div
            className="modal fade"
            id="DiaryModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="dash_card">
                  <div className="card_head d-flex align-items-center justify-content-between border-bottom">
                    <h2 className="card_title">Certificates Expiry Tracker</h2>
                    <button
                      type="button"
                      className="btn-close border-0 bg-transparent"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  {/* card-head/end */}
                  <div id="" data-calendar-theme="light">
                    <div className="card_body d-flex flex-column gap-2 py-4">
                      <div className="trans_card_list d-flex flex-column gap-1">
                        {this.state.certificates.map((cer, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className={`trans_item ${
                                  this.isCertifcatePresent(cer.id)
                                    ? this.isCertificateExpiredDate(cer.id)
                                      ? "expired"
                                      : this.isCertificateIsGoingToBeExpiredSoon(
                                          cer.id
                                        )
                                      ? "expire_soon"
                                      : ""
                                    : ""
                                } `}
                              >
                                <div className="trans_detail">
                                  <span className="card_ico">
                                    <img
                                      style={{ marginTop: "10px" }}
                                      src={cer.imagePath}
                                      alt="Cart Icon"
                                    />
                                  </span>
                                  <div className="d-flex flex-column gap-2">
                                    <strong className="fw-normal text-color">
                                      {cer.enumValue}
                                    </strong>
                                    <span className="text-color-second">
                                      Expiry Date:{" "}
                                      {this.isCertifcatePresent(cer.id)
                                        ? this.getCerificateExpireDate(cer.id)
                                        : "--/--/----"}
                                    </span>
                                  </div>
                                </div>
                                {this.isCertifcatePresent(cer.id) &&
                                this.isCertificateExpiredDate(cer.id) ? (
                                  <div className="expired_btn">Expired</div>
                                ) : this.isCertificateIsGoingToBeExpiredSoon(
                                    cer.id
                                  ) ? (
                                  <div className="expired_btn expire_soon_button">
                                    Expired
                                  </div>
                                ) : (
                                  <strong className="price">&nbsp;</strong>
                                )}
                              </div>
                            </>
                          );
                        })}
                        {/* trans/item */}
                      </div>
                      {/* lise/end */}
                    </div>
                  </div>
                </div>
                {/* card/end */}
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default Trackers;
