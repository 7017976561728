import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import KanBanBoard from "./KanbanBoard";
import { boardActions } from "../../redux/actions";

const ALL_BOARD_TYPES = [
  {
    id: 1,
    enumValue: "moneyTask",
    displayName: "Money Tasks",
    tabId: "money",
    tabColorCssClass: "blue-color",
  },
  {
    id: 2,
    enumValue: "maintenanceTasks",
    displayName: "Maintenance Tasks",
    tabId: "maintenance",
    tabColorCssClass: "dark-green-color",
  },
  {
    id: 3,
    enumValue: "commsTask",
    displayName: "Comm's Tasks",
    tabId: "comms",
    tabColorCssClass: "green-color",
  },
  {
    id: 4,
    enumValue: "documentTasks",
    displayName: "Document Tasks",
    tabId: "document",
    tabColorCssClass: "yellow-color",
  },
  {
    id: 5,
    enumValue: "companyAdminTasks",
    displayName: "Company Admin Tasks",
    tabId: "admin",
    tabColorCssClass: "orange-color",
  },
];

const Board = (props) => {
  const moneyTabEnumValue = ALL_BOARD_TYPES[0].enumValue;
  const [selectedTab, setSelectedTab] = useState(moneyTabEnumValue);

  useEffect(() => {
    props.getBoardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dash_card">
      <div className="card_head  d-flex align-items-center justify-content-between">
        <h2 className="card_title">Create a Task</h2>
        <button
          type="button"
          className="expand_btn border-0 bg-transparent"
          data-bs-toggle="modal"
          data-bs-target="#createTaskModal"
        >
          <svg
            width={20}
            height={17}
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 5.4V1H19V11H14.2333"
              stroke="#8181A5"
              strokeLinecap="round"
            />
            <rect x="0.5" y="5.5" width={13} height={10} stroke="#8181A5" />
          </svg>
          <span className="visually-hidden">Expand Button</span>
        </button>
      </div>
      <div className="card_body">
        <div className="tab_container">
          <ul
            className="nav nav-pills mb-3 gap-1 gap-xxl-2"
            id="pills-tab"
            role="tablist"
          >
            {ALL_BOARD_TYPES.map((boardType, index) => {
              return (
                <li className="nav-item" role="presentation" key={index}>
                  <button
                    className={`nav-link ${
                      selectedTab === boardType.enumValue ? "active" : ""
                    } ${boardType.tabColorCssClass}`}
                    id={`pills-${boardType.tabId}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#pills-${boardType.tabId}`}
                    type="button"
                    role="tab"
                    aria-controls={`pills-${boardType.tabId}`}
                    aria-selected={
                      selectedTab === boardType.enumValue ? "true" : "false"
                    }
                    onClick={(e) => {
                      setSelectedTab(boardType.enumValue);
                    }}
                  >
                    + {boardType.displayName}{" "}
                  </button>
                </li>
              );
            })}
          </ul>
          <div className="tab-content" id="pills-tabContent">
            {ALL_BOARD_TYPES.map((boardType, index) => {
              return (
                <div
                  className={`tab-pane fade ${
                    selectedTab === boardType.enumValue ? "show active" : ""
                  }`}
                  id={`pills-${boardType.tabId}`}
                  role="tabpanel"
                  aria-labelledby={`pills-${boardType.tabId}-tab`}
                  tabIndex={0}
                  key={index}
                >
                  <KanBanBoard boradEnum={boardType.enumValue} />
                </div>
              );
            })}
          </div>
        </div>
        {/* tab/end */}
      </div>
      {/* card-body/end */}
    </div>
  );
};

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getBoardData: () => dispatch(boardActions.getBoardData()),
});

// mapping actions via props
export default connect(null, mapDispatchToProps)(Board);
