import React, { useEffect, useState } from "react";
import { clientendpoints } from "../../../endpoints/endpoints";
import { Link } from "react-router-dom";
import { authService } from "../../../services/auth.service";
import { DashboardIcon } from "./SidebarIcons";
import { PropertiesIcon } from "./SidebarIcons";
import { UsersIcon } from "./SidebarIcons";
import { RolesIcon } from "./SidebarIcons";
import { TasksIcon } from "./SidebarIcons";
import { QuestionerIcon } from "./SidebarIcons";
import { FinancialsIcon } from "./SidebarIcons";
import { FindingReportsIcon } from "./SidebarIcons";
import { CommsIcon } from "./SidebarIcons";
import { NewsIcon } from "./SidebarIcons";
import { SocialIcon } from "./SidebarIcons";
import { MarketPlaceIcon } from "./SidebarIcons";

const initailSidebaeLinks = [
  {
    title: "Dashboard",
    route: clientendpoints.dashboard,
    svgIcon: <DashboardIcon />,
  },
  {
    title: "Properties",
    route: clientendpoints.properties.propertiesScreen,
    svgIcon: <PropertiesIcon />,
  },
  {
    title: "Users",
    route: clientendpoints.users.users,
    svgIcon: <UsersIcon />,
  },
  {
    title: "Roles",
    route: clientendpoints.roles.main,
    svgIcon: <RolesIcon />,
  },
  {
    title: "Tasks",
    route: "",
    svgIcon: <TasksIcon />,
  },
  {
    title: "Questionares",
    route: clientendpoints.questionares.questionare,
    svgIcon: <QuestionerIcon />,
  },
  {
    title: "Financials",
    route: "",
    svgIcon: <FinancialsIcon />,
  },
  {
    title: "FindingReports",
    route: clientendpoints.reporting.findingReport,
    svgIcon: <FindingReportsIcon />,
  },
  {
    title: "Comms",
    route: "",
    svgIcon: <CommsIcon />,
  },
  {
    title: "MarketPlace",
    route: "",
    svgIcon: <MarketPlaceIcon />,
  },
  {
    title: "News",
    route: clientendpoints.media.media,
    svgIcon: <NewsIcon />,
  },
  {
    title: "Socials",
    route: "",
    svgIcon: <SocialIcon />,
  },
];

const Sidebar = (props) => {
  const [sidebarLinks, setSidebarLinks] = useState([...initailSidebaeLinks]);

  useEffect(() => {
    let preveliges = authService.getUserPreveliges();
    let updatedList = [];
    sidebarLinks.forEach((sidebarLink) => {
      let isSideBarLinkAuthorize = preveliges.some(
        (prev) =>
          prev.ScreenName === sidebarLink.title &&
          authService.isAdminOrCustomPrevelige(prev.ScreenPriviliges)
      );
      if (isSideBarLinkAuthorize) {
        updatedList.push(sidebarLink);
      }
    });
    setSidebarLinks([...updatedList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* sidebar */}
      <aside
        className="d-flex flex-column flex-shrink-0 "
        style={{ width: 100 }}
      >
        <a
          href="/"
          className="d-block p-3 link-dark text-decoration-none text-center"
          title="Icon-only"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
        >
          <img src="images/logo.png" alt="Logo" height={46} width={46} />
          <span className="visually-hidden">Icon-only</span>
        </a>
        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          {sidebarLinks && sidebarLinks.length > 0 ? (
            sidebarLinks.map((link, index) => {
              return (
                <li className="nav-item" key={index}>
                  <Link
                    to={link.route}
                    className={`nav-link ${
                      props.activeTab === link.route ? "active" : ""
                    } py-3 border-bottom rounded-0`}
                    aria-current="page"
                    title={link.title}
                  >
                    {link.svgIcon}
                    <span className="d-block">{link.title}</span>
                  </Link>
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
      </aside>
      {/* sidebar/end */}
    </>
  );
};

export default Sidebar;
