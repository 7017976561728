import React, { Component } from "react";

class ActionCards extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div className="dash_ovrall">
          <div className="row">
            <div className="col-lg-4">
              <div className="dash_card">
                <div className="trans_detail">
                  <div className="d-flex flex-column gap-2">
                    <strong className="fw-normal text-color">
                      Urgent Issues
                    </strong>
                    <span className="text-color-second">
                      {this.props.cardsData.totalUrgentIssues
                        ? this.props.cardsData.totalUrgentIssues.length
                        : "0"}
                    </span>
                  </div>
                  <span className="card_ico">
                    <img src="images/exclamation.svg" alt="Cart Icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dash_card">
                <div className="trans_detail">
                  <div
                    className="d-flex flex-column gap-2 progress-bar-style2"
                    style={{ width: "100%" }}
                  >
                    <strong className="fw-normal text-color">
                      Overall Compliance
                    </strong>
                    <div className="progress-bar-sub">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow={
                            this.props.cardsData.OverAllCompalience
                          }
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{
                            width: `${this.props.cardsData.OverAllCompalience}%`,
                          }}
                        >
                          <span>
                            {this.props.cardsData.OverAllCompalience}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dash_card">
                <div className="trans_detail">
                  <div className="d-flex flex-column gap-2">
                    <strong className="fw-normal text-color">
                      Key Actioned
                    </strong>
                    <span className="text-color-thrd">
                      {this.props.cardsData.keyActioned.totalKeyActioned
                        .length -
                        this.props.cardsData.keyActioned.totalKeyUrgentIssues
                          .length}
                      /
                      {this.props.cardsData.keyActioned.totalKeyActioned.length}
                    </span>
                  </div>
                  <div className="progress-rt yellow">
                    <span className="progress-left">
                      <span
                        className="progress-bar-rt"
                        style={{
                          transform: `rotate(${
                            this.props.cardsData.keyActioned
                              .totalKeyUrgentIssues.length /
                              this.props.cardsData.keyActioned.totalKeyActioned
                                .length <=
                            180
                              ? 0
                              : ((this.props.cardsData.keyActioned
                                  .totalKeyUrgentIssues.length /
                                  this.props.cardsData.keyActioned
                                    .totalKeyActioned.length -
                                  180) /
                                  180) *
                                100
                          }deg)`,
                        }}
                      />
                    </span>
                    <span className="progress-right">
                      <span
                        className="progress-bar-rt"
                        style={{
                          transform: `rotate(${
                            this.props.cardsData.keyActioned
                              .totalKeyUrgentIssues.length /
                              this.props.cardsData.keyActioned.totalKeyActioned
                                .length >=
                            180
                              ? 180
                              : (this.props.cardsData.keyActioned
                                  .totalKeyUrgentIssues.length /
                                  this.props.cardsData.keyActioned
                                    .totalKeyActioned.length /
                                  180) *
                                100
                          }deg)`,
                        }}
                      />
                    </span>
                    <div className="progress-value">
                      {this.props.cardsData.keyActioned.totalKeyActioned
                        .length -
                        this.props.cardsData.keyActioned.totalKeyUrgentIssues
                          .length}
                      /
                      {this.props.cardsData.keyActioned.totalKeyActioned.length}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ActionCards;
