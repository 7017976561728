export function authHeader() {
  // return authorization header with jwt token
  const token = sessionStorage.getItem("JWTtoken");

  const userToken = token || "";

  if (userToken && userToken.length > 0) {
    return {
      Authorization: `Bearer ${userToken}`,
    };
  }
  return {};
}
