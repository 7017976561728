import React from "react";

const ChartTaskModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="expendModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen ">
          <div className="modal-content">
            <div className="dash_card">
              <div className="card_head  d-flex align-items-center justify-content-between border-bottom">
                <h2 className="card_title">Diary</h2>
                <button
                  type="button"
                  className="btn-close border-0 bg-transparent"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {/* card-head/end */}
              <div className="card_body">
                <div className="chart_action  py-2 px-2  px-md-4 py-md-4">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group d-inline-block">
                        <label htmlFor="year" className="visually-hidden">
                          Select Chart Year
                        </label>
                        <select name="" id="" className="form-control">
                          <option value={0}>Select Year</option>
                          <option value={2022}>2022</option>
                          <option value={2023}>2023</option>
                        </select>
                      </div>
                    </div>
                    {/* col/end */}
                    <div className="col-6">
                      <ul className="list-unstyled  text-end d-flex gap-0 flex-column">
                        <li>
                          Amount spent <strong>£2800</strong>
                        </li>
                        <li>
                          Amount hold <strong>£12 00</strong>
                        </li>
                        <li>
                          Reserve hold <strong>£2,348</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* /end */}
                <div id="chartModal"></div>
              </div>
            </div>
            {/* card/end */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartTaskModal;
