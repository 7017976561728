import React from "react";
import { questionTypesEnum } from "../../../enums/questionTypesEnum";
import MultiSelectField from "./MultiSelectField";

const QuestionForm = ({
  questions,
  ischildQuestion,
  handleChange,
  currentStep,
  stepsData,
}) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <>
      {questions && questions.length > 0 ? (
        questions.map((question, index) => {
          let fields = ischildQuestion ? stepsData[currentStep].fields : {};
          let parentId = ischildQuestion
            ? fields[question.id].parentQuestionId
            : {};

          return (
            <>
              {ischildQuestion ? (
                <>
                  {parentId &&
                  parentId != null &&
                  fields[parentId].value == fields[parentId].correctOptionId ? (
                    <>
                      <div className="tw-rounded tw-bg-gray-100 tw-w-[600px] tw-bg-opacity-70 tw-flex tw-flex-col tw-mt-5 tw-pl-1 tw-pr-20 tw-py-4 tw-items-start max-md:tw-max-w-full max-md:tw-pr-5">
                        <div
                          className="tw-text-zinc-900 tw-text-sm tw-font-bold tw-whitespace-nowrap"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {capitalizeFirstLetter(question.text)}
                        </div>
                        <div className=" tw-mt-4 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 ">
                          {question.questionTypeId ==
                          questionTypesEnum.TextBox ? (
                            <input
                              type="text"
                              className="tw-border tw-flex   tw-w-[367px] tw-items-center tw-justify-between tw-gap-5 tw-mt-3 tw-pl-4 tw-pr-7 tw-py-3.5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-pr-5"
                              placeholder={capitalizeFirstLetter(question.text)}
                              name={question.id}
                              onChange={(e) => handleChange(e)}
                            />
                          ) : question.questionTypeId ==
                            questionTypesEnum.Date ? (
                            <input
                              type="date"
                              className="tw-border tw-flex   tw-w-[367px] tw-items-center tw-justify-between tw-gap-5 tw-mt-3 tw-pl-4 tw-pr-7 tw-py-3.5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-pr-5"
                              placeholder={capitalizeFirstLetter(question.text)}
                              name={question.id}
                              onChange={(e) => handleChange(e)}
                            />
                          ) : question.questionTypeId ==
                            questionTypesEnum.Dropdown ? (
                            <select
                              name={question.id}
                              onChange={(e) => handleChange(e)}
                              className="tw-border flex   tw-w-[367px] tw-items-center tw-justify-between tw-gap-5 tw-mt-3 tw-pl-4 tw-pr-7 tw-py-3.5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-pr-5"
                            >
                              <option
                                className=""
                                selected=""
                                disabled=""
                                value=""
                              >
                                {capitalizeFirstLetter(question.text)}
                              </option>
                              {question.questionOptions &&
                              question.questionOptions.length > 0 ? (
                                question.questionOptions.map(
                                  (option, index) => {
                                    return (
                                      <>
                                        <option value={option.id}>
                                          {capitalizeFirstLetter(option.text)}
                                        </option>
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </select>
                          ) : question.questionTypeId ==
                            questionTypesEnum.radio ? (
                            <>
                              <div className="tw-flex w-[185px] tw-max-w-full tw-items-stretch tw-gap-1.5 tw-mt-4 tw-self-start">
                                <div className="tw-stroke-[1px] tw-flex tw-aspect-square tw-flex-col tw-justify-center tw-items-stretch tw-pl-2 tw-pr-0.5 tw-py-1.5 tw-rounded-[50%]">
                                  <div className="tw-flex tw-shrink-0 tw-h-1.5 tw-flex-col tw-rounded-[50%]" />
                                </div>
                                <div className="tw-text-slate-500 tw-text-xs tw-leading-5 tw-self-center tw-grow tw-whitespace-nowrap tw-my-auto">
                                  <div className="tw-flex tw-flex-wrap">
                                    <div className="tw-flex tw-items-center tw-me-4">
                                      {question.questionOptions &&
                                      question.questionOptions.length > 0 ? (
                                        question.questionOptions.map(
                                          (option, index) => {
                                            return (
                                              <>
                                                <div
                                                  key={question.id}
                                                  className="tw-flex tw-items-center tw-me-4"
                                                >
                                                  <input
                                                    type="radio"
                                                    value={option.id}
                                                    name={question.id}
                                                    id={option.id}
                                                    onChange={(e) =>
                                                      handleChange(e)
                                                    }
                                                    className="tw-w-4 tw-h-4 tw-text-red-600 tw-bg-gray-100 tw-border-gray-300 "
                                                  />
                                                  <label
                                                    htmlFor={option.id}
                                                    className="tw-ms-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-gray-300"
                                                  >
                                                    {capitalizeFirstLetter(
                                                      option.text
                                                    )}
                                                  </label>
                                                </div>
                                              </>
                                            );
                                          }
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : question.questionTypeId ==
                            questionTypesEnum.Multiple_Choice ? (
                            <>
                              {question.questionOptions &&
                              question.questionOptions.length > 0 ? (
                                <>
                                  <MultiSelectField
                                    handleChange={handleChange}
                                    question={question}
                                  />{" "}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {question.childQuestions &&
                          question.childQuestions.length > 0 ? (
                            <>
                              <QuestionForm
                                questions={question.childQuestions}
                                ischildQuestion={true}
                                handleChange={handleChange}
                                currentStep={currentStep}
                                stepsData={stepsData}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <div
                    className={`tw-text-zinc-900 tw-text-sm tw-font-bold tw-whitespace-nowrap ${
                      index !== 0 ? "tw-mt-5" : ""
                    }`}
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {capitalizeFirstLetter(question.text)}
                  </div>
                  {question.questionTypeId == questionTypesEnum.TextBox ? (
                    <input
                      type="text"
                      className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-justify-center tw-mt-3.5 tw-pl-4 tw-pr-16 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 tw-items-start max-md:tw-pr-5"
                      placeholder={capitalizeFirstLetter(question.text)}
                      name={question.id}
                      onChange={(e) => handleChange(e)}
                    />
                  ) : question.questionTypeId == questionTypesEnum.Date ? (
                    <input
                      type="date"
                      className="tw-border flex tw-items-center tw-justify-between tw-gap-5 tw-mt-3 tw-pl-4 tw-pr-7 tw-py-3.5 tw-rounded-lg border-solid border-indigo-500 border-opacity-30 max-md:pr-5"
                      placeholder={capitalizeFirstLetter(question.text)}
                      name={question.id}
                      onChange={(e) => handleChange(e)}
                    />
                  ) : question.questionTypeId == questionTypesEnum.Dropdown ? (
                    <select
                      onChange={(e) => handleChange(e)}
                      name={question.id}
                      className="tw-border tw-flex tw-items-stretch tw-justify-between tw-gap-5 tw-mt-3.5 tw-pl-4 tw-pr-7 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-pr-5"
                    >
                      <option className="" disabled="" value="">
                        {capitalizeFirstLetter(question.text)}
                      </option>
                      {question.questionOptions &&
                      question.questionOptions.length > 0 ? (
                        question.questionOptions.map((option, index) => {
                          return (
                            <>
                              <option value={option.id}>
                                {capitalizeFirstLetter(option.text)}
                              </option>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                  ) : question.questionTypeId == questionTypesEnum.radio ? (
                    <>
                      <div className="tw-flex tw-w-[185px] tw-max-w-full tw-items-stretch tw-gap-1.5 tw-mt-4 tw-self-start">
                        <div className="tw-stroke-[1px] tw-flex tw-aspect-square tw-flex-col tw-justify-center tw-items-stretch tw-pl-2 tw-pr-0.5 tw-py-1.5 tw-rounded-[50%]">
                          <div className="tw-flex tw-shrink-0 tw-h-1.5 tw-flex-col tw-rounded-[50%]" />
                        </div>
                        <div className="tw-text-slate-500 tw-text-xs tw-leading-5 tw-self-center tw-grow tw-whitespace-nowrap tw-my-auto">
                          <div className="tw-flex tw-flex-wrap">
                            <div className="tw-flex tw-items-center tw-me-4">
                              {question.questionOptions &&
                              question.questionOptions.length > 0 ? (
                                question.questionOptions.map(
                                  (option, index) => {
                                    return (
                                      <>
                                        <div
                                          key={question.id}
                                          className="tw-flex tw-items-center tw-me-4"
                                        >
                                          <input
                                            id={option.id}
                                            type="radio"
                                            value={option.id}
                                            name={question.id}
                                            className="tw-w-4 tw-h-4 tw-text-red-600 tw-bg-gray-100 tw-border-gray-300 "
                                            onChange={(e) => handleChange(e)}
                                          />
                                          <label
                                            htmlFor={option.id}
                                            className="tw-ms-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-gray-300"
                                          >
                                            {capitalizeFirstLetter(option.text)}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : question.questionTypeId ==
                    questionTypesEnum.Multiple_Choice ? (
                    <>
                      {question.questionOptions &&
                      question.questionOptions.length > 0 ? (
                        <>
                          <MultiSelectField
                            handleChange={handleChange}
                            question={question}
                          />{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {question.childQuestions &&
                  question.childQuestions.length > 0 ? (
                    <>
                      <QuestionForm
                        questions={question.childQuestions}
                        ischildQuestion={true}
                        handleChange={handleChange}
                        currentStep={currentStep}
                        stepsData={stepsData}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default QuestionForm;
