import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { clientendpoints } from "../../../endpoints/endpoints";
import { propertiesActions } from "../../../redux/actions";

function JoinPropertyConfirm(props) {
  const [isValidToken, setIsValidToken] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("inviteToken");

  const checkTokenValidity = async () => {
    // If there is no token, or if the token is not valid, redirect to login
    if (!token && !isValidToken) {
      history(clientendpoints.auth.login);
      return;
    }

    props
      .validateRequestToJoinProperty(token)
      .then((data) => {
        setIsValidToken(true);
        return;
      })
      .catch((err) => {
        if (!isValidToken) {
          history(clientendpoints.auth.login);
        }
      });
  };

  useEffect(() => {
    checkTokenValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isValidToken ? (
        <div className="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-bg-indigo-500">
          <div className="tw-bg-white tw-p-8 tw-rounded tw-shadow-md tw-max-w-md tw-w-full tw-text-center">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="tw-mx-auto tw-h-12 tw-w-12 tw-text-green-500 tw-mb-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm1-12a1 1 0 0 0-2 0v4a1 1 0 0 0 2 0v-4zm0 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
            </svg> */}
            <h2 className="tw-text-2xl tw-font-semibold tw-mb-6 tw-text-green-500">
              Welcome to Acara Method
            </h2>
            <p className="tw-text-gray-700 tw-mb-4">
              You are now added to the property. You
              can now log in and start using our services.
            </p>
            <Link
              to={clientendpoints.auth.login}
              className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-blue-600"
            >
              Log In
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
    validateRequestToJoinProperty: (token) => dispatch(propertiesActions. validateRequestToJoinProperty(token)),
});

// mapping actions via props
export default connect(mapStateToProps, mapDispatchToProps)(JoinPropertyConfirm);