// eslint-disable-next-line import/no-cycle

import { GLOBAL_STATUS } from "../constants/globalConstants";
import urls from "../helpers/urls";
import { authService } from "../services/auth.service";
import { clientendpoints } from "../endpoints/endpoints";

function handleHTTPResponses(httpResponse) {
  let errorMessages = [];
  if (httpResponse) {
    const { message } = httpResponse.data;
    if (message === "Network Error") {
      errorMessages.push("unable_to_access_server");
      return Promise.reject(errorMessages);
    }
    const responseStatus = httpResponse.status;

    if (responseStatus !== 200) {
      if (responseStatus === 401) {
        authService.logout().then(window.location.assign(urls.login));
      } else if (responseStatus === 406) {
        if (httpResponse.data.msg === "otp") {
          window.location.assign(clientendpoints.otp.validateOtp);
        } else if (httpResponse.data.msg === "phone") {
          window.location.assign(clientendpoints.otp.addPhoneNumber);
        } else if (httpResponse.data.msg === "pricing") {
          window.location.assign(clientendpoints.pricing.price);
        } else if (httpResponse.data.msg === "newUserParentQuestionare") {
          window.location.assign(
            clientendpoints.newUserQuestionare.questionareSubmit
          );
        } else if (httpResponse.data.msg === "newUserQuestionare") {
          window.location.assign(
            clientendpoints.newUserQuestionare.questionare
          );
        }
      }
      if (httpResponse.data.msg) {
        errorMessages = httpResponse.data.msg;
      }
      return Promise.reject(errorMessages);
    }
    return httpResponse;
  }
  errorMessages.push("unable_to_access_server");
  return Promise.reject(errorMessages);
}

function handleResponseByStatus(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (response.status === GLOBAL_STATUS.UNAUTHORIZED) {
      // auto logout if 401 response returned from api
      authService.logout().then(window.location.assign(urls.login));
    }

    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  });
}

function handleServerResponse(response) {
  const { statusCode, msg, errors = [], data } = response.data;
  if (statusCode === GLOBAL_STATUS.SUCCESS) {
    return data;
  }
  return Promise.reject(msg);
}

export default {
  handleHTTPResponses,
  handleResponseByStatus,
  handleServerResponse,
};
