import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import InputMask from "react-input-mask";
import logo from "../../images/logo.png";
import profile from "../../images/profile.png";
import { useLocation} from "react-router-dom";
import PasswordInput from "../../Shared/PasswordInput";
import { clientendpoints } from "../../../endpoints/endpoints";
import { authService } from "../../../services/auth.service";
import { withNavigation } from "../../Shared/withNavigation";
import Spinner from "../../Shared/Spinner";

const Signup = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("inviteToken");
  const emailFromToken = params.get("email");

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastname: "",
    email: "",
    password: "",
    profilePicture: null,
    phonenumber: "",
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    const dataToSend = new FormData();
    dataToSend.append("firstName", userDetails.firstName);
    dataToSend.append("lastname", userDetails.lastname);
    dataToSend.append("email", userDetails.email);
    dataToSend.append("password", userDetails.password);
    dataToSend.append("profilePicture", userDetails.profilePicture);
    dataToSend.append("phonenumber", userDetails.phonenumber);
    if (token !== null) {
      dataToSend.append("inviteToken", token);
    }

    authService
      .register(dataToSend)
      .then((res) => {
        setUserDetails({
          firstName: "",
          lastname: "",
          email: "",
          password: "",
          phonenumber: "",
        });
        setLoading(false);
        toast.success(
          "Signup successful! A confirmation email has been sent. Please check your inbox."
        );
        props.navigate(clientendpoints.auth.login);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setUserDetails({
      ...userDetails,
      profilePicture: event.target.files ? event.target.files[0] : null,
    });
  };

  const isEmailFieldDisabled = () => {
    return emailFromToken !== null;
  };

  useEffect(()=>{
    if (emailFromToken !== null && userDetails.email === "") {
      setUserDetails({...userDetails, email: emailFromToken});
    }
  },[emailFromToken])

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="tw-bg-indigo-500">
          <section className="tw-gap-5 tw-flex max-md:tw-flex-col max-md:tw-items-stretch max-md:tw-gap-0">
            <div className="tw-rounded-r-2xl tw-bg-white tw-flex tw-flex-col tw-w-[55%] max-md:tw-w-full max-md:tw-ml-0 tw-items-center">
              <div className="tw-flex tw-w-[426px] tw-max-w-full tw-flex-col tw-mt-20 tw-mb-11 max-md:tw-mr-2.5 max-md:tw-my-10 tw-px-4">
                <h1 className="tw-text-zinc-900 tw-text-3xl tw-font-bold tw-leading-10 tw-self-stretch tw-whitespace-nowrap max-md:tw-max-w-full">
                  Welcome - Sign up
                </h1>
                <p className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-self-stretch tw-mt-3 max-md:tw-max-w-full">
                  First we will create you as a person, then we’ll create the
                  property
                </p>

                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  disabled={isEmailFieldDisabled()}
                  onChange={(e) => handleChange(e)}
                  value={userDetails.email}
                  required
                  className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                />

                <PasswordInput
                  placeholder={"Password"}
                  name={"password"}
                  value={userDetails.password}
                  handleChange={handleChange}
                />

                <InputMask
                  mask="(+44) 9999 999999"
                  placeholder="(+44) 1234 123456"
                  name="phonenumber"
                  value={userDetails.phonenumber}
                  onChange={(e) => handleChange(e)}
                  required
                  className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-mt-5 tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                ></InputMask>
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  required
                  value={userDetails.firstName}
                  onChange={(e) => handleChange(e)}
                  className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-mt-5 tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                />

                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastname"
                  required
                  value={userDetails.lastname}
                  onChange={(e) => handleChange(e)}
                  className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-mt-5 tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                />

                <div className="tw-self-stretch tw-flex tw-items-stretch tw-gap-1.5 tw-mt-6 tw-pr-20 max-md:tw-max-w-full max-md:tw-flex-wrap max-md:tw-pr-5">
                  <img
                    alt=""
                    loading="lazy"
                    src={
                      userDetails.profilePicture
                        ? URL.createObjectURL(userDetails.profilePicture)
                        : profile
                    }
                    className="tw-aspect-square tw-object-contain tw-object-center tw-w-[45px] tw-overflow-hidden tw-shrink-0 tw-max-w-full tw-rounded-[50%]"
                  />
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e)}
                    style={{ display: "none" }}
                  />
                  <label
                    htmlFor="fileInput"
                    className="tw-text-indigo-500 tw-text-center tw-text-xs tw-leading-4 tw-whitespace-nowrap tw-border tw-bg-white tw-self-center tw-my-auto tw-p-3.5 tw-rounded-lg tw-border-solid tw-border-gray-100"
                  >
                    Select an Picture
                  </label>
                </div>
                <div className="tw-self-stretch tw-flex tw-items-stretch tw-justify-between tw-gap-2.5 tw-mt-10 max-md:tw-max-w-full max-md:tw-flex-wrap ">
                  <button
                    type="submit"
                    disabled={userDetails.loading}
                    className="tw-text-white tw-text-center tw-text-sm tw-font-bold tw-leading-4 tw-whitespace-nowrap tw-bg-indigo-500 tw-grow tw-items-center tw-px-5 tw-py-4 tw-rounded-lg"
                  >
                    {loading ? <Spinner /> : " Sign Up"}
                  </button>
                  <Link
                    to={clientendpoints.auth.login}
                    style={{ backgroundColor: "#EFF2FE" }}
                    className="tw-text-indigo-500 tw-text-center tw-text-sm tw-font-bold tw-leading-4 tw-whitespace-nowrap tw-bg-[linear-gradient(0deg,rgba(94,129,244,0.10)_0%,rgba(94,129,244,0.10)_100%),#FFF] tw-grow tw-items-center tw-px-5 tw-py-4 tw-rounded-lg"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </div>

            <div className="tw-hidden md:tw-flex tw-flex-col tw-items-stretch tw-w-[45%] tw-ml-5 max-md:tw-w-full max-md:tw-ml-0">
              <div className="tw-bg-indigo-500 tw-flex tw-grow tw-flex-col tw-itemsretch tw-w-full tw-mx-auto max-md:tw-max-w-full">
                <div className="tw-flex-col tw-overflow-hidden tw-relative tw-flex tw-min-h-[900px] tw-w-full tw-items-center tw-px-5 tw-py-12 max-md:tw-max-w-full">
                  <img
                    alt=""
                    loading="lazy"
                    src={logo}
                    className="tw-aspect-square tw-object-contain tw-object-center tw-w-[419px] tw-overflow-hidden tw-max-w-full tw-mt-48 tw-mb-36 max-md:tw-my-10"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </form>
    </>
  );
};

export default withNavigation(Signup);
