// JiraBoard.js
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { boardActions } from "../../redux/actions";
import TaskCard from "./TaskCard";

const getTodayDate =()=> {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return yyyy +'-' + mm + '-' + dd;
}

const initialTasksContent = {
  title: "Enter Title",
  startDate: getTodayDate(),
  endDate: getTodayDate(),
  ordering: 1,
}

const cssClasses = {
  Todo: {
    className: "todo_task_card",
    cardclass: "todo_task",
  },
  InProgress: {
    className: "progress_task_card",
    cardclass: "progress_task",
  },
  done: {
    className: "done_task_card",
    cardclass: "done_task",
  },
}

const KanBanBoard = (props) => {
  const {
    boradEnum
  } = props;

  const [tasks, setTasks] = useState({});

  const handleAddTask = (columnId) => {
    const newTask = {
      ...initialTasksContent,
      cardStatus: columnId
    };

    props.addOrUpdateTask(newTask, boradEnum);
  };

  const handleEditTask = (columnId, updatedCardDetails) => {
    if (updatedCardDetails === null) {
      return;
    }

    let dataToSend = {
      ...updatedCardDetails,
      startDate: getDateAsPerFormat(updatedCardDetails.startDate),
      endDate: getDateAsPerFormat(updatedCardDetails.endDate),
      cardStatus: columnId
    }

    props.addOrUpdateTask(dataToSend, boradEnum);
  };

  const handleDeleteTask = (taskId) => {
    props.deleteTask(taskId);
  };

  const getDateAsPerFormat =(date)=> {
    return date.split('T')[0];
  }

  const onDragEnd = (result) => {
    const { destination, source} = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const startColumn = tasks[source.droppableId];
    const finishColumn = tasks[destination.droppableId];

    if (startColumn === finishColumn) {
      const newTasks = [...startColumn];
      const [removed] = newTasks.splice(source.index, 1);
      newTasks.splice(destination.index, 0, removed);

      setTasks((prevTasks) => ({
        ...prevTasks,
        [source.droppableId]: newTasks,
      }));
    } else {
      const startTasks = [...startColumn];
      const finishTasks = [...finishColumn];
      const [removed] = startTasks.splice(source.index, 1);
      finishTasks.splice(destination.index, 0, removed);

      setTasks((prevTasks) => ({
        ...prevTasks,
        [source.droppableId]: startTasks,
        [destination.droppableId]: finishTasks,
      }));
    }
  };

  useEffect(()=>{
    setTasks(props.boardData)
  },[props.boardData]);

  return (
    <div className="tab_head">
      <div className="row">
        <div style={{ display: "flex" }}>
          <DragDropContext onDragEnd={onDragEnd}>
            {Object.keys(tasks).map((columnId, index) => (
              <div className="col-lg-4" key={`${columnId}_${index}`}>
                <div
                  className={`head_info ${cssClasses[columnId].cardclass}  d-flex align-items-center justify-content-between`}
                  style={{ margin: "8px" }}
                >
                  <div>
                    <strong>{columnId.toUpperCase()}</strong>{" "}
                    <span className="badge blue-color">
                      {tasks[columnId].length}
                    </span>
                  </div>
                  <div className="info_btn d-flex align-items-center gap-1">
                    <button  onClick={()=>{ handleAddTask(columnId) }}>
                      <span className="visually-hidden">Add Button</span>
                      <svg
                        width={12}
                        height={16}
                        viewBox="0 0 12 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 1.88889L6 14.1111"
                          stroke="#5E81F4"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                        <path
                          d="M11 8L1 8"
                          stroke="#5E81F4"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                  {/* info-btn/end */}
                </div>
                <div className="d-flex flex-column gap-3">
                  <div key={`${columnId}_${index}`} style={{ margin: 8 }}>
                    <Droppable droppableId={columnId} >
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {tasks[columnId].map((task, index) => (
                            <TaskCard 
                                task={task} 
                                index={index}
                                columnId={columnId}
                                boradEnum={boradEnum}
                                handleDeleteTask={handleDeleteTask}
                                handleEditTask={handleEditTask}
                                cardClass={cssClasses[columnId].className}/>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              </div>
            ))}
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  boardData: state.boardReducer.boardData,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addOrUpdateTask: (newTask, enumValue) => dispatch(boardActions.addOrUpdateTask(newTask, enumValue)),
  deleteTask: (taskId) => dispatch(boardActions.deleteTask(taskId)),
  updateTaskOrder: (taskDetail) => dispatch(boardActions.updateTaskOrder(taskDetail)),
});

// mapping actions via props
export default connect(mapStateToProps, mapDispatchToProps)(KanBanBoard);