import React, {useState} from "react";
import { Draggable } from "react-beautiful-dnd";
import { EditText} from 'react-edit-text';
import { toast } from "react-toastify";
import 'react-edit-text/dist/index.css';

const TaskCard = (props) => {
  const {
    task,
    index,
    columnId,
    boradEnum,
    cardClass,
    handleDeleteTask,
    handleEditTask
  } = props;

  const cardDetailsUpdateHandler = (componentId, e) => {
    let updatedCardDetails = null;

    if (componentId === 'taskStartDate') {
      var newStartDate = e.target.value;
      if (!isDateRangeValid(newStartDate, task.endDate)) {
        toast.error("Start date cannot be greater then end date.");
        return;
      } else {
        updatedCardDetails = {
          ...task,
          startDate: newStartDate
        }
      }
    } else if (componentId === 'taskEndDate') {
      var newEndDate = e.target.value;
      if (!isDateRangeValid(task.startDate, newEndDate)) {
        toast.error("Start date cannot be greater then end date.");
        return;
      } else {
        updatedCardDetails = {
          ...task,
          endDate: newEndDate
        }
      }
    } else if (componentId === 'taskTitle') {
      updatedCardDetails = {
        ...task,
        title: e.value
      }
    }

    handleEditTask(columnId, updatedCardDetails)
  }

  const getDateAsPerFormat =(date)=> {
    return date.split('T')[0];
  }

  const getNumberOfDays =()=> {
    const startMs = new Date(task.startDate).getTime();
    const endMs = new Date(task.endDate).getTime();

    const differenceMs = endMs - startMs;
    const daysDifference = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

    return daysDifference;
  }

  const isDateRangeValid =(startDate, endDate)=> {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    return endDateObj > startDateObj;
  }

  const getDragableId =()=> {
    return `${boradEnum}_${columnId}_${task.id}`;
  }

    return (
      <Draggable key={task.id} draggableId={getDragableId()} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              userSelect: "none",
              margin: "0 0 8px 0",
              minHeight: "50px",
              borderRadius: "4px",
              ...provided.draggableProps.style,
            }}
          >
            <div
              className={`task_card ${cardClass}`}
            >
              <EditText defaultValue={task.title} onSave={(e)=>{cardDetailsUpdateHandler('taskTitle', e)}}/>
              <div className="task_time d-flex align-items-center justify-content-start gap-3">
                <div className="task_date">
                  <svg
                    width={12}
                    height={14}
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 0V14" stroke="#C1C5CD" />
                    <path
                      d="M1.5 1.5H10.4593C10.645 1.5 10.7305 1.73101 10.5895 1.85185L7.6893 4.33774C7.59215 4.42101 7.5971 4.57282 7.69946 4.64959L11.02 7.14C11.1738 7.25532 11.0922 7.5 10.9 7.5H1.5"
                      stroke="#C1C5CD"
                    />
                  </svg>
                  <input 
                      id="taskStartDate" 
                      type="date" 
                      name="startDate"
                      style={{width: "42%"}}
                      value={getDateAsPerFormat(task.startDate)} 
                      onChange={(e)=>{cardDetailsUpdateHandler('taskStartDate', e)}} />
                  <span style={{margin: "0 10px"}}>-</span>
                  <input 
                      id="taskEndDate" 
                      type="date"
                      name="endDate"
                      style={{width: "42%"}}
                      className="red-color" 
                      value={getDateAsPerFormat(task.endDate)} 
                      onChange={(e)=>{cardDetailsUpdateHandler('taskEndDate', e)}} />
                </div>
                <div className="task_day">
                  <svg
                    width={17}
                    height={14}
                    viewBox="0 0 17 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 1.5C5 5.66667 9.2 11.5 14 1.5"
                      stroke="#C1C5CD"
                      strokeLinecap="round"
                    />
                    <path
                      d="M13.5 12.5215C12 8.35488 7.8 2.52155 3 12.5215"
                      stroke="#C1C5CD"
                      strokeLinecap="round"
                    />
                    <path
                      d="M1 1H16"
                      stroke="#C1C5CD"
                      strokeLinecap="round"
                    />
                    <path
                      d="M16 13.0215L1 13.0215"
                      stroke="#C1C5CD"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span style={{fontSize: "13px"}}>{getNumberOfDays()}d</span>
                </div>
              </div>
              <div className="task_btns d-flex gap-2 align-items-center justify-content-start">
                <button className="btn btn-outlined-primary" onClick={()=>{handleDeleteTask(task.id, columnId)}}>
                  Delete
                </button>
              </div> 
            </div>
          </div>
        )}
      </Draggable>
    );
  };
  
  export default TaskCard;