import React from "react";
import { clientendpoints } from "../../endpoints/endpoints";

const Header = (props) => {
  const { screenName } = props;

  const logout=()=>{
    sessionStorage.clear();
    window.location.assign(clientendpoints.auth.login);
  }
  return (
    <>
      <div className="top_head mb-4">
        <div className="row align-items-center">
          {screenName === undefined ? (
            <div className="col-8 col-md-9">
              <div className="prop_info pt-3 d-flex align-items-center gap-1">
                <img
                  src="images/property-thumbnail.png"
                  alt="Property Thumbnail"
                />
                <span className="ms-2 text-truncate">
                  Old Deer Park, 187 Kew Road, Richmond, London, United Kingdom,
                  TW9 2AZ
                </span>
              </div>
            </div>
          ) : (
            <div className="col-8 col-md-9">
              <div className="prop_info pt-3 d-flex align-items-center gap-1">
                <h2>{screenName}</h2>
              </div>
            </div>
          )}
          {/* col/end */}
          <div className="col-4 col-md-3 align-content-center justify-content-end">
            <div className="prop_action text-end d-flex align-items-center gap-3 justify-content-end">
              <a href="#" className="notifi_btn has_notifi">
                <img
                  src="images/noti-ico.svg"
                  alt="Notification"
                  width={15}
                  height={18}
                />
                <span className="visually-hidden">Nofitication Btn</span>
              </a>
              <a href="#" className="notifi_btn" onClick={()=>{logout()}}>
                <img
                  src="images/logout-icon.svg"
                  alt="Logout"
                  width={17}
                  height={12}
                />
              </a>
              <a href="#" className="profile_btn">
                <img
                  src="images/avatar.png"
                  alt="Profile Thumbnail"
                  className="rounded-circle"
                  height={34}
                  width={34}
                />
              </a>
              <button id="menuToggle" className="d-inline-block d-lg-none">
                <svg
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 7C4 6.44771 4.44772 6 5 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H5C4.44772 8 4 7.55229 4 7Z"
                    fill="#000000"
                  />
                  <path
                    d="M4 13.9998C4 13.4475 4.44772 12.9997 5 12.9997L16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15L5 14.9998C4.44772 14.9998 4 14.552 4 13.9998Z"
                    fill="#000000"
                  />
                  <path
                    d="M5 19.9998C4.44772 19.9998 4 20.4475 4 20.9998C4 21.552 4.44772 21.9997 5 21.9997H22C22.5523 21.9997 23 21.552 23 20.9998C23 20.4475 22.5523 19.9998 22 19.9998H5Z"
                    fill="#000000"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* col/end */}
        </div>
      </div>
    </>
  );
};

export default Header;
