import React, { Component } from "react";

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordVisible: false,
    };
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      passwordVisible: !prevState.passwordVisible,
    }));
  };

  render() {
    const { passwordVisible } = this.state;

    return (
      <div className="tw-relative">
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={(e) => this.props.handleChange(e)}
          value={this.props.value}
          style={{ width: "24.7rem" }}
          className=" tw-text-sm tw-border tw-self-stretch tw-flex tw-items-stretch tw-justify-between tw-gap-5 tw-mt-6 tw-pl-4 tw-pr-3.5 tw-py-4 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full max-md:tw-flex-wrap"
        />
        <a
          style={{ top: "1.4rem" }}
          className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-px-4 tw-text-gray-600"
          onClick={() => this.togglePasswordVisibility()}
        >
          {passwordVisible ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="tw-w-5 tw-h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="tw-w-5 tw-h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}
        </a>
      </div>
    );
  }
}

export default PasswordInput;
