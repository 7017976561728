import React, { useState } from "react";
import { sessionStorageConstant } from "../../../constants/globalConstants";

const Pricing = () => {
  const [email, setEmail] = useState(
    sessionStorage.getItem(sessionStorageConstant.email)
  );
  return (
    <>
      <stripe-pricing-table
        customer-email={email}
        pricing-table-id="prctbl_1OfmFoFXeUGgiNTvJdDjGius"
        publishable-key="pk_test_51OfgN0FXeUGgiNTvQ4IlBmjFcIjCuqzH6aOXYebwHz0HAuGKIkTQqRd1mljULLp65F9TgBQAHb9v2y5gn8ty01MO008ZL7CJzK"
      ></stripe-pricing-table>
    </>
  );
};

export default Pricing;
