import { updateObject } from "../../shared";
import {boardConstants, globalConstants} from "../../constants";

const { GET_ALL_BOARD_DATA } = boardConstants;

// default state
const initialState = {
    boardData: {}
};

// manupilating the state based on actions and returning the state
export const boardReducer = (state = initialState, action) => {
  switch (action.type) {
    case  GET_ALL_BOARD_DATA + globalConstants.SUCCESS:
      return updateObject(state, { boardData: action.data });
    default:
      return state;
  }
};