import React, { Component } from "react";
class ServiceChargeExpenditure extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div className="col-12 col-md-12 col-lg-12 order-3 order-xl-2">
          <div className="dash_card pb-4  p-4">
            <div className="card_head  d-flex align-items-center justify-content-between border-bottom p-0 pb-3">
              <h2 className="card_title">Service Charge Expenditure</h2>
              <button
                type="button"
                className="expand_btn border-0 bg-transparent"
                data-bs-toggle="modal"
                data-bs-target="#expendModal"
              >
                <svg
                  width={20}
                  height={17}
                  viewBox="0 0 20 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 5.4V1H19V11H14.2333"
                    stroke="#8181A5"
                    strokeLinecap="round"
                  />
                  <rect
                    x="0.5"
                    y="5.5"
                    width={13}
                    height={10}
                    stroke="#8181A5"
                  />
                </svg>
                <span className="visually-hidden">Expand Button</span>
              </button>
            </div>
            {/* card-head/end */}
            <div className="card_body">
              <div className="chart_action py-2 px-0  px-md-4 py-md-4">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group d-inline-block">
                      <label htmlFor="year" className="visually-hidden">
                        Select Chart Year
                      </label>
                      <select name="" id="" className="form-control">
                        <option value={0}>Select Year</option>
                        <option value={2022}>2022</option>
                        <option value={2023}>2023</option>
                      </select>
                    </div>
                  </div>
                  {/* col/end */}
                  <div className="col-6">
                    <ul className="list-unstyled  text-end d-flex gap-0 flex-column">
                      <li>
                        Amount spent <strong>£2800</strong>
                      </li>
                      <li>
                        Amount hold <strong>£12 00</strong>
                      </li>
                      <li>
                        Reserve hold <strong>£2,348</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /end */}
              <div id="chart"></div>
            </div>
          </div>
          {/* calendar/end */}
        </div>
      </>
    );
  }
}

export default ServiceChargeExpenditure;
