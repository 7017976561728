import React from "react";

const BankTransaction = () => {
  return (
    <>
      <div className="col-12 col-md-6 col-xl-12 order-2 order-xl-3">
        <div className="dash_card pb-0  p-4">
          <div className="card_head  d-flex align-items-center justify-content-between border-bottom p-0 pb-3">
            <h2 className="card_title p-0">Bank Transactions</h2>
            <div className="d-flex gap-3 align-items-center">
              <a href="#" className="blue-color text-capitalize">
                SEE ALL
              </a>
              <button
                type="button"
                className="expand_btn border-0 bg-transparent"
                data-bs-toggle="modal"
                data-bs-target="#bankModal"
              >
                <svg
                  width={20}
                  height={17}
                  viewBox="0 0 20 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 5.4V1H19V11H14.2333"
                    stroke="#8181A5"
                    strokeLinecap="round"
                  />
                  <rect
                    x="0.5"
                    y="5.5"
                    width={13}
                    height={10}
                    stroke="#8181A5"
                  />
                </svg>
                <span className="visually-hidden">Expand Button</span>
              </button>
            </div>
          </div>
          {/* card-head/end */}
          <div className="card_body d-flex flex-column gap-3 py-4">
            <div className="trans_card_list d-flex flex-column gap-3">
              <div className="trans_item">
                <div className="trans_detail">
                  <span className="card_ico">
                    <img src="images/cc-ico.svg" alt="Cart Icon" />
                  </span>
                  <div className="d-flex flex-column gap-2">
                    <strong className="fw-normal text-color">
                      Tim Deposit Pending Amount
                    </strong>
                    <span className="text-color-second">09/09/2023</span>
                  </div>
                </div>
                <strong className="price">$7,200.00</strong>
              </div>
              {/* trans/item */}
              <div className="trans_item">
                <div className="trans_detail">
                  <span className="card_ico">
                    <img src="images/cc-ico.svg" alt="Cart Icon" />
                  </span>
                  <div className="d-flex flex-column gap-2">
                    <strong className="fw-normal text-color">
                      Tim Deposit Pending Amount
                    </strong>
                    <span className="text-color-second">09/09/2023</span>
                  </div>
                </div>
                <strong className="price">$7,200.00</strong>
              </div>
              {/* trans/item */}
            </div>
            {/* lise/end */}
            <div className="text-end">
              <a href="#" className="btn btn-outlined-primary">
                + Add New Transaction
              </a>
            </div>
          </div>
        </div>
        {/* calendar/end */}
      </div>
    </>
  );
};

export default BankTransaction;
