import React, { Component } from "react";
import { roleService } from "../../../services/role.service";
import {
  previligeConstants,
  screenElementsConstant,
} from "../../../constants/globalConstants";
import { authService } from "../../../services/auth.service";

class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      isAdminPrevelige: false,
      isCustomPrevelige: false,
      prevelige: {},
      selectRoles: {},
      userUpdateButton: {},
    };
  }

  async componentDidMount() {
    this.setState({
      prevelige:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.UserAccessCount
            )
          : {},
      selectRoles:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.UserSelectRole
            )
          : {},

      userUpdateButton:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.UserUpdate
            )
          : {},

      isAdminPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.ADMIN
        : false,

      isCustomPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.CUSTOM
        : false,
    });
    await roleService
      .getAllActiveRoles()
      .then((data) => {
        this.setState({ roles: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <>
        <div
          className={`modal fade ${this.props.showMoal ? " show" : ""}`}
          tabIndex={-1}
          style={
            this.props.showMoal ? { display: "block", paddingLeft: "0px" } : {}
          }
        >
          <div className="modal-dialog modal-fullscreen ">
            <div className="modal-content">
              <div className="dash_card">
                <div className="card_head  d-flex align-items-center justify-content-between">
                  <h2 className="card_title">
                    {this.props.isEdit
                      ? `Edit User ${this.props.editData.user.email}`
                      : "Add new User"}
                  </h2>
                  <button
                    type="button"
                    className="btn-close border-0 bg-transparent"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.props.closeModal()}
                  >
                    <span className="visually-hidden">Expand Button</span>
                  </button>
                </div>
                <div className="card_body">
                  <div className="tab_container">
                    <ul
                      className="nav nav-pills mb-3 gap-2"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active blue-color"
                          id="pills-money-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-money1"
                          type="button"
                          role="tab"
                          aria-controls="pills-money"
                          aria-selected="true"
                        >
                          General
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-money1"
                        role="tabpanel"
                        aria-labelledby="pills-money-tab"
                        tabIndex={0}
                      >
                        <div className="tab_head">
                          <div className="container">
                            <form
                              id="RoleForm"
                              onSubmit={
                                this.state.isAdminPrevelige ||
                                (this.state.userUpdateButton &&
                                  authService.isFullControlPrevelige(
                                    this.state.userUpdateButton.Priviliges
                                  ))
                                  ? (e) =>
                                      this.props.handleEditModalSubmit(
                                        e,
                                        this.props.editData.user.id
                                      )
                                  : (e) => {
                                      e.preventDefault();
                                    }
                              }
                              method="post"
                            >
                              <div className="modal-height">
                                <div className="row scrollable">
                                  {this.state.isAdminPrevelige ||
                                  (this.state.isCustomPrevelige &&
                                    !authService.isNoAccess(
                                      this.state.prevelige?.Priviliges
                                    )) ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Access Count
                                        </label>

                                        <input
                                          name="accessFailedCount"
                                          maxLength={50}
                                          id="name"
                                          type="number"
                                          required
                                          disabled={
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.prevelige?.Priviliges
                                            )
                                              ? true
                                              : false
                                          }
                                          className="is-untouched is-pristine av-invalid form-control"
                                          defaultValue={
                                            this.props.isEdit
                                              ? this.props.editData.user
                                                  .accessFailedCount
                                              : ""
                                          }
                                        />

                                        <div
                                          id="NameInvalid-msg"
                                          className="invalid-feedback d-none"
                                        >
                                          This field is invalid
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {this.state.isAdminPrevelige ||
                                  (this.state.isCustomPrevelige &&
                                    !authService.isNoAccess(
                                      this.state.selectRoles?.Priviliges
                                    )) ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Select Role
                                        </label>

                                        <select
                                          name="roles"
                                          className="is-untouched is-pristine av-invalid form-control"
                                          required
                                          disabled={
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.selectRoles?.Priviliges
                                            )
                                              ? true
                                              : false
                                          }
                                        >
                                          {this.state.roles &&
                                          this.state.roles.length > 0 ? (
                                            this.state.roles.map(
                                              (role, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={role.id}
                                                    selected={this.props.editData.user?.roles?.some(
                                                      (rol) =>
                                                        rol.id === role.id
                                                    )}
                                                  >
                                                    {role.name}
                                                  </option>
                                                );
                                              }
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </select>

                                        <div
                                          id="NameInvalid-msg"
                                          className="invalid-feedback d-none"
                                        >
                                          This field is invalid
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {this.props.isEdit &&
                                  !this.props.editData.user.isActive ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Is Active
                                          <br />
                                          <input
                                            name="isActive"
                                            id="isActive"
                                            type="checkbox"
                                            className="ml-2"
                                          />
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <hr />

                              {this.state.isAdminPrevelige ||
                              (this.state.userUpdateButton &&
                                !authService.isNoAccess(
                                  this.state.userUpdateButton?.Priviliges
                                )) ? (
                                <button
                                  type="submit"
                                  disabled={
                                    !this.state.isAdminPrevelige &&
                                    authService.isReadOnlyPrevelige(
                                      this.state.userUpdateButton?.Priviliges
                                    )
                                      ? true
                                      : false
                                  }
                                  className={`submit-button btn btn-primary ${
                                    !this.state.isAdminPrevelige &&
                                    authService.isReadOnlyPrevelige(
                                      this.state.userUpdateButton?.Priviliges
                                    )
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  Update
                                </button>
                              ) : (
                                <></>
                              )}
                            </form>
                          </div>
                        </div>
                        {/* tab-head/end */}
                      </div>
                    </div>
                  </div>
                  {/* tab/end */}
                </div>
                {/* card-body/end */}
              </div>
              {/* card/end */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UserModal;
