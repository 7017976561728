import React, { useEffect, useState } from "react";
import SearchBar from "../../Shared/SearchBar";
import { authService } from "../../../services/auth.service";
import {
  previligeConstants,
  screenElementsConstant,
  screenNamesConstant,
} from "../../../constants/globalConstants";
import { mediaService } from "../../../services/media.service";
import { toast } from "react-toastify";

const Media = () => {
  const [screenDetails, setScreenDetails] = useState({
    screenName: screenNamesConstant.MEDIA,
    privilige: authService.getScreenPrivelige(screenNamesConstant.MEDIA),
    isAdminPrivilige: false,
    isCustomPrivilige: false,
    offSet: 0,
    loadmore: {},
    link: {},
  });
  const [news, setNews] = useState([]);
  const [allNews, setAllNews] = useState({});

  const getCurrentWeekDates = () => {
    const today = new Date();
    const currentDay = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    // Calculate the start date of the current week (Sunday)
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - currentDay);

    // Calculate the end date of the current week (Saturday)
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + (6 - currentDay));

    // Format dates in "YYYY-MM-DD" format
    const startDateFormatted = formatDate(startDate);
    const endDateFormatted = formatDate(endDate);

    return `${startDateFormatted},${endDateFormatted}`;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const loadMoreData = () => {
    // Fetch more data from API or any source
    // For now, I'll simulate adding data to the state
    const newData = []; // Replace this with your API call

    // Remove the first six items from the news array and store them in removedItems array
    const removed = allNews.slice(0, 6);

    if (removed.length === 0) {
      getNewsAllNewsFromThirdParty();
    } else {
      setAllNews((prevState) => [...prevState.slice(6)]);
      setNews((prevState) => [...prevState, divideArrayIntoGroups(removed, 3)]);
    }
  };

  const divideArrayIntoGroups = (array, groupSize) => {
    const dividedGroups = [];
    for (let i = 0; i < array.length; i += groupSize) {
      dividedGroups.push(array.slice(i, i + groupSize));
    }
    return dividedGroups;
  };

  const getNewsAllNewsFromThirdParty = () => {
    mediaService
      .getAllNews(
        `?access_key=${
          process.env.REACT_APP_MEDIA_API_KEY
        }&search=UK property news,Leasehold regulation,London property prices,London housing market trends,UK interest rates,Property regulation UK,Housing affordability London,UK leaseholder rights,UK property market forecast,Section 21 eviction UK,Help to Buy scheme UK,Property investment London,UK housing policy,Residential property law UK,UK rental market trends,Building safety standards UK&categories=general,science,business,technology&countries=gb&languages=en&date=${getCurrentWeekDates()}&sort=published_desc&limit=100&offset=${
          screenDetails.offSet
        }`
      )
      .then((data) => {
        setAllNews([...data.data]);
        setScreenDetails({
          ...screenDetails,
          offSet: screenDetails.offSet + data.pagination.count,
        });
        loadMoreData();
      })
      .catch((error) => {
        toast.error("Error in fetching data from api");
      });
  };

  const getScreenElementPriviliges = (elementName) => {
    return screenDetails.privilige.ScreenElementPriviliges.find(
      (element) => element.ElementName === elementName
    );
  };

  useEffect(() => {
    console.log("Awais akmal");
    setScreenDetails({
      ...screenDetails,
      isAdminPrevelige: screenDetails.privilige
        ? screenDetails.privilige.ScreenPriviliges === previligeConstants.ADMIN
        : false,

      isCustomPrevelige: screenDetails.privilige
        ? screenDetails.privilige.ScreenPriviliges === previligeConstants.CUSTOM
        : false,

      loadmore: getScreenElementPriviliges(screenElementsConstant.LoadMoreNews),
      link: getScreenElementPriviliges(screenElementsConstant.ShowLinks),
    });

    getNewsAllNewsFromThirdParty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <SearchBar />

          <header className="tw-self-stretch tw-mt-8 max-md:tw-max-w-full">
            {news.map((group, index) => {
              return (
                <div className="tw-gap-5 tw-flex max-md:tw-flex-col max-md:tw-items-stretch max-md:tw-gap-0 mb-3">
                  {group.map((news, index) => {
                    return (
                      <section
                        className={`tw-flex tw-flex-col tw-items-stretch tw-w-[33%] ${
                          index !== 0 ? "tw-ml-5" : ""
                        } max-md:tw-w-full max-md:tw-ml-0`}
                      >
                        <span className="tw-bg-white tw-flex tw-grow tw-flex-col tw-items-stretch tw-w-full tw-pt-7 tw-pb-12 tw-px-6 tw-rounded-2xl max-md:tw-mt-8 max-md:tw-px-5">
                          <img
                            alt=""
                            loading="lazy"
                            src={news.image}
                            className="tw-aspect-[1.88] tw-object-contain tw-object-center tw-w-full tw-overflow-hidden"
                          />
                          <h2 className="tw-text-black tw-text-base tw-font-bold tw-leading-6 tw-mt-6">
                            {news.title}
                          </h2>
                          <div
                            className="tw-text-stone-500 tw-text-xs tw-leading-5 tw-mt-6"
                            aria-label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                          >
                            {news.description}
                          </div>
                          {screenDetails.isAdminPrivilige ||
                          (screenDetails.loadmore &&
                            !authService.isNoAccess(
                              screenDetails.link?.Priviliges
                            )) ? (
                            <a
                              href={
                                !screenDetails.isAdminPrivilige &&
                                authService.isReadOnlyPrevelige(
                                  screenDetails.loadmore?.Priviliges
                                )
                                  ? "#"
                                  : news.url
                              }
                              disabled={
                                !screenDetails.isAdminPrivilige &&
                                authService.isReadOnlyPrevelige(
                                  screenDetails.link?.Priviliges
                                )
                                  ? true
                                  : false
                              }
                              target="_blank"
                              className="tw-text-indigo-500 tw-text-sm tw-leading-5 tw-mt-8"
                            >
                              Read More
                            </a>
                          ) : (
                            <></>
                          )}
                        </span>
                      </section>
                    );
                  })}
                </div>
              );
            })}
          </header>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12  text-center">
          {screenDetails.isAdminPrivilige ||
          (screenDetails.loadmore &&
            !authService.isNoAccess(screenDetails.loadmore?.Priviliges)) ? (
            <button
              type="submit"
              disabled={
                !screenDetails.isAdminPrivilige &&
                authService.isReadOnlyPrevelige(
                  screenDetails.loadmore?.Priviliges
                )
                  ? true
                  : false
              }
              onClick={() => loadMoreData()}
              className="tw-text-indigo-500 tw-text-center tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-rounded 
                              tw-border tw-bg-white tw-self-center tw-justify-center tw-items-stretch tw-mt-12 tw-px-11 tw-py-2.5 tw-border-solid tw-border-gray-100
                              max-md:tw-mt-10 max-md:tw-px-5"
            >
              Show More
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Media;
