import React, { Component } from "react";
import SearchBar from "../../Shared/SearchBar";
import KeyActioned from "./ChildComponents/KeyActioned";
import DetailedFindings from "./ChildComponents/DetailedFindings";
import ActionCards from "./ChildComponents/ActionsCards";
import Trackers from "./ChildComponents/Trackers";
import Reminders from "./ChildComponents/Reminders";
import DocumentStorage from "./ChildComponents/DocumentStorage";
import { reportService } from "../../../services/reports.service";
import { toast } from "react-toastify";
import { questionTypesEnum } from "../../../enums/questionTypesEnum";

class FindingReports extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    actionCardsData: {
      totalUrgentIssues: [],
      OverAllCompalience: 0,
      keyActioned: {
        totalKeyActioned: [],
        totalKeyUrgentIssues: [],
      },
      centificateQuestions: [],
    },
    finalQuestions: [],
  };

  getActionCardsData = (finalQuestions) => {
    let totalUrgentIssues = [];
    let totalKeyActioned = [];
    let totalKeyUrgentIssues = [];
    let OverAllCompalience = 0;
    let centificateQuestions = [];
    finalQuestions.forEach((question) => {
      if (question.question.isUrgentItem) {
        if (
          question.question.questionTypeId === questionTypesEnum.Date ||
          question.question.questionTypeId === questionTypesEnum.DateTime
        ) {
          if (question.question.isCertificateQuestion) {
            try {
              const parsedDate = new Date(question.fieldValue);

              // Get the current date
              const currentDate = new Date();

              // Check if the parsed date is expired
              if (parsedDate < currentDate) {
                totalUrgentIssues.push(question);
              }
            } catch (error) {
              totalUrgentIssues.push(question);
            }
          }
        } else if (
          question.question.questionTypeId === questionTypesEnum.radio ||
          question.question.questionTypeId === questionTypesEnum.CheckBox ||
          question.question.questionTypeId ===
            questionTypesEnum.Multiple_Choice ||
          question.question.questionTypeId === questionTypesEnum.Dropdown
        ) {
          if (
            question.question.questionTypeId === questionTypesEnum.radio ||
            question.question.questionTypeId === questionTypesEnum.Dropdown
          ) {
            if (question.selectedOptions.length > 0) {
              let isCorrect = false;
              question.selectedOptions.forEach((opt) => {
                isCorrect = opt.isCorrect;
              });

              if (!isCorrect) {
                totalUrgentIssues.push(question);
              }
            } else {
              totalUrgentIssues.push(question);
            }
          } else if (
            question.question.questionTypeId === questionTypesEnum.CheckBox ||
            question.question.questionTypeId ===
              questionTypesEnum.Multiple_Choice
          ) {
            if (question.selectedOptions.length === 0) {
              totalUrgentIssues.push(question);
            }
          }
        } else if (
          (question.question.questionTypeId === questionTypesEnum.TextBox ||
            question.question.questionTypeId === questionTypesEnum.File) &&
          question.fieldValue.length === 0
        ) {
          totalUrgentIssues.push(question);
        }
      }
      if (question.question.isKeyActionedQuestion) {
        totalKeyActioned.push(question);
        if (
          question.question.questionTypeId === questionTypesEnum.Date ||
          question.question.questionTypeId === questionTypesEnum.DateTime
        ) {
          if (question.question.isCertificateQuestion) {
            try {
              const parsedDate = new Date(question.fieldValue);

              // Get the current date
              const currentDate = new Date();

              // Check if the parsed date is expired
              if (parsedDate < currentDate) {
                totalKeyUrgentIssues.push(question);
              }
            } catch (error) {
              totalKeyUrgentIssues.push(question);
            }
          }
        } else if (
          question.question.questionTypeId === questionTypesEnum.radio ||
          question.question.questionTypeId === questionTypesEnum.CheckBox ||
          question.question.questionTypeId ===
            questionTypesEnum.Multiple_Choice ||
          question.question.questionTypeId === questionTypesEnum.Dropdown
        ) {
          if (
            question.question.questionTypeId === questionTypesEnum.radio ||
            question.question.questionTypeId === questionTypesEnum.Dropdown
          ) {
            if (question.selectedOptions.length > 0) {
              let isCorrect = false;
              question.selectedOptions.forEach((opt) => {
                isCorrect = opt.isCorrect;
              });

              if (!isCorrect) {
                totalKeyUrgentIssues.push(question);
              }
            } else {
              totalKeyUrgentIssues.push(question);
            }
          } else if (
            question.question.questionTypeId === questionTypesEnum.CheckBox ||
            question.question.questionTypeId ===
              totalKeyUrgentIssues.Multiple_Choice
          ) {
            if (question.selectedOptions.length === 0) {
              totalKeyUrgentIssues.push(question);
            }
          }
        } else if (
          (question.question.questionTypeId === questionTypesEnum.TextBox ||
            question.question.questionTypeId === questionTypesEnum.File) &&
          question.fieldValue.length === 0
        ) {
          totalKeyUrgentIssues.push(question);
        }
      }
      if (question.question.isOverAllCompiliance) {
        OverAllCompalience += parseInt(question.question.compilianceScore);
      }

      if (question.question.isCertificateQuestion) {
        centificateQuestions.push(question);
      }
    });

    const actionsBarData = {
      totalUrgentIssues: totalUrgentIssues,
      OverAllCompalience: OverAllCompalience,
      keyActioned: {
        totalKeyActioned: totalKeyActioned,
        totalKeyUrgentIssues: totalKeyUrgentIssues,
      },
      centificateQuestions: centificateQuestions,
    };
    console.log(actionsBarData);
    return actionsBarData;
  };

  async componentDidMount() {
    await reportService
      .getSolvedQuestionareByUserForFindingReport()
      .then((data) => {
        if (data) {
          let questionsMap = new Map();

          data.userAnswers.forEach((element) => {
            const { questionId, questionOption, ...rest } = element;
            const question = questionsMap.get(questionId);
            if (question) {
              question.selectedOptions.push(questionOption);
            } else {
              let data = {
                ...rest,
              };

              if (questionOption) {
                data.selectedOptions = [questionOption];
              }
              questionsMap.set(questionId, data);
            }
          });

          const finalQuestions = [...questionsMap.values()];

          this.setState({
            actionCardsData: this.getActionCardsData(finalQuestions),
            finalQuestions: finalQuestions,
          });
        }
      })
      .catch((error) => {
        toast.error("Error in getting Finding Reports data.");
      });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-xl-8">
            <SearchBar />
            <ActionCards cardsData={this.state.actionCardsData} />
            <KeyActioned
              keyActioned={
                this.state.actionCardsData.keyActioned.totalKeyUrgentIssues
              }
            />
            <DetailedFindings details={this.state.finalQuestions} />
            <DocumentStorage />
          </div>
          <div className="col-xl-4">
            <div className="row">
              <Trackers
                certificates={this.state.actionCardsData.centificateQuestions}
              />
              <Reminders />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FindingReports;
