import React, { Component } from "react";
import QuestionareLogo from "../../images/questionareLogo.png";
import { questionareService } from "../../../services/questionare.service";
import { toast } from "react-toastify";
import QuestionForm from "./QuestionsForm";
import { clientendpoints } from "../../../endpoints/endpoints";
import { withNavigation } from "../../Shared/withNavigation";

class Questionare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      questionareTitle: "",
      currentStep: 1,
      totalSteps: 0,
      stepsData: {},
      questionareId: 0,
    };
  }

  // initializeStepsData = (questions) => {
  //   let stepsData = {};

  //   for (let i = 1; i <= questions.length; i++) {
  //     let fieldsList = {};
  //     let questionGroup = questions[i - 1];

  //     questionGroup.forEach((element) => {
  //       let fields = {};
  //       fields.name = element.id;
  //       fields.value = "";
  //       fields.isAnswered = false;
  //       fields.isRequired = true;
  //       fields.qtypeId = element.questionTypeId;
  //       fieldsList[element.id] = fields;

  //       if (element.childQuestions.length > 0) {
  //         element.childQuestions.forEach((cq) => {
  //           let newfield = {};
  //           newfield.name = cq.id;
  //           newfield.value = "";
  //           newfield.qtypeId = cq.questionTypeId;
  //           newfield.isAnswered = false;
  //           newfield.isRequired = false;
  //           fieldsList[cq.id] = newfield;
  //         });
  //       }
  //     });

  //     stepsData[i] = {
  //       fields: fieldsList,
  //       stepCompleted: false,
  //       isFirstStep: i == 1,
  //       isLastStep: i == questions.length,
  //     };
  //   }

  //   return stepsData;
  // };

  initializeStepsData = (questions) => {
    let stepsData = {};

    const processQuestion = (
      question,
      stepNumber,
      parentCorrectOptionId = null,
      parentQuestionId = null,
      isParentQuestion = true
    ) => {
      let fieldsList = {};

      question.forEach((element) => {
        let fields = {};
        fields.name = element.id;
        fields.value = "";
        fields.isAnswered = false;
        fields.isRequired = isParentQuestion;
        fields.hasChild = element.childQuestions.length > 0;
        fields.isParent = !parentCorrectOptionId;
        let correctOption = element.questionOptions.find(
          (item) => item.isCorrect === true
        );

        fields.parentQuestionId = parentQuestionId;
        fields.correctParentOptionId = parentCorrectOptionId;

        fields.correctOptionId = correctOption ? correctOption.id : null;
        fields.qtypeId = element.questionTypeId;
        fieldsList[element.id] = fields;

        if (element.childQuestions.length > 0) {
          const childStepNumber = `${stepNumber}.${element.id}`;
          const childFieldsList = processQuestion(
            element.childQuestions,
            childStepNumber,
            fields.correctOptionId,
            fields.name,
            false
          );
          fieldsList = { ...fieldsList, ...childFieldsList };
        }
      });

      return fieldsList;
    };

    for (let i = 1; i <= questions.length; i++) {
      let questionGroup = questions[i - 1];
      const fieldsList = processQuestion(questionGroup, i);

      stepsData[i] = {
        fields: fieldsList,
        stepCompleted: false,
        isFirstStep: i === 1,
        isLastStep: i === questions.length,
      };
    }

    console.log(stepsData);

    return stepsData;
  };

  handleChange = (e) => {
    const questionareGroup = this.state.stepsData[this.state.currentStep];

    let currentStepFields = questionareGroup.fields;

    // Find the index of the field with the given name
    const fieldIndex = currentStepFields[e.target.name];

    // Check if the field with the given name exists
    if (fieldIndex !== -1) {
      fieldIndex.value = e.target.value;
      if (fieldIndex.value) {
        fieldIndex.isAnswered = true;
      }
    }

    this.setState({
      stepsData: {
        ...this.state.stepsData,
        [this.state.currentStep]: {
          ...this.state.stepsData[this.state.currentStep],
          fields: currentStepFields,
        },
      },
    });
  };

  handleContinue = () => {
    const currentStepFields =
      this.state.stepsData[this.state.currentStep].fields;

    console.log(currentStepFields);
    // Convert the fields object values into an array
    const fieldsArray = Object.values(currentStepFields);
    // Check if all required fields are answered
    const requiredFields = fieldsArray.filter((obj) => obj.isRequired === true);

    const allFieldsAnswered = requiredFields.every((field) => field.isAnswered);

    if (this.state.currentStep == this.state.totalSteps && allFieldsAnswered) {
      let transformedArray = [];
      Object.values(this.state.stepsData).forEach((step) => {
        Object.values(step.fields).forEach((a) => {
          if (a.isAnswered) {
            let transformedObject = {
              name: a.name,
              value: a.value,
              qtypeId: a.qtypeId,
              isAnswered: a.isAnswered,
              isRequired: a.isRequired,
            };

            transformedArray.push(transformedObject);
          }
        });
      });

      let finalData = {
        answers: transformedArray,
        questionareId: this.state.questionareId,
      };

      questionareService
        .addUserQuestionareAnswers(finalData)
        .then((data) => {
          this.props.navigate(clientendpoints.dashboard);
        })
        .catch((err) => {
          toast.error(
            "there is an error in saving questionare answers please try again later"
          );
        });
    } else if (allFieldsAnswered) {
      this.setState({
        currentStep: this.state.currentStep + 1,
        stepsData: {
          ...this.state.stepsData,
          [this.state.currentStep]: {
            ...this.state.stepsData[this.state.currentStep],
            stepCompleted: true,
          },
        },
      });
    } else {
      toast.error("All Fields are required to continue");
    }
  };

  async componentDidMount() {
    questionareService
      .getUserUncompletedOnRegisterQuestionare()
      .then((data) => {
        const questionare = data.find(
          (item) => !item.UserQuestionare.isCompleted
        );

        if (questionare) {
          const questionareId = questionare.UserQuestionare.questionareId;

          questionareService
            .getQuestionareById(questionareId)
            .then((questionareData) => {
              console.log(questionareData == null ? true : false);
              if (questionareData) {
                const groupSize = 5;
                const questionGroups = [];
                for (
                  let i = 0;
                  i < questionareData.questions.length;
                  i += groupSize
                ) {
                  questionGroups.push(
                    questionareData.questions.slice(i, i + groupSize)
                  );
                }

                console.log(questionGroups);

                this.setState({
                  questionareId: questionareId,
                  questions: questionGroups,
                  questionareTitle: questionareData.title,
                  totalSteps: questionGroups.length,
                  stepsData: this.initializeStepsData(questionGroups),
                });
              } else {
                this.props.navigate(clientendpoints.dashboard);
              }
            })
            .catch((error) => {
              toast.error(error);
            });
        } else {
          this.props.navigate(clientendpoints.dashboard);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  render() {
    return (
      <>
        <header className="tw-bg-neutral-100 tw-flex tw-flex-col tw-pl-7 tw-pr-20 tw-pt-5 tw-pb-12 tw-items-start max-md:tw-px-5">
          <img
            alt=""
            loading="lazy"
            src={QuestionareLogo}
            className="tw-aspect-square tw-object-contain tw-object-center tw-w-[46px] tw-overflow-hidden tw-max-w-full"
          />
          {this.state.questions && this.state.questions.length > 0 ? (
            <>
              <div className="tw-shadow-sm tw-bg-white tw-self-center tw-flex tw-w-[1100px] tw-max-w-full tw-flex-col tw-items-stretch tw-mt-14 tw-mb-12 tw-pb-12 tw-rounded-lg max-md:tw-my-10">
                <div className="tw-bg-gray-100 tw-bg-opacity-70 tw-flex tw-justify-between tw-gap-4 tw-pl-16 tw-pb-7 tw-rounded-lg tw-items-end max-md:tw-max-w-full max-md:tw-flex-wrap max-md:tw-px-5">
                  <div className="tw-text-black tw-text-xs tw-font-bold tw-uppercase tw-grow tw-whitespace-nowrap tw-mt-16 max-md:tw-mt-10">
                    {this.state.questionareTitle}
                    {this.state.totalSteps !== 0 ? (
                      <>
                        {" "}
                        <ul className="tw-relative tw-flex tw-flex-row tw-gap-x-2">
                          {this.state.questions.map((questions, index) => {
                            return (
                              <>
                                {/* {JSON.stringify(this.state.stepsData)} */}
                                <li className="tw-flex tw-items-center tw-gap-x-2 tw-shrink tw-basis-0 tw-flex-1 tw-group">
                                  <div className="tw-min-w-[28px] tw-min-h-[28px] tw-inline-flex tw-justify-center tw-items-center tw-text-xs tw-align-middle">
                                    <span
                                      className={`tw-w-3 tw-h-3 tw-flex tw-justify-center tw-items-center tw-flex-shrink-0  tw-font-medium tw-text-white tw-rounded-full ${
                                        this.state.stepsData[String(index + 1)]
                                          .stepCompleted ||
                                        index + 1 == this.state.currentStep
                                          ? "tw-bg-blue-custom"
                                          : this.state.stepsData[
                                              String(index + 1)
                                            ].isFirstStep
                                          ? "tw-bg-blue-custom"
                                          : " tw-bg-gray-200"
                                      }  `}
                                    ></span>
                                  </div>
                                  <div
                                    className={`tw-w-full tw-h-px tw-flex-1  ${
                                      this.state.stepsData[String(index + 1)]
                                        .stepCompleted
                                        ? "tw-bg-blue-custom"
                                        : " tw-bg-gray-200"
                                    } group-last:tw-hidden`}
                                  />
                                </li>
                              </>
                            );
                          })}
                          {/* Item */}

                          {/* End Item */}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* Stepper */}
                    {/* End Stepper */}
                  </div>
                </div>
                <div className="tw-flex tw-w-[353px] tw-max-w-full tw-flex-col tw-items-stretch tw-ml-16 tw-mt-12 tw-mb-6 tw-self-start max-md:tw-ml-2.5 max-md:tw-mt-10">
                  {this.state.questions && this.state.questions.length > 0 ? (
                    <QuestionForm
                      questions={
                        this.state.questions[this.state.currentStep - 1]
                      }
                      currentStep={this.state.currentStep}
                      stepsData={this.state.stepsData}
                      ischildQuestion={false}
                      handleChange={this.handleChange}
                    />
                  ) : (
                    <></>
                  )}
                  <button
                    onClick={() => this.handleContinue()}
                    className="tw-text-white tw-text-center tw-text-sm tw-font-bold tw-bg-indigo-500 tw-justify-center tw-items-center tw-mt-8 tw-px-16 tw-py-5 tw-rounded-lg max-md:tw-px-5"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </header>
      </>
    );
  }
}

export default withNavigation(Questionare);
