import { endpoints } from "../endpoints/endpoints";
import { deleteRequest, getRequest, postRequest } from "../system/server-calls";

import responseHandler from "../system/server-response-handler";

function checkIfUserSubmittedTheQuestionareForm() {
  return getRequest(
    endpoints.user.verifyUserSubmittedParentQuestionareForm,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getAllUsers(queryParam = "") {
  return getRequest(endpoints.user.getAllUsers + queryParam, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getUserById(id) {
  return getRequest(endpoints.user.getSpecificUserById + id, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function deleteUserById(id) {
  return deleteRequest(endpoints.user.getSpecificUserById + id, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function updateUserAndAssignRoleToUser(userid, data) {
  return postRequest(
    endpoints.user.updateUserAndAssignNewRoles + userid,
    data,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// exporting functions for other files
export const userService = {
  checkIfUserSubmittedTheQuestionareForm,
  getAllUsers,
  getUserById,
  updateUserAndAssignRoleToUser,
  deleteUserById,
};
