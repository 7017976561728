import React, { Component } from "react";

class Reminders extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <>
          <div className="col-12 col-md-6 col-xl-12">
            <div className="dash_card pb-4 p-4">
              <div className="card_head d-flex align-items-center justify-content-between border-bottom p-0 pb-3">
                <h2 className="card_title">Reminder</h2>
                <div className="d-flex gap-3 align-items-center">
                  <button
                    type="button"
                    className="btn btn-light bg-transparent blue-color font_12"
                    data-bs-toggle="modal"
                    data-bs-target="#DiaryModal"
                  >
                    + Add To Reminders
                  </button>
                </div>
              </div>
              {/* card-head/end */}
              <div className="card_body d-flex flex-column gap-2 py-4">
                <div className="trans_card_list d-flex flex-column gap-1">
                  {/* trans/item */}
                  <div className="trans_item">
                    <div className="trans_detail">
                      <span className="card_ico expire">
                        <img
                          style={{ marginTop: "12px", marginLeft: "3px" }}
                          src="images/alert.svg"
                          alt="Cart Icon"
                        />
                      </span>
                      <div className="d-flex flex-column gap-2">
                        <strong className="fw-normal text-color">
                          Remind Me Later
                        </strong>
                        <span className="text-color-second">
                          Add a reminder to edit this later.
                        </span>
                      </div>
                    </div>
                    <strong className="price">&nbsp;</strong>
                  </div>

                  {/* trans/item */}
                </div>
                {/* lise/end */}
              </div>
            </div>
            {/* calendar/end */}
          </div>
        </>
      </>
    );
  }
}

export default Reminders;
