import { updateObject } from "../../shared";
import { propertiesConstants, globalConstants } from "../../constants";

const { GET_All_USER_PROPERTIES, GET_PROPERTY_DETALIS } = propertiesConstants;

// default state
const initialState = {
  allUserProperties: [],
  propertyDetails: [],
};

// manupilating the state based on actions and returning the state
export const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_All_USER_PROPERTIES + globalConstants.SUCCESS:
      return updateObject(state, { allUserProperties: action.data });
    case GET_PROPERTY_DETALIS + globalConstants.SUCCESS:
      return updateObject(state, { propertyDetails: action.data });
    default:
      return state;
  }
};