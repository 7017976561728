import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import logo from "../../images/logo.png";
import tick from "../../images/tick.svg";
import Spinner from "../../Shared/Spinner";
import { withNavigation } from "../../Shared/withNavigation";
import { userService } from "../../../services/user.service";
import { clientendpoints } from "../../../endpoints/endpoints";
import { questionareService } from "../../../services/questionare.service";

const availableUnits = Array.from({ length: 50 }, (_, index) => index + 1);
const initialUserData = {
  location: "",
  postal_code: "",
  locality:"",
  country:"",
  administrative_area_level_1:"",
  suite:"",
  noOfUnits: "",
  selectedQuestionare: [],
};

const UserInfo = (props) => {
  const [userDetails, setUserDetails] = useState({ ...initialUserData });
  const [questionares, setQuestionares] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRequestingToJoin, setIsRequestingToJoin] = useState(false);
  const [isAutocompleteReady, setIsAutocompleteReady] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setLoading(false);
  };
  const handleShow = () => setShow(true);

  const divideIntoGroups = (objects, groupSize) => {
    const result = [];
    for (let i = 0; i < objects.length; i += groupSize) {
      result.push(objects.slice(i, i + groupSize));
    }
    return result;
  };

  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    let data = {
      address: userDetails.location,
      postCode: userDetails.postal_code,
      city: userDetails.locality,
      country: userDetails.country,
      state: userDetails.administrative_area_level_1,
      apt: userDetails.suite,
      noOfUnits: userDetails.noOfUnits,
      questionares: userDetails.selectedQuestionare,
      propertyName:"",
      propertyType:""
    };

    questionareService
      .addUserQuestionare(data)
      .then((res) => {
        if (res.isPropertyExits !== undefined && res.isPropertyExits === false) {
          toast.success("User Questionare Added successfully");
          setUserDetails({ ...initialUserData });
          setLoading(false);
          props.navigate(clientendpoints.auth.login);
        } else {
          handleShow();
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const requestToJoinExistingProperty = () => {
    setIsRequestingToJoin(true);
    let data = {
      address: userDetails.location,
      postCode: userDetails.postal_code,
    };
     questionareService
      .requestToJoinExistingProperty(data)
      .then((res) => {
        toast.success("Request to join to Existing property send.");
        setIsRequestingToJoin(false);
        handleClose();
        props.navigate(clientendpoints.auth.login);
      })
      .catch((err) => {
        setIsRequestingToJoin(false);
        handleClose();
        toast.error(err);
      });
  }

  const handleQuestionareSelection = (id) => {
    const { selectedQuestionare } = userDetails;

    // Check if the ID is already present in selectedQuestionare
    const index = selectedQuestionare.indexOf(id);

    if (index !== -1) {
      // If present, remove it
      const updatedSelection = [...selectedQuestionare];
      updatedSelection.splice(index, 1);
      setUserDetails({
        ...userDetails,
        selectedQuestionare: updatedSelection,
      });
    } else {
      // If not present, add it
      setUserDetails({
        ...userDetails,
        selectedQuestionare: [...userDetails.selectedQuestionare, id],
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await userService
        .checkIfUserSubmittedTheQuestionareForm()
        .then((data) => {
          if (data) {
            //props.navigate(clientendpoints.dashboard);
          }
        });

      await questionareService
        .getAllQuestionares()
        .then((data) => {
          setQuestionares([...divideIntoGroups(data.entity, 3)]);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, []);

  const CONFIGURATION = {
    ctaTitle: "Confirm Address",
    mapOptions: {
      center: { lat: 37.4221, lng: -122.0841 },
      fullscreenControl: true,
      mapTypeControl: false,
      streetViewControl: true,
      zoom: 14,
      zoomControl: true,
      maxZoom: 22,
      mapId: "",
    },
    mapsApiKey: process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY,
    capabilities: {
      addressAutocompleteControl: true,
      mapDisplayControl: true,
      ctaControl: true,
    },
  };

  const SHORT_NAME_ADDRESS_COMPONENT_TYPES = new Set([
    "street_number",
    "administrative_area_level_1",
    "postal_code",
  ]);

  const ADDRESS_COMPONENT_TYPES_IN_FORM = [
    "location",
    "locality",
    "administrative_area_level_1",
    "postal_code",
    "country",
  ];

  function getFormInputElement(componentType) {
    return document.getElementById(`${componentType}-input`);
  }

  function fillInAddress(place) {
    function getComponentName(componentType) {
      for (const component of place.address_components || []) {
        if (component.types[0] === componentType) {
          return SHORT_NAME_ADDRESS_COMPONENT_TYPES.has(componentType)
            ? component.short_name
            : component.long_name;
        }
      }
      return "";
    }

    function getComponentText(componentType) {
      return componentType === "location"
        ? `${getComponentName("street_number")} ${getComponentName("route")}`
        : getComponentName(componentType);
    }

    function updateAddressFields(componentType, value) {
      setUserDetails(prevState => {
        const newState = { ...prevState };
        newState[componentType] = value
        return newState;
      });
    }

    for (const componentType of ADDRESS_COMPONENT_TYPES_IN_FORM) {
      var newText = getComponentText(componentType);
      updateAddressFields(componentType, newText);
    }
  }

  function renderAddress(place, map, marker) {
    if (place.geometry && place.geometry.location) {
      map.setCenter(place.geometry.location);
      marker.position = place.geometry.location;
    } else {
      marker.position = null;
    }
  }

  const initAutocomplete = () => {
    var google = window.google;
      if (google === undefined) return;
      if (google.maps === undefined) return;

      if (google.maps.marker === undefined || 
          google.maps.places === undefined) {
          return;
      }

      const { Map } = google.maps;
      const { AdvancedMarkerElement } = google.maps.marker;
      const { Autocomplete } = google.maps.places;

      const mapOptions = CONFIGURATION.mapOptions;
      mapOptions.mapId = mapOptions.mapId || "DEMO_MAP_ID";
      mapOptions.center = mapOptions.center || { lat: 37.4221, lng: -122.0841 };

      const map = new Map(document.getElementById("gmp-map"), mapOptions);
      const marker = new AdvancedMarkerElement({ map });
      const autocomplete = new Autocomplete(getFormInputElement("location"), {
        fields: ["address_components", "geometry", "name"],
        types: ["address"],
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert(`No details available for input: '${place.name}'`);
          return;
        }
        renderAddress(place, map, marker);
        fillInAddress(place);
      });
      setIsAutocompleteReady(true);
  };

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 3;
    const retryInterval = 1000; // 1 second

    const initialize = () => {
      if (!isAutocompleteReady) {
        if (retryCount < maxRetries) {
          retryCount++;
          initAutocomplete();
          if (!isAutocompleteReady) {
            setTimeout(initialize, retryInterval);
          }
        } else {
          console.error('Google Autocomplete failed to initialize.');
        }
      }
    };

    initialize();

  }, [isAutocompleteReady]);

  return (
    <>
      <>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="tw-bg-indigo-500">
            <section className="tw-gap-5 tw-flex max-md:tw-flex-col max-md:tw-items-stretch max-md:tw-gap-0">
              <div className="tw-rounded-r-2xl tw-bg-white tw-flex tw-flex-col tw-w-[55%] max-md:tw-w-full max-md:tw-ml-0 tw-items-center">
                <div className="tw-flex tw-w-[426px] tw-max-w-full tw-flex-col tw-mt-20 tw-mb-11 max-md:tw-mr-2.5 max-md:tw-my-10 tw-px-4">
                  <h1 className="tw-text-zinc-900 tw-text-3xl tw-font-bold tw-leading-10 tw-self-stretch tw-whitespace-nowrap max-md:tw-max-w-full">
                    Welcome
                  </h1>
                  <p className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-self-stretch tw-mt-3 max-md:tw-max-w-full">
                    You will now be asked to answer a questionnaire which covers
                    details about your property. At the end of the
                    questionnaire, you'll be provided with a comprehensive
                    health check report which will highlight important
                    information as well as any immediate actions required on
                    your behalf. To help tailor this questionnaire. Please
                    select the boxes that are applicable to your property below.
                    If non are applicable, please click the continue button
                  </p>

                  <input
                    id="location-input"
                    type="text"
                    placeholder="Address"
                    name="location"
                    onChange={(e) => handleChange(e)}
                    value={userDetails.location}
                    required
                    className="tw-text-slate-500 tw-mt-5 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                  />

                  <input
                    id="country-input"
                    type="text"
                    placeholder="Country"
                    name="country"
                    onChange={(e) => handleChange(e)}
                    value={userDetails.country}
                    className="tw-text-slate-500 tw-mt-5 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                  />

                  <input
                    id="locality-input"
                    type="text"
                    placeholder="City"
                    name="locality"
                    onChange={(e) => handleChange(e)}
                    value={userDetails.locality}
                    className="tw-text-slate-500 tw-mt-5 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                  />

                  <input
                    id="postal_code-input"
                    type="text"
                    placeholder="Postal Code"
                    name="postal_code"
                    onChange={(e) => handleChange(e)}
                    value={userDetails.postal_code}
                    required
                    className="tw-text-slate-500 tw-mt-5 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                  />

                  <input
                    id="administrative_area_level_1-input"
                    type="text"
                    placeholder="State/Province"
                    name="administrative_area_level_1"
                    onChange={(e) => handleChange(e)}
                    value={userDetails.administrative_area_level_1}
                    className="tw-text-slate-500 tw-mt-5 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                  />

                  <input
                    id=""
                    type="text"
                    placeholder="Apt, Suite, etc (optional)"
                    name="suite"
                    onChange={(e) => handleChange(e)}
                    value={userDetails.suite}
                    className="tw-text-slate-500 tw-mt-5 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                  />

                  <div  style={{height:"300px"}} class="tw-text-slate-500 tw-mt-5 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full" id="gmp-map"></div>

                  <select
                    onChange={(e) => handleChange(e)}
                    required
                    name="noOfUnits"
                    className="tw-text-slate-500 tw-mt-5 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                  >
                    <option  className="tw-text-slate-500 tw-text-sm" disabled={true} selected={true} value="">
                      Number of units
                    </option>
                    {availableUnits.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>

                  <header className="tw-flex w-[407px] tw-max-w-full tw-flex-col max-md:tw-mb-10">
                    <div className="tw-text-zinc-900 tw-text-sm tw-font-bold tw-self-stretch tw-mt-10">
                      {" "}
                      Select Applicable Items{" "}
                    </div>

                    <div className="tw-self-stretch tw-mt-3 max-md:tw-pr-5">
                      {questionares && questionares.length > 0 ? (
                        questionares.map((questionare, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className={` ${
                                  index !== 0 ? "tw-mt-4" : ""
                                } tw-flex max-md:tw-flex-col max-md:tw-items-stretch max-md:tw-gap-0`}
                              >
                                {questionare.map((data, index) => {
                                  return (
                                    <>
                                      <div
                                        className={`tw-relative tw-flex tw-flex-col tw-items-stretch tw-w-[33%] ${
                                          index !== 0 ? "tw-ml-5" : ""
                                        }  max-md:tw-w-full max-md:tw-ml-0`}
                                        onClick={() =>
                                          handleQuestionareSelection(data.id)
                                        }
                                      >
                                        <div
                                          style={
                                            userDetails.selectedQuestionare.includes(
                                              data.id
                                            )
                                              ? { backgroundColor: "#EEEDF2" }
                                              : {}
                                          }
                                          className="tw-border tw-bg-white tw-flex tw-grow tw-flex-col tw-items-center tw-w-full tw-pt-6 tw-pb-3 tw-px-5 tw-rounded-lg tw-border-solid tw-border-gray-100 max-md:tw-mt-4"
                                        >
                                          <div
                                            className="tw-absolute tw-right-0 tw-mr-0 tw-mt-[-23px] tw-selected-tick"
                                            style={
                                              userDetails.selectedQuestionare.includes(
                                                data.id
                                              )
                                                ? {
                                                    backgroundColor: "#5E81F4",
                                                    padding: "4px",
                                                  }
                                                : {
                                                    // backgroundColor: "#5E81F4",
                                                    padding: "4px",
                                                  }
                                            }
                                          >
                                            {userDetails.selectedQuestionare.includes(
                                              data.id
                                            ) ? (
                                              <img
                                                loading="lazy"
                                                src={tick}
                                                className=""
                                                alt="Image description"
                                              />
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                          <img
                                            alt=""
                                            loading="lazy"
                                            src={require(`../../images/${data.iconName}`)}
                                            className="tw-aspect-[3.23] tw-object-contain tw-object-center tw-w-[71px] tw-overflow-hidden"
                                          />
                                          <div className="tw-bg-indigo-500 tw-self-stretch tw-shrink-0 tw-h-px" />
                                          <div
                                            className="tw-text-slate-500 tw-text-center tw-text-xs tw-self-stretch tw-mt-4"
                                            role="heading"
                                            aria-level={2}
                                          >
                                            {data.title}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </header>

                  <div className="tw-self-stretch tw-flex tw-items-stretch tw-justify-between  tw-mt-10 max-md:tw-max-w-full max-md:tw-flex-wrap ">
                    <button
                      type="submit"
                      disabled={loading}
                      className="tw-text-white tw-text-center tw-text-sm tw-font-bold tw-leading-4 tw-whitespace-nowrap tw-bg-indigo-500 tw-grow tw-items-center tw-px-5 tw-py-4 tw-rounded-lg"
                    >
                      {loading ? <Spinner /> : "Continue"}
                    </button>
                  </div>
                </div>
              </div>

              <div className="tw-hidden md:tw-flex tw-flex-col tw-items-stretch tw-w-[45%] tw-ml-5 max-md:tw-w-full max-md:tw-ml-0">
                <div className="tw-bg-indigo-500 tw-flex tw-grow tw-flex-col tw-itemsretch tw-w-full tw-mx-auto max-md:tw-max-w-full">
                  <div className="tw-flex-col tw-overflow-hidden tw-relative tw-flex tw-min-h-[900px] tw-w-full tw-items-center tw-px-5 tw-py-12 max-md:tw-max-w-full">
                    <img
                      alt=""
                      loading="lazy"
                      src={logo}
                      className="tw-aspect-square tw-object-contain tw-object-center tw-w-[419px] tw-overflow-hidden tw-max-w-full tw-mt-48 tw-mb-36 max-md:tw-my-10"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </form>
      </>
      <Modal show={show} onHide={()=>{handleClose()}}>
        <Modal.Header closeButton>
          <Modal.Title>Property already exist!</Modal.Title>
        </Modal.Header>
        <Modal.Body>This block already exists in our system. 
                    If you want to joint the existing block you will get a link on your email.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{handleClose()}}>
            Exit
          </Button>
          <Button style={{backgroundColor: "#6366f1", width:"80px"}} onClick={()=>{requestToJoinExistingProperty()}}>
          {isRequestingToJoin ? <Spinner /> : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default withNavigation(UserInfo);
