import React, { Component } from "react";

class Modal extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div
          className={`modal fade ${this.props.showMoal ? " show" : ""}`}
          id="createTaskModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={
            this.props.showMoal ? { display: "block", paddingLeft: "0px" } : {}
          }
        >
          <div className="modal-dialog modal-fullscreen ">
            <div className="modal-content">
              <div className="dash_card">
                <div className="card_head  d-flex align-items-center justify-content-between">
                  <h2 className="card_title">Create a Task</h2>
                  <button
                    type="button"
                    className="btn-close border-0 bg-transparent"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.props.closeModal()}
                  >
                    <span className="visually-hidden">Expand Button</span>
                  </button>
                </div>
                <div className="card_body">
                  <div className="tab_container">
                    <ul
                      className="nav nav-pills mb-3 gap-2"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active blue-color"
                          id="pills-money-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-money1"
                          type="button"
                          role="tab"
                          aria-controls="pills-money"
                          aria-selected="true"
                        >
                          + Money Tasks
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link dark-green-color"
                          id="pills-maintenance-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-maintenance1"
                          type="button"
                          role="tab"
                          aria-controls="pills-maintainance"
                          aria-selected="false"
                        >
                          + Maintenance Tasks
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link green-color"
                          id="pills-comms-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-comms1"
                          type="button"
                          role="tab"
                          aria-controls="pills-comms"
                          aria-selected="false"
                        >
                          + Comm's Tasks
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link yellow-color"
                          id="pills-document-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-document1"
                          type="button"
                          role="tab"
                          aria-controls="pills-document"
                          aria-selected="false"
                        >
                          + Document Tasks{" "}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link orange-color"
                          id="pills-admin-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-admin1"
                          type="button"
                          role="tab"
                          aria-controls="pills-admin"
                          aria-selected="false"
                        >
                          + Company Admin Tasks{" "}
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-money1"
                        role="tabpanel"
                        aria-labelledby="pills-money-tab"
                        tabIndex={0}
                      >
                        <div className="tab_head">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="head_info todo_task  d-flex align-items-center justify-content-between">
                                <div>
                                  <strong>TO DO</strong>{" "}
                                  <span className="badge blue-color">9</span>
                                </div>
                                <div className="info_btn d-flex align-items-center gap-1">
                                  <div className="dropdown">
                                    <button
                                      className="bg-transparent border-0 dropdown-toggle"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={13}
                                        height={3}
                                        viewBox="0 0 13 3"
                                        fill="none"
                                      >
                                        <circle
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          fill="#C1C5CD"
                                        />
                                        <circle
                                          cx="6.5"
                                          cy="1.5"
                                          r="1.5"
                                          fill="#C1C5CD"
                                        />
                                        <circle
                                          cx="11.5"
                                          cy="1.5"
                                          r="1.5"
                                          fill="#C1C5CD"
                                        />
                                      </svg>
                                    </button>
                                    <ul
                                      className="dropdown-menu"
                                      data-popper-placement="bottom-end"
                                    >
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Sublink -01
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Sublink -02
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Sublink -03
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  {/* dropdown */}
                                  <a href="#">
                                    <span className="visually-hidden">
                                      Add Button
                                    </span>
                                    <svg
                                      width={12}
                                      height={16}
                                      viewBox="0 0 12 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 1.88889L6 14.1111"
                                        stroke="#5E81F4"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                      />
                                      <path
                                        d="M11 8L1 8"
                                        stroke="#5E81F4"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                      />
                                    </svg>
                                  </a>
                                </div>
                                {/* info-btn/end */}
                              </div>
                              <div className="d-flex flex-column gap-3">
                                <div className="task_card todo_task_card">
                                  <h3>Raise a service charge demand</h3>
                                  <div className="task_time d-flex align-items-center justify-content-start gap-3">
                                    <div className="task_date">
                                      <svg
                                        width={12}
                                        height={14}
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1 0V14" stroke="#C1C5CD" />
                                        <path
                                          d="M1.5 1.5H10.4593C10.645 1.5 10.7305 1.73101 10.5895 1.85185L7.6893 4.33774C7.59215 4.42101 7.5971 4.57282 7.69946 4.64959L11.02 7.14C11.1738 7.25532 11.0922 7.5 10.9 7.5H1.5"
                                          stroke="#C1C5CD"
                                        />
                                      </svg>
                                      <span>5-11-23 -</span>
                                      <span className="red-color">7-11-23</span>
                                    </div>
                                    <div className="task_day">
                                      <svg
                                        width={17}
                                        height={14}
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.5 1.5C5 5.66667 9.2 11.5 14 1.5"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M13.5 12.5215C12 8.35488 7.8 2.52155 3 12.5215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M1 1H16"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M16 13.0215L1 13.0215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                      <span>7d</span>
                                    </div>
                                  </div>
                                  <div className="task_btns d-flex gap-2 align-items-center justify-content-start">
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn1
                                    </a>
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn2
                                    </a>
                                  </div>
                                </div>
                                {/* task-card/end */}
                                <div className="task_card todo_task_card">
                                  <h3>Raise a service charge demand</h3>
                                  <div className="task_time d-flex align-items-center justify-content-start gap-3">
                                    <div className="task_date">
                                      <svg
                                        width={12}
                                        height={14}
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1 0V14" stroke="#C1C5CD" />
                                        <path
                                          d="M1.5 1.5H10.4593C10.645 1.5 10.7305 1.73101 10.5895 1.85185L7.6893 4.33774C7.59215 4.42101 7.5971 4.57282 7.69946 4.64959L11.02 7.14C11.1738 7.25532 11.0922 7.5 10.9 7.5H1.5"
                                          stroke="#C1C5CD"
                                        />
                                      </svg>
                                      <span>5-11-23 -</span>
                                      <span className="red-color">7-11-23</span>
                                    </div>
                                    <div className="task_day">
                                      <svg
                                        width={17}
                                        height={14}
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.5 1.5C5 5.66667 9.2 11.5 14 1.5"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M13.5 12.5215C12 8.35488 7.8 2.52155 3 12.5215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M1 1H16"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M16 13.0215L1 13.0215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                      <span>7d</span>
                                    </div>
                                  </div>
                                  <div className="task_btns d-flex gap-2 align-items-center justify-content-start">
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn1
                                    </a>
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn2
                                    </a>
                                  </div>
                                </div>
                                {/* task-card/end */}
                                <div className="task_card todo_task_card">
                                  <h3>Raise a service charge demand</h3>
                                  <div className="task_time d-flex align-items-center justify-content-start gap-3">
                                    <div className="task_date">
                                      <svg
                                        width={12}
                                        height={14}
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1 0V14" stroke="#C1C5CD" />
                                        <path
                                          d="M1.5 1.5H10.4593C10.645 1.5 10.7305 1.73101 10.5895 1.85185L7.6893 4.33774C7.59215 4.42101 7.5971 4.57282 7.69946 4.64959L11.02 7.14C11.1738 7.25532 11.0922 7.5 10.9 7.5H1.5"
                                          stroke="#C1C5CD"
                                        />
                                      </svg>
                                      <span>5-11-23 -</span>
                                      <span className="red-color">7-11-23</span>
                                    </div>
                                    <div className="task_day">
                                      <svg
                                        width={17}
                                        height={14}
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.5 1.5C5 5.66667 9.2 11.5 14 1.5"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M13.5 12.5215C12 8.35488 7.8 2.52155 3 12.5215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M1 1H16"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M16 13.0215L1 13.0215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                      <span>7d</span>
                                    </div>
                                  </div>
                                  <div className="task_btns d-flex gap-2 align-items-center justify-content-start">
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn1
                                    </a>
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn2
                                    </a>
                                  </div>
                                </div>
                                {/* task-card/end */}
                              </div>
                            </div>
                            {/* col-end */}
                            <div className="col-lg-4">
                              <div className="head_info progress_task  d-flex align-items-center justify-content-between">
                                <div>
                                  <strong>Tasks in Progress</strong>{" "}
                                  <span className="badge orange-color">4</span>
                                </div>
                                <div className="info_btn d-flex align-items-center gap-1">
                                  <div className="dropdown">
                                    <button
                                      className="bg-transparent border-0 dropdown-toggle"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={13}
                                        height={3}
                                        viewBox="0 0 13 3"
                                        fill="none"
                                      >
                                        <circle
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          fill="#C1C5CD"
                                        />
                                        <circle
                                          cx="6.5"
                                          cy="1.5"
                                          r="1.5"
                                          fill="#C1C5CD"
                                        />
                                        <circle
                                          cx="11.5"
                                          cy="1.5"
                                          r="1.5"
                                          fill="#C1C5CD"
                                        />
                                      </svg>
                                    </button>
                                    <ul
                                      className="dropdown-menu"
                                      data-popper-placement="bottom-end"
                                    >
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Sublink -01
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Sublink -02
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Sublink -03
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  {/* dropdown */}
                                  <a href="#">
                                    <span className="visually-hidden">
                                      Add Button
                                    </span>
                                    <svg
                                      width={12}
                                      height={16}
                                      viewBox="0 0 12 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 1.88889L6 14.1111"
                                        stroke="#5E81F4"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                      />
                                      <path
                                        d="M11 8L1 8"
                                        stroke="#5E81F4"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                      />
                                    </svg>
                                  </a>
                                </div>
                                {/* info-btn/end */}
                              </div>
                              <div className="d-flex flex-column gap-3">
                                <div className="task_card progress_task_card">
                                  <h3>Raise a service charge demand</h3>
                                  <div className="task_time d-flex align-items-center justify-content-start gap-3">
                                    <div className="task_date">
                                      <svg
                                        width={12}
                                        height={14}
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1 0V14" stroke="#C1C5CD" />
                                        <path
                                          d="M1.5 1.5H10.4593C10.645 1.5 10.7305 1.73101 10.5895 1.85185L7.6893 4.33774C7.59215 4.42101 7.5971 4.57282 7.69946 4.64959L11.02 7.14C11.1738 7.25532 11.0922 7.5 10.9 7.5H1.5"
                                          stroke="#C1C5CD"
                                        />
                                      </svg>
                                      <span>5-11-23 -</span>
                                      <span className="red-color">7-11-23</span>
                                    </div>
                                    <div className="task_day">
                                      <svg
                                        width={17}
                                        height={14}
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.5 1.5C5 5.66667 9.2 11.5 14 1.5"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M13.5 12.5215C12 8.35488 7.8 2.52155 3 12.5215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M1 1H16"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M16 13.0215L1 13.0215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                      <span>7d</span>
                                    </div>
                                  </div>
                                  <div className="task_btns d-flex gap-2 align-items-center justify-content-start">
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn1
                                    </a>
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn2
                                    </a>
                                  </div>
                                </div>
                                {/* task-card/end */}
                                <div className="task_card progress_task_card">
                                  <h3>Raise a service charge demand</h3>
                                  <div className="task_time d-flex align-items-center justify-content-start gap-3">
                                    <div className="task_date">
                                      <svg
                                        width={12}
                                        height={14}
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1 0V14" stroke="#C1C5CD" />
                                        <path
                                          d="M1.5 1.5H10.4593C10.645 1.5 10.7305 1.73101 10.5895 1.85185L7.6893 4.33774C7.59215 4.42101 7.5971 4.57282 7.69946 4.64959L11.02 7.14C11.1738 7.25532 11.0922 7.5 10.9 7.5H1.5"
                                          stroke="#C1C5CD"
                                        />
                                      </svg>
                                      <span>5-11-23 -</span>
                                      <span className="red-color">7-11-23</span>
                                    </div>
                                    <div className="task_day">
                                      <svg
                                        width={17}
                                        height={14}
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.5 1.5C5 5.66667 9.2 11.5 14 1.5"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M13.5 12.5215C12 8.35488 7.8 2.52155 3 12.5215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M1 1H16"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M16 13.0215L1 13.0215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                      <span>7d</span>
                                    </div>
                                  </div>
                                  <div className="task_btns d-flex gap-2 align-items-center justify-content-start">
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn1
                                    </a>
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn2
                                    </a>
                                  </div>
                                </div>
                                {/* task-card/end */}
                                <div className="task_card progress_task_card">
                                  <h3>Raise a service charge demand</h3>
                                  <div className="task_time d-flex align-items-center justify-content-start gap-3">
                                    <div className="task_date">
                                      <svg
                                        width={12}
                                        height={14}
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1 0V14" stroke="#C1C5CD" />
                                        <path
                                          d="M1.5 1.5H10.4593C10.645 1.5 10.7305 1.73101 10.5895 1.85185L7.6893 4.33774C7.59215 4.42101 7.5971 4.57282 7.69946 4.64959L11.02 7.14C11.1738 7.25532 11.0922 7.5 10.9 7.5H1.5"
                                          stroke="#C1C5CD"
                                        />
                                      </svg>
                                      <span>5-11-23 -</span>
                                      <span className="red-color">7-11-23</span>
                                    </div>
                                    <div className="task_day">
                                      <svg
                                        width={17}
                                        height={14}
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.5 1.5C5 5.66667 9.2 11.5 14 1.5"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M13.5 12.5215C12 8.35488 7.8 2.52155 3 12.5215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M1 1H16"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M16 13.0215L1 13.0215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                      <span>7d</span>
                                    </div>
                                  </div>
                                  <div className="task_btns d-flex gap-2 align-items-center justify-content-start">
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn1
                                    </a>
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn2
                                    </a>
                                  </div>
                                </div>
                                {/* task-card/end */}
                              </div>
                            </div>
                            {/* col-end */}
                            <div className="col-lg-4">
                              <div className="head_info done_task  d-flex align-items-center justify-content-between">
                                <div>
                                  <strong>Done</strong>{" "}
                                  <span className="badge dark-green-color">
                                    2
                                  </span>
                                </div>
                                <div className="info_btn d-flex align-items-center gap-1">
                                  <div className="dropdown">
                                    <button
                                      className="bg-transparent border-0 dropdown-toggle"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={13}
                                        height={3}
                                        viewBox="0 0 13 3"
                                        fill="none"
                                      >
                                        <circle
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          fill="#C1C5CD"
                                        />
                                        <circle
                                          cx="6.5"
                                          cy="1.5"
                                          r="1.5"
                                          fill="#C1C5CD"
                                        />
                                        <circle
                                          cx="11.5"
                                          cy="1.5"
                                          r="1.5"
                                          fill="#C1C5CD"
                                        />
                                      </svg>
                                    </button>
                                    <ul
                                      className="dropdown-menu"
                                      data-popper-placement="bottom-end"
                                    >
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Sublink -01
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Sublink -02
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Sublink -03
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  {/* dropdown */}
                                </div>
                                {/* info-btn/end */}
                              </div>
                              <div className="d-flex flex-column gap-3">
                                <div className="task_card done_task_card">
                                  <h3>Raise a service charge demand</h3>
                                  <div className="task_time d-flex align-items-center justify-content-start gap-3">
                                    <div className="task_date">
                                      <svg
                                        width={12}
                                        height={14}
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1 0V14" stroke="#C1C5CD" />
                                        <path
                                          d="M1.5 1.5H10.4593C10.645 1.5 10.7305 1.73101 10.5895 1.85185L7.6893 4.33774C7.59215 4.42101 7.5971 4.57282 7.69946 4.64959L11.02 7.14C11.1738 7.25532 11.0922 7.5 10.9 7.5H1.5"
                                          stroke="#C1C5CD"
                                        />
                                      </svg>
                                      <span>5-11-23 -</span>
                                      <span className="red-color">7-11-23</span>
                                    </div>
                                    <div className="task_day">
                                      <svg
                                        width={17}
                                        height={14}
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.5 1.5C5 5.66667 9.2 11.5 14 1.5"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M13.5 12.5215C12 8.35488 7.8 2.52155 3 12.5215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M1 1H16"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M16 13.0215L1 13.0215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                      <span>7d</span>
                                    </div>
                                  </div>
                                  <div className="task_btns d-flex gap-2 align-items-center justify-content-start">
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn1
                                    </a>
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn2
                                    </a>
                                  </div>
                                </div>
                                {/* task-card/end */}
                                <div className="task_card done_task_card">
                                  <h3>Raise a service charge demand</h3>
                                  <div className="task_time d-flex align-items-center justify-content-start gap-3">
                                    <div className="task_date">
                                      <svg
                                        width={12}
                                        height={14}
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1 0V14" stroke="#C1C5CD" />
                                        <path
                                          d="M1.5 1.5H10.4593C10.645 1.5 10.7305 1.73101 10.5895 1.85185L7.6893 4.33774C7.59215 4.42101 7.5971 4.57282 7.69946 4.64959L11.02 7.14C11.1738 7.25532 11.0922 7.5 10.9 7.5H1.5"
                                          stroke="#C1C5CD"
                                        />
                                      </svg>
                                      <span>5-11-23 -</span>
                                      <span className="red-color">7-11-23</span>
                                    </div>
                                    <div className="task_day">
                                      <svg
                                        width={17}
                                        height={14}
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.5 1.5C5 5.66667 9.2 11.5 14 1.5"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M13.5 12.5215C12 8.35488 7.8 2.52155 3 12.5215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M1 1H16"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M16 13.0215L1 13.0215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                      <span>7d</span>
                                    </div>
                                  </div>
                                  <div className="task_btns d-flex gap-2 align-items-center justify-content-start">
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn1
                                    </a>
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn2
                                    </a>
                                  </div>
                                </div>
                                {/* task-card/end */}
                                <div className="task_card done_task_card">
                                  <h3>Raise a service charge demand</h3>
                                  <div className="task_time d-flex align-items-center justify-content-start gap-3">
                                    <div className="task_date">
                                      <svg
                                        width={12}
                                        height={14}
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1 0V14" stroke="#C1C5CD" />
                                        <path
                                          d="M1.5 1.5H10.4593C10.645 1.5 10.7305 1.73101 10.5895 1.85185L7.6893 4.33774C7.59215 4.42101 7.5971 4.57282 7.69946 4.64959L11.02 7.14C11.1738 7.25532 11.0922 7.5 10.9 7.5H1.5"
                                          stroke="#C1C5CD"
                                        />
                                      </svg>
                                      <span>5-11-23 -</span>
                                      <span className="red-color">7-11-23</span>
                                    </div>
                                    <div className="task_day">
                                      <svg
                                        width={17}
                                        height={14}
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.5 1.5C5 5.66667 9.2 11.5 14 1.5"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M13.5 12.5215C12 8.35488 7.8 2.52155 3 12.5215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M1 1H16"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M16 13.0215L1 13.0215"
                                          stroke="#C1C5CD"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                      <span>7d</span>
                                    </div>
                                  </div>
                                  <div className="task_btns d-flex gap-2 align-items-center justify-content-start">
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn1
                                    </a>
                                    <a
                                      href="#"
                                      className="btn btn-outlined-primary"
                                    >
                                      Custom Btn2
                                    </a>
                                  </div>
                                </div>
                                {/* task-card/end */}
                              </div>
                            </div>
                            {/* col-end */}
                          </div>
                        </div>
                        {/* tab-head/end */}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-maintenance1"
                        role="tabpanel"
                        aria-labelledby="pills-maintenance-tab"
                        tabIndex={0}
                      >
                        ...
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-comms1"
                        role="tabpanel"
                        aria-labelledby="pills-comms-tab"
                        tabIndex={0}
                      >
                        ...
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-document1"
                        role="tabpanel"
                        aria-labelledby="pills-document-tab"
                        tabIndex={0}
                      >
                        ...
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-admin1"
                        role="tabpanel"
                        aria-labelledby="pills-admin-tab"
                        tabIndex={0}
                      >
                        ...
                      </div>
                    </div>
                  </div>
                  {/* tab/end */}
                </div>
                {/* card-body/end */}
              </div>
              {/* card/end */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
