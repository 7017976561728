import { clientendpoints } from "../../endpoints/endpoints";
import { authService } from "../../services/auth.service";
import { Navigate } from "react-router-dom";

export const PublicRoute = ({ children }) => {
  const isAuthenticated = authService.isUserAuthenticated();

  if (!isAuthenticated) {
    return children;
  }

  return <Navigate to={clientendpoints.dashboard} />;
};
