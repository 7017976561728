export const endpoints = {
  auth: {
    login: "/api/users/authenticate",
    register: "/api/users/register",
    googleOAuthLogin: "/api/users/googleOauth",
    facebookOauthLogin: "/api/users/facebookOauth",
    verifyuser: "/api/users/verify/",
    forgotPassword: "/api/user/forgotPassword",
    validateRestPasswordToken: "/api/user/validatePasswordToken/",
    resetpassword: "/api/user/resetPassword",
  },
  otp: {
    addPhoneNumer: "/api/users/addPhoneNumber",
    regenerateOtp: "/api/users/regenerateOtp",
    getOtpExpiresAt: "/api/users/getOtpExpiredAtTime",
    validateOtp: "/api/users/validateOtp",
  },
  questionare: {
    getAllQuestionares: "/api/questionare",
    getUserUncompletedQuestionare: "/api/questionare/notCompleted",
    getAllNewUserQuestionare: "/api/questionare/newUser",
    getQuestionarebyId: "/api/questionare/",
    addQuestionareAnswers: "/api/questionare/addAnswers",
    addUserQuestionare: "/api/users/UserQuestionare",
    getAllDisplayTriggers: "/api/questionare/getAllDisplayTriggers",
    getUserSubmittedQuestionare: "/api/questionare/filled/getNewUserQuestion",
    requestToJoinExistingProperty: "/api/user/sendRequestToPropertyLeadUser"
  },

  questions: {
    getQuestionsByQuestionareId: "/api/question/getAllQuestions/",
    addOneQuestion: "/api/question/AddNewQuestion/",
    getQuestionByQuestionId: "/api/question/getQuestion/",
    updateQuestionById: "/api/question/updateQuestion/",
    deleteQuestionByQuestionId: "/api/question/deleteQuestion/",
  },

  roles: {
    role: "/api/role",
    getAllRoles: "/api/role/getAll/Active",
    getScreenElements: "/api/screens/getAllElements/",
    addScreenElements: "/api/screens/addAllElements/",
    getRoleScreenElements: "/api/screens/getRoleScreenElements/",
    addScreens: "/api/screens/addAllRoleScreens/",
  },
  user: {
    getUserUncompletedQuestionare: "/api/user/uncompletedQuestionare",
    verifyUserSubmittedParentQuestionareForm:
      "/api/user/IsSubmittedQuestionare",
    getAllUsers: "/api/users",
    getSpecificUserById: "/api/users/getUserById/",
    updateUserAndAssignNewRoles: "/api/user/updateUserAddRoles/",
  },

  media: {
    getNews: "http://api.mediastack.com/v1/news",
  },
  ping: {
    authorizePing: "/api/authorizePing",
    subscribedPing: "/api/subscribedPlan",
  },
};

export const clientendpoints = {
  auth: {
    login: "/login",
    register: "/register",
    accountConfirm: "/account/confirm",
    forgotPassword: "/account/recover",
    resetpassword: "/account/resetPassword",
  },
  otp: {
    addPhoneNumber: "/account/addPhoneNumber",
    validateOtp: "/account/ValidateOtp",
  },
  newUserQuestionare: {
    questionare: "/questionare/newUserRegisterQuestionare",
    questionareSubmit: "/questionare/submitUserInfo",
  },
  dashboard: "/",
  roles: {
    main: "/role",
  },
  questionares: {
    questionare: "/questionare",
  },
  users: {
    users: "/users",
  },
  properties: {
    propertiesScreen: "/properties",
    joinPropertyConfirmation: "/property/acceptInvite",
  },
  media: {
    media: "/media",
  },
  pricing: {
    price: "/pricing",
  },
  reporting: {
    findingReport: "/findingReport",
  },
};

export const boardEndpoints = {
  GET_BOARD_DATA: "/api/kanbanBoard/getAll",
  ADD_OR_UPDATE_TASK: "/api/kanbanBoard/addOrUpdateCard/",
  DELETE_TASK: "/api/kanbanBoard/deleteCard/",
  UPDATE_TASK_ORDER: "/api/kanbanBoard/updateCardOrder/"
};

export const propertiesEndpoints = {
  GET_All_USER_PROPERTIES: "/api/properties/getAll",
  GET_PROPERTY_DETALIS: "/api/properties/get/",
  ADD_USER_TO_PROPERTY:"/api/properties/inviteProperty",
  VALIDATE_JOIN_PROPERTY:"/api/properties/property/verify/"
};