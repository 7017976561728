import React, { useState } from "react";
import logo from "../../images/logo.png";
import PasswordInput from "../../Shared/PasswordInput";
import outlookIcon from "../../images/outlook.svg";
import { Link } from "react-router-dom";
import { clientendpoints } from "../../../endpoints/endpoints";
import { authService } from "../../../services/auth.service";
import { toast } from "react-toastify";
import { withNavigation } from "../../Shared/withNavigation";
import Spinner from "../../Shared/Spinner";
import GoogleLoginButton from "./googleLogin";
import FacebookLoginButton from "./FacebookLogin";

const Login = (props) => {
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    authService
      .login({
        email: loginDetails.email,
        password: loginDetails.password,
      })
      .then((res) => {
        setLoading({
          email: "",
          password: "",
        });
        setLoading(false);
        toast.success("Sign Successfull.");
        props.navigate(clientendpoints.dashboard);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const handleChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="tw-bg-indigo-500">
          <section className="tw-gap-5 tw-flex max-md:tw-flex-col max-md:tw-items-stretch max-md:tw-gap-0">
            <div className=" tw-rounded-r-2xl tw-bg-white tw-flex tw-flex-col tw-w-[55%] max-md:tw-w-full max-md:tw-ml-0  tw-items-center">
              <div className="tw-flex tw-w-[426px] tw-max-w-full tw-flex-col tw-mt-20 tw-mb-11 max-md:tw-mr-2.5 max-md:tw-my-10 tw-px-4">
                <h1 className="tw-text-zinc-900 tw-text-3xl tw-font-bold tw-leading-10 tw-self-stretch tw-whitespace-nowrap max-md:tw-max-w-full">
                  Welcome - Sign In
                </h1>
                <p className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-self-stretch tw-mt-3 max-md:tw-max-w-full">
                  Enter your details to proceed further
                </p>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  required
                  value={loginDetails.email}
                  onChange={(e) => handleChange(e)}
                  className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                />

                <PasswordInput
                  placeholder={"Password"}
                  name={"password"}
                  value={loginDetails.password}
                  className="tw-text-sm"
                  handleChange={handleChange}
                />

                <div className="tw-flex tw-w-full tw-items-stretch tw-justify-between tw-gap-5 tw-mt-6">
                  <label
                    htmlFor="keep-logged-in"
                    className="tw-flex tw-items-stretch tw-justify-between tw-gap-3.5"
                  >
                    <input
                      type="checkbox"
                      id="keep-logged-in"
                      className="tw-rounded tw-border tw-flex tw-w-6 tw-shrink-0 tw-h-6 tw-flex-col tw-border-solid tw-border-indigo-500 tw-border-opacity-30"
                    />
                    <label className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-grow tw-whitespace-nowrap tw-self-start">
                      Keep me logged in
                    </label>
                  </label>
                  <Link
                    to={clientendpoints.auth.forgotPassword}
                    className="tw-text-indigo-500 tw-text-sm tw-font-bold tw-leading-4  tw-whitespace-nowrap tw-mt-1"
                  >
                    Forgot login details
                  </Link>
                </div>

                <div className="tw-self-stretch tw-flex tw-items-stretch tw-justify-between tw-gap-2.5 tw-mt-10 max-md:tw-max-w-full max-md:tw-flex-wrap">
                  <button
                    className="tw-bg-indigo-500 tw-text-white tw-text-center tw-text-sm tw-font-bold tw-leading-4 tw-whitespace-nowrap tw-grow tw-items-center tw-px-5 tw-py-4 tw-rounded-lg"
                  >
                    {loading ? <Spinner /> : "Sign In"}
                  </button>
                  <Link
                    to={clientendpoints.auth.register}
                    style={{ backgroundColor: "#eff2fe"}}
                    className="tw-text-indigo-500 tw-text-center tw-text-sm tw-font-bold tw-leading-4 tw-whitespace-nowrap tw-grow tw-items-center tw-px-5 tw-py-4 tw-rounded-lg"
                  >
                    Sign Up
                  </Link>
                </div>

                <div className="tw-self-stretch tw-flex tw-items-stretch tw-justify-between tw-gap-2.5 tw-mt-8 max-md:tw-justify-center">
                  <div className="tw-bg-indigo-500 tw-bg-opacity-30 tw-w-[165px] tw-shrink-0 tw-h-px tw-self-start" />
                  <div className="tw-text-slate-500 tw-text-sm tw-leading-5">
                    or
                  </div>
                  <div className="tw-bg-indigo-500 tw-bg-opacity-30 tw-w-[166px] tw-shrink-0 tw-h-px tw-self-start" />
                </div>

                <GoogleLoginButton {...props} />
                <FacebookLoginButton {...props} />

                <div className="tw-bg-sky-500 tw-bg-opacity-10 tw-self-stretch tw-flex tw-w-full tw-flex-col tw-items-center tw-mt-3 tw-px-5 tw-py-3.5 tw-rounded-lg">
                  <div className="tw-flex tw-w-[152px] tw-max-w-full tw-items-stretch tw-gap-3">
                    <img
                      alt=""
                      loading="lazy"
                      src={outlookIcon}
                      className="tw-aspect-[1.05] tw-object-contain tw-object-center tw-w-[22px] tw-overflow-hidden tw-shrink-0 tw-max-w-full"
                    />
                    <div className="tw-text-indigo-800 tw-text-center tw-text-sm tw-leading-4 tw-grow tw-whitespace-nowrap tw-mt-1.5 tw-self-start">
                      Sign In with Outlook
                    </div>
                  </div>
                </div>

                <div className="tw-self-stretch tw-flex tw-items-stretch tw-justify-between tw-gap-1.5 tw-mt-11 tw-pr-0.5 max-md:tw-mt-10">
                  <a
                    href="#"
                    className="tw-text-indigo-500 tw-text-center tw-text-xs tw-leading-4 tw-whitespace-nowrap tw-border tw-bg-white tw-grow tw-items-center tw-px-5 tw-py-5 tw-rounded-lg tw-border-solid tw-border-gray-100"
                  >
                    Take a Look Around
                  </a>
                  <a
                    href="#"
                    className="tw-text-indigo-500 tw-text-center tw-text-xs tw-leading-4 tw-whitespace-nowrap tw-border tw-bg-white tw-grow tw-items-center tw-px-5 tw-py-5 tw-rounded-lg tw-border-solid tw-border-gray-100"
                  >
                    Book A Demo
                  </a>
                </div>
              </div>
            </div>

            <div className="tw-hidden md:tw-flex tw-flex-col tw-items-stretch tw-w-[45%] tw-ml-5 max-md:tw-w-full max-md:tw-ml-0">
              <div className="tw-bg-indigo-500 tw-flex tw-grow tw-flex-col tw-itemsretch tw-w-full tw-mx-auto max-md:tw-max-w-full">
                <div className="tw-flex-col tw-overflow-hidden tw-relative tw-flex tw-min-h-[900px] tw-w-full tw-items-center tw-px-5 tw-py-12 max-md:tw-max-w-full">
                  <img
                    alt=""
                    loading="lazy"
                    src={logo}
                    className="tw-aspect-square tw-object-contain tw-object-center tw-w-[419px] tw-overflow-hidden tw-max-w-full tw-mt-48 tw-mb-36 max-md:tw-my-10"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </form>
    </>
  );
};

export default withNavigation(Login);
