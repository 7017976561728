import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Dashboard from "../Dashboard/Dashboard";
import { clientendpoints } from "../../../endpoints/endpoints";
import { propertiesActions } from "../../../redux/actions";
import { roleService } from "../../../services";
import HOUSE from "../../images/house.JPG";
import EXPAND from "../../images/arrow_lg.png";
import FILTER from "../../images/my_cart.png";
import AVATAR from "../../images/avatar.png";
import AddUserToPropertyModal from "./AddUserToPropertyModal";

const PropertiesScreen = (props) => {
  const {
    allUserProperties,
    getAllUserProperties,
    getPropertyDetails,
    propertyDetails,
  } = props;

  const [allRoles, setAllRoles] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  const [addUserModal, setAddUserModal] = useState(false);
  const closeAddUserModal = () => setAddUserModal(false);
  const showAddUserModal = () => setAddUserModal(true);

  const getPropertyTitle = (property) => {
    var propertyTitle = "";
    if (property.Address) {
      propertyTitle = propertyTitle + property.Address;
    }
    if (property.City) {
      propertyTitle = propertyTitle + ` ${property.City}`;
    }
    return propertyTitle;
  };

  const onPropertyChange = (e) => {
    var propertyId = e.target.value;
    setSelectedPropertyId(propertyId);
    getPropertyDetails(propertyId);
  };

  const getAllActiveRoles = () => {
    roleService
      .getAllActiveRoles()
      .then((data) => {
        setAllRoles([...data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssociatedUsers = () => {
    var assUsers = [];
    if (propertyDetails.length > 0) {
      assUsers = propertyDetails[0].associatiedUsers;
    }
    return assUsers;
  };

  const getNumberOfUnits = () => {
    var numOfUnits = 0;
    if (propertyDetails.length > 0) {
      numOfUnits = propertyDetails[0].numberOfUnits;
    }
    return numOfUnits;
  };

  useEffect(() => {
    getAllUserProperties();
    getAllActiveRoles();
  }, []);

  useEffect(() => {
    if (allUserProperties.length > 0) {
      var firstPropertyId = allUserProperties[0].id;
      setSelectedPropertyId(firstPropertyId);
      getPropertyDetails(firstPropertyId);
    }
  }, [allUserProperties]);
  console.log(propertyDetails);
  return (
    <>
      <Dashboard
        activeTab={clientendpoints.properties.propertiesScreen}
        screenName="Properties"
      >
        <div className="row">
          <div className="col-6 col-md-6">
            <select
              onChange={(e) => {
                onPropertyChange(e);
              }}
              name="userProperties"
              className="user-properties-dropdown"
            >
              {allUserProperties.map((property) => {
                return (
                  <option value={property.id}>
                    {getPropertyTitle(property)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="search_sec property-image-container">
              <div>
                <img class="property-image" src={HOUSE} alt="Notification" />
              </div>
            </div>

            <div class="dash_card">
              <div class="card_head  d-flex align-items-center justify-content-between">
                <h2 class="card_title" style={{ marginLeft: "20px" }}>
                  Users
                </h2>
                <button
                  type="button"
                  class="expand_btn border-0 bg-transparent"
                  data-bs-toggle="modal"
                  data-bs-target="#comTaskModal"
                >
                  <img src={EXPAND} alt="Notification" />

                  <span class="visually-hidden">Expand Button</span>
                </button>
              </div>

              <div class="task_table">
                <div class="table_tr table_usr table_usr_fst">
                  <div class="table_text">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        style={{ display: "none" }}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        <strong>Name</strong>
                      </label>
                    </div>
                  </div>
                  <div class="table_btns d-flex property-users-header">
                    <strong className="btn property-users-header-titles">
                      Block/Unit
                    </strong>

                    <strong className="btn property-users-header-titles">
                      Account Type
                    </strong>
                  </div>
                </div>
                {getAssociatedUsers().map((user) => {
                  return (
                    <div class="table_tr table_usr">
                      <div class="table_text">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            style={{ display: "none" }}
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault1"
                          >
                            {user.firstName + ' ' +user.lastname}
                          </label>
                        </div>
                      </div>
                      <div class="table_btns d-flex gap-1 gap-xxl-2">
                        <a href="#" class="btn btn-secondary">
                          Profile
                        </a>
                        <a href="#" class="btn">
                          {getNumberOfUnits()}
                        </a>
                        <a href="#" class="btn">
                          {user.roles.length > 0 ? user.roles[0].name : "None"}
                        </a>
                      </div>
                    </div>
                  );
                })}

                <div class="table_foot">
                  <button
                    class="btn-primary usr_btn"
                    onClick={() => {
                      showAddUserModal();
                    }}
                  >
                    Add New User
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6">
            <div class="row">
              <div class="col-12 col-md-6  col-xl-12">
                <div class="dash_card pb-4 p-4">
                  <div class="card_head  d-flex align-items-center justify-content-between border-bottom p-0 pb-3">
                    <h2 class="card_title">Summary Feed</h2>
                    <div class="d-flex gap-3 align-items-center">
                      <a
                        href="#"
                        class="text-capitalize"
                        style={{ color: "#000", display: "flex" }}
                      >
                        <img src={FILTER} alt="Notification" /> &nbsp; Filters 
                      </a>
                      <button
                        type="button"
                        class="expand_btn border-0 bg-transparent"
                        data-bs-toggle="modal"
                        data-bs-target="#DiaryModal"
                      >
                        <img src={EXPAND} alt="Notification" />

                        <span class="visually-hidden">Expand Button</span>
                      </button>
                    </div>
                  </div>

                  <div class="card_body d-flex flex-column gap-2">
                    <div class="trans_card_list d-flex flex-column gap-1">
                      <div class="feed-container">
                        <div class="feed-header">
                          <div class="feed-user-detail">
                            <div class="feed-user-image">
                              <img src={AVATAR} />
                            </div>
                            <div class="feed-user-name">Jassy Godson</div>
                          </div>

                          <div>
                            <a href="#" class="btn btn-secondary v_btn">
                              View
                            </a>
                          </div>
                        </div>
                        <div className="feed-description">
                          <p style={{ margin: "0" }}>
                            Jassy Godson just joined the Acara Group under
                            Property Management role. Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                        </div>
                      </div>

                      <div class="feed-container">
                        <div class="feed-header">
                          <div class="feed-user-detail">
                            <div class="feed-user-image">
                              <img src={AVATAR} />
                            </div>
                            <div class="feed-user-name">Jassy Godson</div>
                          </div>

                          <div>
                            <a href="#" class="btn btn-secondary v_btn">
                              View
                            </a>
                          </div>
                        </div>
                        <div className="feed-description">
                          <p style={{ margin: "0" }}>
                            Jassy Godson just joined the Acara Group under
                            Property Management role. Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                        </div>
                      </div>
                      <div class="feed-container">
                        <div class="feed-header">
                          <div class="feed-user-detail">
                            <div class="feed-user-image">
                              <img src={AVATAR} />
                            </div>
                            <div class="feed-user-name">Jassy Godson</div>
                          </div>

                          <div>
                            <a href="#" class="btn btn-secondary v_btn">
                              View
                            </a>
                          </div>
                        </div>
                        <div className="feed-description">
                          <p style={{ margin: "0" }}>
                            Jassy Godson just joined the Acara Group under
                            Property Management role. Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                        </div>
                      </div>
                      <div class="feed-container">
                        <div class="feed-header">
                          <div class="feed-user-detail">
                            <div class="feed-user-image">
                              <img src={AVATAR} />
                            </div>
                            <div class="feed-user-name">Jassy Godson</div>
                          </div>

                          <div>
                            <a href="#" class="btn btn-secondary v_btn">
                              View
                            </a>
                          </div>
                        </div>
                        <div className="feed-description">
                          <p style={{ margin: "0" }}>
                            Jassy Godson just joined the Acara Group under
                            Property Management role. Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="table_foot text-center">
                    <a href="#" class="usr_btn">
                      Clear Feed
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
      <AddUserToPropertyModal
        showModal={addUserModal}
        handleClose={closeAddUserModal}
        allRoles={allRoles}
        selectedPropertyId={selectedPropertyId}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  allUserProperties: state.propertiesReducer.allUserProperties,
  propertyDetails: state.propertiesReducer.propertyDetails,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getAllUserProperties: () =>
    dispatch(propertiesActions.getAllUserProperties()),
  getPropertyDetails: (propertyId) =>
    dispatch(propertiesActions.getPropertyDetails(propertyId)),
});

// mapping actions via props
export default connect(mapStateToProps, mapDispatchToProps)(PropertiesScreen);
