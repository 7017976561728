import React from "react";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import { authService } from "../../../services/auth.service";
import { clientendpoints } from "../../../endpoints/endpoints";
import googleIcon from "../../images/google.svg";

function GoogleLoginButton(props) {
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const data = { access_token: tokenResponse.access_token };
      authService
        .googleAuthLogin(data)
        .then((res) => {
          toast.success("Sign Successfull.");
          props.navigate(clientendpoints.dashboard);
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  });

  return (
    <>
      <div className="tw-bg-red-500 tw-bg-opacity-10 tw-self-stretch tw-flex tw-w-full tw-flex-col tw-items-center tw-mt-7 tw-px-5 tw-py-3 tw-rounded-lg">
        <div className="tw-flex tw-w-[152px] tw-max-w-full tw-items-stretch tw-gap-3">
          <img
            alt=""
            loading="lazy"
            src={googleIcon}
            className="tw-aspect-square tw-object-contain tw-object-center tw-w-[22px] tw-overflow-hidden tw-shrink-0 tw-max-w-full"
          />
          <a
            onClick={() => googleLogin()}
            className="tw-text-red-500 tw-text-center tw-text-sm tw-leading-4 tw-grow tw-whitespace-nowrap tw-mt-1.5 tw-self-start"
          >
            Sign In with Google
          </a>
        </div>
      </div>
    </>
  );
}

export default GoogleLoginButton;
