import React, { Component } from "react";
import { questionTypesEnum } from "../../../../enums/questionTypesEnum";

class DetailedFindings extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  checkIfDateIsExpired = (fieldValue) => {
    const parsedDate = new Date(fieldValue);

    // Get the current date
    const currentDate = new Date();

    // Check if the parsed date is expired
    if (parsedDate < currentDate) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    return (
      <>
        <div className="dash_card">
          <div className="card_head  d-flex align-items-center justify-content-between">
            <h2 className="card_title">Detail Findings</h2>
            <button
              type="button"
              className="expand_btn border-0 bg-transparent"
              data-bs-toggle="modal"
              data-bs-target="#findingModal"
            >
              <svg
                width={20}
                height={17}
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 5.4V1H19V11H14.2333"
                  stroke="#8181A5"
                  strokeLinecap="round"
                />
                <rect x="0.5" y="5.5" width={13} height={10} stroke="#8181A5" />
              </svg>
              <span className="visually-hidden">Expand Button</span>
            </button>
          </div>
          {/* card-head/end */}
          <div className="task_table task_tables">
            <div className="table_tr">
              <div className="table_text">
                <div className="form-check">
                  <label
                    className="form-check-label question_label"
                    htmlFor="flexRadioDefault1"
                  >
                    Questions
                  </label>
                </div>
              </div>
              <div className="table_text">
                <div className="form-check">
                  <label
                    className="form-check-label finding_label"
                    htmlFor="flexRadioDefault1"
                  >
                    Findings
                  </label>
                </div>
              </div>
              <div className="table_btns align_self gap-1 gap-xxl-2 d-flex">
                <img   alt="" src={`images/green.png`} />
                <img   alt="" src={`images/yellow.png`} />
                <img   alt="" src={`images/red.png`} />
              </div>
            </div>
            {/* tr/end */}

            {this.props.details.slice(0, 3).map((question, index) => {
              return (
                <>
                  <div className="table_tr" key={index}>
                    <div className="table_text">
                      <div className="form-check">
                        <label
                          className="form-check-label question_label"
                          htmlFor="flexRadioDefault2"
                        >
                          {index + 1}. {question.question.text}
                        </label>
                      </div>
                    </div>

                    <div className="table_text">
                      <div className="form-check">
                        <label
                          className="form-check-label finding_label"
                          htmlFor="flexRadioDefault2"
                        >
                          {/* {index + 1}. {question.question.text} */}
                          {[
                            questionTypesEnum.Date,
                            questionTypesEnum.DateTime,
                            questionTypesEnum.TextBox,
                          ].includes(question.question.questionTypeId) ? (
                            question.fieldValue
                          ) : [
                              questionTypesEnum.Dropdown,
                              questionTypesEnum.radio,
                            ].includes(question.question.questionTypeId) &&
                            question.selectedOptions.length > 0 ? (
                            `${question.selectedOptions[0].text} - ${question.selectedOptions[0].detailDescription}`
                          ) : [questionTypesEnum.Multiple_Choice].includes(
                              question.question.questionTypeId
                            ) && question.selectedOptions.length > 0 ? (
                            question.selectedOptions
                              .map((obj) => obj.text)
                              .join(", ")
                          ) : (
                            <></>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="table_btns align_self gap-1 gap-xxl-2">
                      <img
                        alt=""
                        src={`images/${
                          [
                            questionTypesEnum.Date,
                            questionTypesEnum.DateTime,
                          ].includes(question.question.questionTypeId)
                            ? question.fieldValue &&
                              this.checkIfDateIsExpired(question.fieldValue)
                              ? "red"
                              : "green"
                            : [questionTypesEnum.TextBox].includes(
                                question.question.questionTypeId
                              )
                            ? question.fieldValue !== ""
                              ? "green"
                              : "red"
                            : [
                                questionTypesEnum.Dropdown,
                                questionTypesEnum.radio,
                              ].includes(question.question.questionTypeId) &&
                              question.selectedOptions.length > 0
                            ? question.selectedOptions[0].isCorrect
                              ? "green"
                              : question.selectedOptions[0].isAnswer
                              ? "yellow"
                              : "red"
                            : [questionTypesEnum.Multiple_Choice].includes(
                                question.question.questionTypeId
                              ) && question.selectedOptions.length > 0
                            ? "green"
                            : "red"
                        }.png`}
                      />
                    </div>
                  </div>
                </>
              );
            })}

            {/* tr/end */}
            <div className="table_foot text-end"></div>
          </div>
        </div>

        <>
          <div
            className="modal fade"
            id="findingModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-fullscreen ">
              <div className="modal-content">
                <div className="dash_card">
                  <div className="card_head  d-flex align-items-center justify-content-between">
                    <h2 className="card_title">Detail Findings</h2>
                    <button
                      type="button"
                      className="btn-close border-0 bg-transparent"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  {/* card-head/end */}
                  <div className="task_table task_tables">
                    <div className="table_tr">
                      <div className="table_text">
                        <div className="form-check">
                          <label
                            className="form-check-label question_label"
                            htmlFor="flexRadioDefault1"
                          >
                            Questions
                          </label>
                        </div>
                      </div>
                      <div className="table_text">
                        <div className="form-check">
                          <label
                            className="form-check-label finding_label"
                            htmlFor="flexRadioDefault1"
                          >
                            Findings
                          </label>
                        </div>
                      </div>
                      <div className="table_btns align_self gap-1 gap-xxl-2 d-flex">
                        <img   alt="" src={`images/green.png`} />
                        <img   alt="" src={`images/yellow.png`} />
                        <img   alt="" src={`images/red.png`} />
                      </div>
                    </div>
                    {/* tr/end */}

                    {this.props.details.map((question, index) => {
                      return (
                        <>
                          <div className="table_tr" key={index}>
                            <div className="table_text">
                              <div className="form-check">
                                <label
                                  className="form-check-label question_label"
                                  htmlFor="flexRadioDefault2"
                                >
                                  {index + 1}. {question.question.text}
                                </label>
                              </div>
                            </div>

                            <div className="table_text">
                              <div className="form-check">
                                <label
                                  className="form-check-label finding_label"
                                  htmlFor="flexRadioDefault2"
                                >
                                  {/* {index + 1}. {question.question.text} */}
                                  {[
                                    questionTypesEnum.Date,
                                    questionTypesEnum.DateTime,
                                    questionTypesEnum.TextBox,
                                  ].includes(
                                    question.question.questionTypeId
                                  ) ? (
                                    question.fieldValue
                                  ) : [
                                      questionTypesEnum.Dropdown,
                                      questionTypesEnum.radio,
                                    ].includes(
                                      question.question.questionTypeId
                                    ) && question.selectedOptions.length > 0 ? (
                                    `${question.selectedOptions[0].text} - ${question.selectedOptions[0].detailDescription}`
                                  ) : [
                                      questionTypesEnum.Multiple_Choice,
                                    ].includes(
                                      question.question.questionTypeId
                                    ) && question.selectedOptions.length > 0 ? (
                                    question.selectedOptions
                                      .map((obj) => obj.text)
                                      .join(", ")
                                  ) : (
                                    <></>
                                  )}
                                </label>
                              </div>
                            </div>
                            <div className="table_btns align_self gap-1 gap-xxl-2">
                              <img
                                alt=""
                                src={`images/${
                                  [
                                    questionTypesEnum.Date,
                                    questionTypesEnum.DateTime,
                                  ].includes(question.question.questionTypeId)
                                    ? question.fieldValue &&
                                      this.checkIfDateIsExpired(
                                        question.fieldValue
                                      )
                                      ? "red"
                                      : "green"
                                    : [questionTypesEnum.TextBox].includes(
                                        question.question.questionTypeId
                                      )
                                    ? question.fieldValue !== ""
                                      ? "green"
                                      : "red"
                                    : [
                                        questionTypesEnum.Dropdown,
                                        questionTypesEnum.radio,
                                      ].includes(
                                        question.question.questionTypeId
                                      ) && question.selectedOptions.length > 0
                                    ? question.selectedOptions[0].isCorrect
                                      ? "green"
                                      : question.selectedOptions[0].isAnswer
                                      ? "yellow"
                                      : "red"
                                    : [
                                        questionTypesEnum.Multiple_Choice,
                                      ].includes(
                                        question.question.questionTypeId
                                      ) && question.selectedOptions.length > 0
                                    ? "green"
                                    : "red"
                                }.png`}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}

                    {/* tr/end */}
                    <div className="table_foot text-end"></div>
                  </div>
                </div>
                {/* card/end */}
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default DetailedFindings;
