import React from "react";

const BankTaskModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="bankModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen ">
          <div className="modal-content">
            <div className="dash_card">
              <div className="card_head  d-flex align-items-center justify-content-between border-bottom">
                <h2 className="card_title">Diary</h2>
                <button
                  type="button"
                  className="btn-close border-0 bg-transparent"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {/* card-head/end */}
              <div className="card_body d-flex flex-column gap-3 p-4">
                <div className="trans_card_list d-flex flex-column gap-3">
                  <div className="trans_item">
                    <div className="trans_detail">
                      <span className="card_ico">
                        <img src="images/cc-ico.svg" alt="Cart Icon" />
                      </span>
                      <div className="d-flex flex-column gap-2">
                        <strong className="fw-normal text-color">
                          Tim Deposit Pending Amount
                        </strong>
                        <span className="text-color-second">09/09/2023</span>
                      </div>
                    </div>
                    <strong className="price">$7,200.00</strong>
                  </div>
                  {/* trans/item */}
                  <div className="trans_item">
                    <div className="trans_detail">
                      <span className="card_ico">
                        <img src="images/cc-ico.svg" alt="Cart Icon" />
                      </span>
                      <div className="d-flex flex-column gap-2">
                        <strong className="fw-normal text-color">
                          Tim Deposit Pending Amount
                        </strong>
                        <span className="text-color-second">09/09/2023</span>
                      </div>
                    </div>
                    <strong className="price">$7,200.00</strong>
                  </div>
                  {/* trans/item */}
                </div>
                {/* lise/end */}
                <div className="text-end">
                  <a href="#" className="btn btn-outlined-primary">
                    + Add New Transaction
                  </a>
                </div>
              </div>
            </div>
            {/* card/end */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BankTaskModal;
