import React from "react";
import facebookIcon from "../../images/fb.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { toast } from "react-toastify";
import { clientendpoints } from "../../../endpoints/endpoints";
import { authService } from "../../../services/auth.service";

function FacebookLoginButton(props) {
  const responseFacebook = (response) => {
    authService
      .facebookAuthLogin(response)
      .then((res) => {
        toast.success("Sign Successfull.");
        props.navigate(clientendpoints.dashboard);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_AUTH_CLIENT_ID}
        callback={responseFacebook}
        render={(renderProps) => (
          <div className="tw-bg-indigo-800 tw-bg-opacity-10 tw-self-stretch tw-flex tw-w-full tw-flex-col tw-items-center tw-mt-3 tw-px-5 tw-py-3.5 tw-rounded-lg">
            <div className="tw-flex tw-w-[152px] tw-max-w-full tw-items-stretch tw-gap-3">
              <img
                alt=""
                loading="lazy"
                src={facebookIcon}
                className="tw-aspect-square tw-object-contain tw-object-center tw-w-[22px] tw-overflow-hidden tw-shrink-0 tw-max-w-full"
              />
              <a
                onClick={renderProps.onClick}
                className="tw-text-indigo-800 tw-text-center tw-text-sm tw-leading-4 tw-grow tw-whitespace-nowrap tw-mt-1.5 tw-self-start"
              >
                Sign In with Facebook
              </a>
            </div>
          </div>
        )}
      />
    </>
  );
}

export default FacebookLoginButton;
