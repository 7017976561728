import React, { useEffect } from "react";
import { authService } from "../../../services/auth.service";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../../Shared/Header";
import Footer from "../../Shared/Foorter";

const Dashboard = (props) => {
  const { children, activeTab, screenName } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await authService.authorizePing();
      } catch (err) {
        // Handle error
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <link rel="stylesheet" href={require("../../styles/bootstrap.min.css")} />

      <link rel="stylesheet" href={require("../../styles/sidebars.css")} />
      <link
        rel="stylesheet"
        href={require("../../styles/vanilla-calendar.min.css")}
      />
      <link rel="stylesheet" href={require("../../styles/light.min.css")} />
      <link rel="stylesheet" href={require("../../styles/style.css")} />
      <link rel="stylesheet" href={require("../../styles/responsive.css")} />

      <main className="d-flex flex-nowrap">
        <Sidebar activeTab={activeTab} />

        <div className="container-fluid">
          <Header screenName={screenName}/>

          {/* Children between Header and Footer */}
          {children}

          <Footer />
        </div>
      </main>
    </>
  );
};

export default Dashboard;
