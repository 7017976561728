import React, { useState } from "react";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { clientendpoints } from "../../../endpoints/endpoints";
import { authService } from "../../../services/auth.service";
import Spinner from "../../Shared/Spinner";
import { toast } from "react-toastify";
import { withNavigation } from "../../Shared/withNavigation";
import "react-phone-input-2/lib/style.css";
import GoogleLoginButton from "./../Login/googleLogin";
import FacebookLoginButton from "./../Login/FacebookLogin";
import outlookIcon from "../../images/outlook.svg";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    let dataToSend = {
      email: email,
    };

    authService
      .forgotPassword(dataToSend)
      .then((res) => {
        setEmail("");
        setLoading(false);
        toast.success("An confirmation email has been sent to your email");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="tw-bg-indigo-500">
          <section className="tw-gap-5 tw-flex max-md:tw-flex-col max-md:tw-items-stretch max-md:tw-gap-0">
            <div className=" tw-rounded-r-2xl tw-bg-white tw-flex tw-flex-col tw-w-[55%] max-md:tw-w-full max-md:tw-ml-0  tw-items-center">
              <div className="tw-flex tw-w-[426px] tw-max-w-full tw-flex-col tw-mt-20 tw-mb-11 max-md:tw-mr-2.5 max-md:tw-my-10 tw-px-4">
                <h1 className="tw-text-zinc-900 tw-text-3xl tw-font-bold tw-leading-10 tw-self-stretch tw-whitespace-nowrap max-md:tw-max-w-full">
                  Forgot Password
                </h1>
                <p className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-self-stretch tw-mt-3 max-md:tw-max-w-full">
                  Enter your email to proceed further
                </p>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => handleChange(e)}
                  className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-border tw-self-stretch tw-px-4 tw-py-5 tw-rounded-lg tw-border-solid tw-border-indigo-500 tw-border-opacity-30 max-md:tw-max-w-full"
                />

                <div className="tw-self-stretch tw-flex tw-items-stretch tw-justify-between tw-gap-2.5 tw-mt-10 max-md:tw-max-w-full max-md:tw-flex-wrap">
                  <button className="tw-text-white tw-text-center tw-text-sm tw-font-bold tw-leading-4 tw-whitespace-nowrap tw-bg-indigo-500 tw-grow tw-items-center tw-px-5 tw-py-4 tw-rounded-lg">
                    {loading ? <Spinner /> : "Send"}
                  </button>
                </div>

                <div className="tw-self-stretch tw-flex tw-items-stretch tw-justify-between tw-gap-2.5 tw-mt-4 max-md:tw-max-w-full max-md:tw-flex-wrap">
                  <Link
                    style={{ backgroundColor: "#EEEDF2", color: "#5E81F4" }}
                    to={clientendpoints.auth.login}
                    className="tw-text-white tw-text-center tw-text-sm tw-font-bold tw-leading-4 tw-whitespace-nowrap tw-bg-indigo-500 tw-grow tw-items-center tw-px-5 tw-py-4 tw-rounded-lg"
                  >
                    Back to Sign In
                  </Link>
                </div>

                <div className="tw-self-stretch tw-flex tw-items-stretch tw-justify-between tw-gap-2.5 tw-mt-8 max-md:tw-justify-center">
                  <div className="tw-bg-indigo-500 tw-bg-opacity-30 tw-w-[165px] tw-shrink-0 tw-h-px tw-self-start" />
                  <div className="tw-text-slate-500 tw-text-sm tw-leading-5">
                    or
                  </div>
                  <div className="tw-bg-indigo-500 tw-bg-opacity-30 tw-w-[166px] tw-shrink-0 tw-h-px tw-self-start" />
                </div>

                <GoogleLoginButton {...props} />
                <FacebookLoginButton {...props} />

                <div className="tw-bg-sky-500 tw-bg-opacity-10 tw-self-stretch tw-flex tw-w-full tw-flex-col tw-items-center tw-mt-3 tw-px-5 tw-py-3.5 tw-rounded-lg">
                  <div className="tw-flex tw-w-[152px] tw-max-w-full tw-items-stretch tw-gap-3">
                    <img
                      alt=""
                      loading="lazy"
                      src={outlookIcon}
                      className="tw-aspect-[1.05] tw-object-contain tw-object-center tw-w-[22px] tw-overflow-hidden tw-shrink-0 tw-max-w-full"
                    />
                    <div className="tw-text-indigo-800 tw-text-center tw-text-sm tw-leading-4 tw-grow tw-whitespace-nowrap tw-mt-1.5 tw-self-start">
                      Sign In with Outlook
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tw-hidden md:tw-flex tw-flex-col tw-items-stretch tw-w-[45%] tw-ml-5 max-md:tw-w-full max-md:tw-ml-0">
              <div className="tw-bg-indigo-500 tw-flex tw-grow tw-flex-col tw-itemsretch tw-w-full tw-mx-auto max-md:tw-max-w-full">
                <div className="tw-flex-col tw-overflow-hidden tw-relative tw-flex tw-min-h-[900px] tw-w-full tw-items-center tw-px-5 tw-py-12 max-md:tw-max-w-full">
                  <img
                    alt=""
                    loading="lazy"
                    src={logo}
                    className="tw-aspect-square tw-object-contain tw-object-center tw-w-[419px] tw-overflow-hidden tw-max-w-full tw-mt-48 tw-mb-36 max-md:tw-my-10"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </form>
    </>
  );
};

export default withNavigation(ForgotPassword);