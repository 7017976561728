import React, { useState, useEffect } from "react";
import logo from "../../images/logo.png";
import PasswordInput from "../../Shared/PasswordInput";
import { clientendpoints } from "../../../endpoints/endpoints";
import { authService } from "../../../services/auth.service";
import Spinner from "../../Shared/Spinner";
import { toast } from "react-toastify";
import { withNavigation } from "../../Shared/withNavigation";
import "react-phone-input-2/lib/style.css";

const ResetPassword = (props) => {
  const [passwordDetails, setPasswordDetails] = useState({
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);

  const getTokenFromUrl = () => {
    const queryParam = new URLSearchParams(window.location.search).get("token");
    return queryParam;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (passwordDetails.password === passwordDetails.confirmPassword) {
      let dataToSend = {
        password: passwordDetails.password,
        token: getTokenFromUrl(),
      };

      setLoading(true);
      authService
        .resetPassword(dataToSend)
        .then((res) => {
          setPasswordDetails({
            password: "",
            confirmPassword: "",
          });
          setLoading(false);
          toast.success("Password Reset Successfully");
          props.navigate(clientendpoints.auth.login);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    } else {
      toast.error("Password and confirm password do not matched");
    }
  };

  const handleChange = (event) => {
    setPasswordDetails({
      ...passwordDetails,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    async function isQueryParamValid() {
      const queryParam = getTokenFromUrl();
      console.log(queryParam);
      if (queryParam === null) {
        window.location.href = clientendpoints.auth.login;
      }

      await authService
        .validateResetPasswordToken(queryParam)
        .then(() => {})
        .catch((error) => {
          toast.error(error);
          window.location.href = clientendpoints.auth.login;
        });
    }

    isQueryParamValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="tw-bg-indigo-500">
          <section className="tw-gap-5 tw-flex max-md:tw-flex-col max-md:tw-items-stretch max-md:tw-gap-0">
            <div className=" tw-rounded-r-2xl tw-bg-white tw-flex tw-flex-col tw-w-[55%] max-md:tw-w-full max-md:tw-ml-0  tw-items-center">
              <div className="tw-flex tw-w-[426px] tw-max-w-full tw-flex-col tw-mt-20 tw-mb-11 max-md:tw-mr-2.5 max-md:tw-my-10 tw-px-4">
                <h1 className="tw-text-zinc-900 tw-text-3xl tw-font-bold tw-leading-10 tw-self-stretch tw-whitespace-nowrap max-md:tw-max-w-full">
                  New Password
                </h1>
                <p className="tw-text-slate-500 tw-text-sm tw-leading-5 tw-self-stretch tw-mt-3 max-md:tw-max-w-full"></p>
                <a
                  href="#"
                  className="tw-text-white tw-text-center tw-text-sm tw-font-bold tw-leading-4 tw-whitespace-nowrap tw-ml-14 tw-mt-11 tw-self-start max-md:tw-ml-2.5 max-md:tw-mt-10"
                ></a>
                <PasswordInput
                  placeholder={"Enter New Password"}
                  name="password"
                  value={passwordDetails.password}
                  handleChange={handleChange}
                />

                <PasswordInput
                  placeholder={"Confirm Password"}
                  name="confirmPassword"
                  value={passwordDetails.confirmPassword}
                  handleChange={handleChange}
                />

                <div className="tw-self-stretch tw-flex tw-items-stretch tw-justify-between tw-gap-2.5 tw-mt-10 max-md:tw-max-w-full max-md:tw-flex-wrap">
                  <button className="tw-text-white tw-text-center tw-text-sm tw-font-bold tw-leading-4 tw-whitespace-nowrap tw-bg-indigo-500 tw-grow tw-items-center tw-px-5 tw-py-4 tw-rounded-lg">
                    {loading ? <Spinner /> : "Send"}
                  </button>
                </div>
              </div>
            </div>

            <div className="tw-hidden md:tw-flex tw-flex-col tw-items-stretch tw-w-[45%] tw-ml-5 max-md:tw-w-full max-md:tw-ml-0">
              <div className="tw-bg-indigo-500 tw-flex tw-grow tw-flex-col tw-itemsretch tw-w-full tw-mx-auto max-md:tw-max-w-full">
                <div className="tw-flex-col tw-overflow-hidden tw-relative tw-flex tw-min-h-[900px] tw-w-full tw-items-center tw-px-5 tw-py-12 max-md:tw-max-w-full">
                  <img
                    alt=""
                    loading="lazy"
                    src={logo}
                    className="tw-aspect-square tw-object-contain tw-object-center tw-w-[419px] tw-overflow-hidden tw-max-w-full tw-mt-48 tw-mb-36 max-md:tw-my-10"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </form>
    </>
  );
};

export default withNavigation(ResetPassword);
