import { endpoints } from "../endpoints/endpoints";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../system/server-calls";

import responseHandler from "../system/server-response-handler";

function getAllQuestionsByQuestionareId(questionareId) {
  return getRequest(
    endpoints.questions.getQuestionsByQuestionareId + questionareId,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getQuestionById(questionId) {
  return getRequest(
    endpoints.questions.getQuestionByQuestionId + questionId,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function addNewQuestionInQuestionare(questionareId, data) {
  return postRequest(
    endpoints.questions.addOneQuestion + questionareId,
    data,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function updateQuestionById(questionId, data) {
  return putRequest(
    endpoints.questions.updateQuestionById + questionId,
    data,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function deleteQuestionById(questionId) {
  return deleteRequest(
    endpoints.questions.deleteQuestionByQuestionId + questionId,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// exporting functions for other files
export const questionsService = {
  getAllQuestionsByQuestionareId,
  addNewQuestionInQuestionare,
  getQuestionById,
  updateQuestionById,
  deleteQuestionById,
};
