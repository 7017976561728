import { endpoints } from "../endpoints/endpoints";
import { getThirdPartyRequest } from "../system/server-calls";

function getAllNews(queryParams) {
  return getThirdPartyRequest(
    `${endpoints.media.getNews}${encodeURI(queryParams)}`,
    false
  ).then(
    (response) => {
      return response.data;
    },
    (error) => {
      return error;
    }
  );
}

// exporting functions for other files
export const mediaService = {
  getAllNews,
};
