import React, { Component } from "react";
import { roleService } from "../../../services/role.service";
import ElementsModal from "./ElementsModal";
import { toast } from "react-toastify";
import {
  previligeConstants,
  screenElementsConstant,
} from "../../../constants/globalConstants";
import { authService } from "../../../services/auth.service";

class RoleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: this.props.isEdit
        ? this.props.editData.role.screens.map((s) =>
            s?.Privilege ? s.Privilege : 0
          )
        : [],
      optionData: [
        {
          id: 0,
          name: "None",
        },
        {
          id: 1,
          name: "Admin",
        },
        {
          id: 2,
          name: "Custom",
        },
      ],
      elementsModal: false,
      isEditModal: false,
      editElementsData: {},
      isAdminPrevelige: false,
      isCustomPrevelige: false,
      prevelige: {},
      roleSubmitPrevelige: {},
      rolePreveilge: {},
      rolePreveilgeSubmit: {},
    };
  }

  componentDidMount() {
    this.setState({
      prevelige:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.RoleNameInput
            )
          : {},
      roleSubmitPrevelige:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName === screenElementsConstant.RoleNameSubmit
            )
          : {},

      rolePreveilge:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName ===
                screenElementsConstant.ScreenPrivilegesSelect
            )
          : {},
      rolePreveilgeSubmit:
        this.props.prevelige &&
        this.props.prevelige.ScreenElementPriviliges &&
        this.props.prevelige.ScreenElementPriviliges.length > 0
          ? this.props.prevelige.ScreenElementPriviliges.find(
              (element) =>
                element.ElementName ===
                screenElementsConstant.RolePrivilegesSubmit
            )
          : {},
      isAdminPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.ADMIN
        : false,

      isCustomPrevelige: this.props.prevelige
        ? this.props.prevelige.ScreenPriviliges === previligeConstants.CUSTOM
        : false,
    });
  }

  handleRoleScreenSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.preventDefault();
    const formData = {};
    Array.from(e.target.elements).forEach((element) => {
      if (element.name) {
        formData[element.name] = element.value;
      }
    });

    const roleId = this.props.editData.role.id;

    const data = [];

    for (const screenId in formData) {
      data.push({
        screenId: screenId,
        previlige: formData[screenId],
      });
    }

    roleService
      .addRoleScreens(roleId, { screens: data })
      .then((data) => {
        toast.success("Role screen updated successfully");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  openModal = () => {
    this.setState({ elementsModal: true });
  };

  closeModal = () => {
    this.setState({ elementsModal: false });
  };

  handleSelectChange = (index, value) => {
    // Update the selected option for the specific screen
    const selectedOptions = [...this.state.selectedOptions];
    selectedOptions[index] = parseInt(value);
    // console.log(index, value);
    this.setState({ selectedOptions });
  };

  handleOpenScreenElementsModal = (screenid, screenName) => {
    roleService
      .getRoleScreenElemnets(this.props.editData.role.id, screenid)
      .then((data) => {
        this.setState(
          {
            editElementsData: {
              screenElement: { name: screenName, elements: data },
            },
            isEditModal: true,
          },
          () => {
            this.openModal();
          }
        );
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <>
        <div
          className={`modal fade ${this.props.showMoal ? " show" : ""}`}
          tabIndex={-1}
          style={
            this.props.showMoal ? { display: "block", paddingLeft: "0px" } : {}
          }
        >
          <div className="modal-dialog modal-fullscreen ">
            <div className="modal-content">
              <div className="dash_card">
                <div className="card_head  d-flex align-items-center justify-content-between">
                  <h2 className="card_title">
                    {this.props.isEdit
                      ? `Edit Role ${this.props.editData.role.name}`
                      : "Add new Role"}
                  </h2>
                  <button
                    type="button"
                    className="btn-close border-0 bg-transparent"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.props.closeModal()}
                  >
                    <span className="visually-hidden">Expand Button</span>
                  </button>
                </div>
                <div className="card_body">
                  <div className="tab_container">
                    <ul
                      className="nav nav-pills mb-3 gap-2"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active blue-color"
                          id="pills-money-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-money1"
                          type="button"
                          role="tab"
                          aria-controls="pills-money"
                          aria-selected="true"
                        >
                          General
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link dark-green-color"
                          id="pills-maintenance-tab1"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-maintenance1"
                          type="button"
                          role="tab"
                          aria-controls="pills-maintainance"
                          aria-selected="false"
                          disabled={this.props.isEdit ? false : true}
                        >
                          Privileges
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-money1"
                        role="tabpanel"
                        aria-labelledby="pills-money-tab"
                        tabIndex={0}
                      >
                        <div className="tab_head">
                          <div className="container">
                            <form
                              id="RoleForm"
                              onSubmit={
                                this.state.isAdminPrevelige ||
                                (this.state.roleSubmitPrevelige &&
                                  authService.isFullControlPrevelige(
                                    this.state.roleSubmitPrevelige.Priviliges
                                  ))
                                  ? (e) =>
                                      this.props.isEdit
                                        ? this.props.handleEditRole(
                                            e,
                                            this.props.editData.role.id
                                          )
                                        : this.props.handleAddNewRole(e)
                                  : (e) => {
                                      e.preventDefault();
                                    }
                              }
                              method="post"
                            >
                              <div className="modal-height">
                                <div className="row scrollable">
                                  {this.state.isAdminPrevelige ||
                                  (this.state.isCustomPrevelige &&
                                    !authService.isNoAccess(
                                      this.state.prevelige?.Priviliges
                                    )) ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Role Name
                                        </label>

                                        <input
                                          name="name"
                                          maxLength={50}
                                          id="name"
                                          type="text"
                                          disabled={
                                            !this.state.isAdminPrevelige &&
                                            authService.isReadOnlyPrevelige(
                                              this.state.prevelige?.Priviliges
                                            )
                                              ? true
                                              : false
                                          }
                                          required
                                          className="is-untouched is-pristine av-invalid form-control"
                                          defaultValue={
                                            this.props.isEdit
                                              ? this.props.editData.role.name
                                              : ""
                                          }
                                        />
                                        <div
                                          id="NameInvalid-msg"
                                          className="invalid-feedback d-none"
                                        >
                                          This field is invalid
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {this.props.isEdit &&
                                  !this.props.editData.role.isActive ? (
                                    <div className="inner-div col-md-6 col-sm-6 col-12 mb-2">
                                      <div id="Name-div" className="form-group">
                                        <label htmlFor="Name" className="">
                                          Is Active
                                          <br />
                                          <input
                                            name="isActive"
                                            id="isActive"
                                            type="checkbox"
                                            className="ml-2"
                                          />
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <hr />

                              {this.state.isAdminPrevelige ||
                              (this.state.isCustomPrevelige &&
                                !authService.isNoAccess(
                                  this.state.roleSubmitPrevelige?.Priviliges
                                )) ? (
                                <button
                                  type="submit"
                                  disabled={
                                    !this.state.isAdminPrevelige &&
                                    authService.isReadOnlyPrevelige(
                                      this.state.roleSubmitPrevelige?.Priviliges
                                    )
                                      ? true
                                      : false
                                  }
                                  className={`submit-button btn btn-primary ${
                                    !this.state.isAdminPrevelige &&
                                    authService.isReadOnlyPrevelige(
                                      this.state.roleSubmitPrevelige?.Priviliges
                                    )
                                      ? " disabled"
                                      : ""
                                  }`}
                                >
                                  Submit
                                </button>
                              ) : (
                                <></>
                              )}
                            </form>
                          </div>
                        </div>
                        {/* tab-head/end */}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-maintenance1"
                        role="tabpanel"
                        aria-labelledby="pills-maintenance-tab"
                        tabIndex={0}
                      >
                        <div className="container">
                          <form
                            id="ScreenPriviligesForm"
                            onSubmit={
                              this.state.isAdminPrevelige ||
                              (this.state.rolePreveilgeSubmit &&
                                authService.isFullControlPrevelige(
                                  this.state.rolePreveilgeSubmit.Priviliges
                                ))
                                ? (e) => this.handleRoleScreenSubmit(e)
                                : (e) => {
                                    e.preventDefault();
                                  }
                            }
                          >
                            <div className="modal-scrolling">
                              {this.props.isEdit ? (
                                this.props.editData.role.screens &&
                                this.props.editData.role.screens.length > 0 ? (
                                  this.props.editData.role.screens.map(
                                    (s, index) => {
                                      return (
                                        <div className="mt-2" key={index}>
                                          <div className="row mr-0 mbSmallScreen">
                                            <div className=" col-lg-4 marginSmallScreen mx-auto">
                                              <span className="font-weight-bold">
                                                {s.screen
                                                  ? s.screen.ScreenName
                                                  : s.ScreenName}
                                              </span>
                                            </div>
                                            <div className="col-lg-4 mx-auto marginSmallScreen">
                                              {this.state.isAdminPrevelige ||
                                              (this.state.rolePreveilge &&
                                                !authService.isNoAccess(
                                                  this.state.rolePreveilge
                                                    ?.Priviliges
                                                )) ? (
                                                <select
                                                  id={`screenPriviligesAudit_${index}`}
                                                  name={
                                                    s.screen
                                                      ? s.screen.id
                                                      : s.id
                                                  }
                                                  disabled={
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.rolePreveilge
                                                        ?.Priviliges
                                                    )
                                                      ? true
                                                      : false
                                                  }
                                                  className={`react-select theme-light react-select__control mb-2 form-control ScreenSelector ${
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.rolePreveilge
                                                        ?.Priviliges
                                                    )
                                                      ? " disabled"
                                                      : ""
                                                  }`}
                                                  onChange={
                                                    this.state
                                                      .isAdminPrevelige ||
                                                    (this.state.rolePreveilge &&
                                                      authService.isFullControlPrevelige(
                                                        this.state.rolePreveilge
                                                          .Priviliges
                                                      ))
                                                      ? (e) =>
                                                          this.handleSelectChange(
                                                            index,
                                                            e.target.value
                                                          )
                                                      : () => {}
                                                  }
                                                >
                                                  {this.state.optionData.map(
                                                    (data) => {
                                                      return (
                                                        <option
                                                          value={data.id}
                                                          selected={
                                                            s.screen
                                                              ? s.Privilege ===
                                                                data.id
                                                              : false
                                                          }
                                                        >
                                                          {data.name}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            <div className="col-lg-3 marginSmallScreen">
                                              {this.state.isAdminPrevelige ||
                                              (this.state.rolePreveilge &&
                                                !authService.isNoAccess(
                                                  this.state.rolePreveilge
                                                    ?.Priviliges
                                                )) ? (
                                                <button
                                                  type="button"
                                                  id={`elementButtonsAudit_${index}`}
                                                  className={`submit-button mr-2 mb-2 btn btn-primary btn-sm ${
                                                    this.state.selectedOptions[
                                                      index
                                                    ] === 2
                                                      ? !this.state
                                                          .isAdminPrevelige &&
                                                        authService.isReadOnlyPrevelige(
                                                          this.state
                                                            .rolePreveilge
                                                            ?.Priviliges
                                                        )
                                                        ? "disabled"
                                                        : ""
                                                      : "disabled"
                                                  }`}
                                                  disabled={
                                                    !this.state
                                                      .isAdminPrevelige &&
                                                    authService.isReadOnlyPrevelige(
                                                      this.state.rolePreveilge
                                                        ?.Priviliges
                                                    )
                                                      ? true
                                                      : this.state
                                                          .selectedOptions[
                                                          index
                                                        ] !== 2
                                                  }
                                                  onClick={
                                                    this.state
                                                      .isAdminPrevelige ||
                                                    (this.state.rolePreveilge &&
                                                      authService.isFullControlPrevelige(
                                                        this.state.rolePreveilge
                                                          .Priviliges
                                                      ))
                                                      ? () =>
                                                          this.handleOpenScreenElementsModal(
                                                            s.screen
                                                              ? s.screen.id
                                                              : s.id,
                                                            s.screen
                                                              ? s.screen
                                                                  .ScreenName
                                                              : s.ScreenName
                                                          )
                                                      : () => {}
                                                  }
                                                >
                                                  Elements
                                                </button>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                            <hr />

                            {this.state.isAdminPrevelige ||
                            (this.state.rolePreveilgeSubmit &&
                              !authService.isNoAccess(
                                this.state.rolePreveilgeSubmit?.Priviliges
                              )) ? (
                              <button
                                type="submit"
                                disabled={
                                  !this.state.isAdminPrevelige &&
                                  authService.isReadOnlyPrevelige(
                                    this.state.rolePreveilgeSubmit?.Priviliges
                                  )
                                    ? true
                                    : false
                                }
                                className={`submit-button btn btn-primary ${
                                  !this.state.isAdminPrevelige &&
                                  authService.isReadOnlyPrevelige(
                                    this.state.rolePreveilgeSubmit?.Priviliges
                                  )
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                Update
                              </button>
                            ) : (
                              <></>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* tab/end */}
                </div>
                {/* card-body/end */}
              </div>
              {/* card/end */}
            </div>
          </div>
        </div>

        {this.props.isEdit && this.state.elementsModal ? (
          <ElementsModal
            showMoal={this.state.elementsModal}
            closeModal={this.closeModal}
            isEdit={this.state.isEditModal}
            defaultName={this.state.defaultName}
            editData={this.state.editElementsData}
            handleRoleElementPrivileges={this.handleEditModalSubmit}
            roleId={this.props.editData.role.id}
            prevelige={this.props.prevelige}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default RoleModal;
