import { propertiesConstants } from "../../constants";
import { propertiesService } from "../../services";
import { success } from "./actionsUtilities";

const { GET_All_USER_PROPERTIES, GET_PROPERTY_DETALIS } = propertiesConstants;

function getAllUserProperties() {
  return (dispatch) => {
    propertiesService
      .getAllUserProperties()
      .then(
        (response) => {
          dispatch(success(response, GET_All_USER_PROPERTIES));
        },
        () => {}
      )
      .finally(() => {});
  };
}

function getPropertyDetails(propertyId) {
  return (dispatch) => {
    propertiesService
      .getPropertyDetails(propertyId)
      .then(
        (response) => {
          dispatch(success(response, GET_PROPERTY_DETALIS));
        },
        () => {}
      )
      .finally(() => {});
  };
}

function addUserToProperty(userDetails) {
  return (dispatch) => {
    return propertiesService.addUserToProperty(userDetails);
  };
}

function validateRequestToJoinProperty(token) {
  return (dispatch) => {
    return propertiesService.validateRequestToJoinProperty(token);
  };
}

export const propertiesActions = {
  getAllUserProperties,
  addUserToProperty,
  getPropertyDetails,
  validateRequestToJoinProperty
};
