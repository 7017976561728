import React from "react";

const CommunicationTabs = () => {
  return (
    <>
      <div className="dash_card">
        <div className="card_head  d-flex align-items-center justify-content-between">
          <h2 className="card_title">Communications Tasks</h2>
          <button
            type="button"
            className="expand_btn border-0 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#comTaskModal"
          >
            <svg
              width={20}
              height={17}
              viewBox="0 0 20 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 5.4V1H19V11H14.2333"
                stroke="#8181A5"
                strokeLinecap="round"
              />
              <rect x="0.5" y="5.5" width={13} height={10} stroke="#8181A5" />
            </svg>
            <span className="visually-hidden">Expand Button</span>
          </button>
        </div>
        {/* card-head/end */}
        <div className="task_table">
          <div className="table_tr">
            <div className="table_text">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Do you want to tell your residents about upcoming inspection
                </label>
              </div>
            </div>
            <div className="table_btns d-flex gap-1 gap-xxl-2">
              <a href="#" className="btn btn-primary">
                Schedule Later
              </a>
              <a href="#" className="btn btn-secondary px-4">
                Ignore
              </a>
              <a
                href="#"
                className="custom_btn btn bg-transparent border-0 blue-color blue-color text-uppercase fw-bold"
              >
                Send Now
              </a>
            </div>
          </div>
          {/* tr/end */}
          <div className="table_tr">
            <div className="table_text">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Notify residents of remedial work
                </label>
              </div>
            </div>
            <div className="table_btns d-flex gap-1 gap-xxl-2">
              <a href="#" className="btn btn-primary">
                Schedule Later
              </a>
              <a href="#" className="btn btn-secondary px-4">
                Ignore
              </a>
              <a
                href="#"
                className="custom_btn btn  bg-transparent border-0 blue-color blue-color text-uppercase fw-bold"
              >
                Send Now
              </a>
            </div>
          </div>
          {/* tr/end */}
          <div className="table_tr">
            <div className="table_text">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault3">
                  Notify plumber of broken pipe
                </label>
              </div>
            </div>
            <div className="table_btns d-flex gap-1 gap-xxl-2">
              <a href="#" className="btn btn-primary">
                Schedule Later
              </a>
              <a href="#" className="btn btn-secondary px-4">
                Ignore
              </a>
              <a
                href="#"
                className="custom_btn btn bg-transparent border-0 blue-color blue-color text-uppercase fw-bold"
              >
                Send Now
              </a>
            </div>
          </div>
          {/* tr/end */}
          <div className="table_foot text-end">
            <a href="#" className="blue-color text-uppercase">
              View All Tasks
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunicationTabs;
