import { propertiesEndpoints } from "../endpoints/endpoints";
import {
  getRequest,
  postRequest
} from "../system/server-calls";

import responseHandler from "../system/server-response-handler";

function getAllUserProperties() {
  return getRequest(propertiesEndpoints.GET_All_USER_PROPERTIES, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function getPropertyDetails(propertyId) {
  return getRequest(propertiesEndpoints.GET_PROPERTY_DETALIS + propertyId, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function addUserToProperty(data) {
  return postRequest(propertiesEndpoints.ADD_USER_TO_PROPERTY, data, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function validateRequestToJoinProperty(token) {
  return getRequest(propertiesEndpoints.VALIDATE_JOIN_PROPERTY + token, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// exporting functions for other files
export const propertiesService = {
  getAllUserProperties,
    addUserToProperty,
    getPropertyDetails,
    validateRequestToJoinProperty
};
