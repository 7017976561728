import React from "react";
import Board from "../../Shared/Board";
import SearchBar from "../../Shared/SearchBar";
import CommunicationTabs from "../../Shared/CommunicationTabs";
import CalenderDiary from "../../Shared/CalenderDiary";
import Modal from "../../Shared/Modal";
import ServiceChargeExpenditure from "../../Shared/ServiceChargeExpenditure";
import BankTransaction from "../../Shared/BankTransaction";
import TaskModal from "../../Shared/TaskModal";
import DiaryTaskModal from "../../Shared/DiaryTaskModal";
import BankTaskModal from "../../Shared/BankTaskModal";
import ChartTaskModal from "../../Shared/ChartTaskModal";

const DashboardPage = () => {
  return (
    <>
      <div className="row">
        <div className="col-xl-8">
          <SearchBar />
          {/* search/end */}
          <Board />
          <CommunicationTabs />
        </div>
        <div className="col-xl-4">
          <div className="row">
            <CalenderDiary />
            <ServiceChargeExpenditure />
            <BankTransaction />
          </div>
        </div>
      </div>

      <Modal />
      <TaskModal />
      <DiaryTaskModal />
      <ChartTaskModal />
      <BankTaskModal />
    </>
  );
};

export default DashboardPage;
